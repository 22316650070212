$ ->
  window.Eqapol ||= {}

  class DonorTabs
    constructor: ->
      @tabs = $("#donor-tabs")
      @tabs.tabs { selected: this.getTabIndex(@tabs.attr("current-tab") || 'donor-tab') }
    getTabIndex: (currentTabName) ->
      links = @tabs.find('ul:first a')
      hrefs = $.map links, (a) ->
        $(a).attr "href"
      $.inArray "##{currentTabName}", hrefs

  donorTabs = new DonorTabs

  Eqapol.DonorSearch = do ->
    $donorSearch = $('#donor_search')

    bindProtocols = ->
      $terminated = $donorSearch.find('#terminated')
      $donorSearch.on('change', '#protocols', (event) ->
        if $terminated.val() is ''
          $terminated.val('false')
          $terminated.closest('td').effect('highlight', 3000)
      )

    bindProtocols: bindProtocols

  Eqapol.DonorSearch.bindProtocols()

  $("#note-form-modal").dialog
    autoOpen: false,
    height: 410,
    width: 450,
    modal: true,
    buttons:
      Submit: ->
        $(this).find("form").submit()
        $(this).dialog "close"
      Cancel: ->
        $(this).dialog "close"

  showDonorARTMedicines = ->
    medicineField = $("#donor_medicines").parent()
    if $("#donor_currently_on_art").is(':checked')
      medicineField.show()
    else
      medicineField.hide()

  showDonorARTMedicines()

  $("#donor_currently_on_art").change(showDonorARTMedicines)

  $.each $("table.donor_consents"), (index, value) ->
     $(value).find("tbody tr:not(:last)").hide()
  $("a#toggle_consents").on "click", ->
    $(this).siblings("table.donor_consents").find("tbody tr:not([class=new]):not(:last)").toggle(10)
    if $(this).text() == "Show All" then $(this).text("Hide Older Consents") else $(this).text("Show All")
    return false

  $("a#new_consent").on "click", ->
    $(this).next(".donor_consent_form").slideToggle()
    $(this).slideToggle()
    return false

  #$(".terminated_box:not(:checked)").closest("div.fields").siblings("div.termination").hide()
  $(document).on 'click', '.terminated_box', ->
    if $(this).is(':checked')
      $(this).closest("div.fields").siblings("div.termination").slideToggle(300)
    else
      if confirm("Are you sure you want to revoke termination? Revoking termination clears any details or comments.")
        $(this).closest("div.fields").siblings("div.termination").slideToggle(300)
      else
        $(this).prop('checked', true)

  checkDeferred = ->
    selectList = $(this)
    textField = selectList.closest(".donor-subsection").find(".is_deferred_target")
    if selectList.val() == "true"
      textField.show()
      textField.focus()
    else
      textField.hide()

  $(".is_deferred").on "change", checkDeferred

  changeDeferredReason = ->
    selectList = $(this)
    textArea = selectList.closest(".donor-subsection").find(".is_deferred_target.details")
    if selectList.val() == ""
      textArea.show()
      textArea.focus()
    else
      textArea.hide()

  $(".is_deferred_target.reason select").on "change", changeDeferredReason

  checkAdverseReaction = ->
    selectList = $(this)
    textField = selectList.closest(".donor-subsection").find(".is_adverse_reaction_target")
    if selectList.val() == "true"
      textField.show()
      textField.focus()
    else
      textField.hide()

  $(".is_adverse_reaction").on "change", checkAdverseReaction

  checkOtherVisit = ->
    checkBox = $(this)
    textField = checkBox.closest(".donor-subsection").find(".other_visit_type")
    if checkBox.prop("checked")
      textField.show()
      textField.removeAttr("readonly", "").focus()
    else
      textField.hide()
      textField.val("").attr("readonly", "readonly")

  $(".is_other_visit").on "change", checkOtherVisit

#  $(".dhvi_repository").change ->
#    $.ajax {
#      url: "/specimen/update_dhvi_repository"
#      data: {specimen_id: 4}

  $("#donor-form-modal form").validate
    rules:
      "donor[ptid]": "required"
      "donor[study_id]": "required"
    submitHandler: (form) ->
      form.submit()
      $("#donor-form-modal").dialog "close"

  $("#donor-form-modal").dialog
    autoOpen: false,
    height: 300,
    width: 500,
    modal: true,
    buttons:
      Submit: ->
        $(this).find("form").submit()
      Cancel: ->
        $(this).dialog "close"

  # Show modal on page load if errors exist
  if $("#donor-form-modal div#error_explanation").is("*")
    $('#donor-form-modal').dialog('open')

  jQuery(document).on "drag-select.selected", "table.donor-specimens tr", (event) ->
    row = jQuery(this)
    checkbox = row.find("input[type='checkbox']")
    checkbox.attr "checked", "checked"

  # This unintentionally (erroneously) makes it so that you can't drag the mouse to select/highlight text on any page in EQAPOL!
  # So, we're only calling drag_select IF the table exists, i.e. if were on the donor page.
  if jQuery("table.donor-specimens").length
    jQuery("table.donor-specimens").disableSelection
      table_row_selector: "table.donor-specimens tr"
      on_row_select: (event, start_row, end_row) ->
        DragSelect.preSelectRowRange start_row, end_row  if start_row?

  $('[data-new-donor-button]').on 'click', (event) =>
    event.preventDefault()
    $('#donor-form-modal').dialog('open')

  $('[data-new-note-button]').on 'click', (event) =>
    event.preventDefault()
    $('#note-form-modal').dialog('open')

window.select_aliquots = (checkbox, specimen_id) ->
  if $(checkbox).prop("checked")
    $("." + specimen_id + " input[type='checkbox']").prop('checked', true)
  else
    $("." + specimen_id + " input[type='checkbox']").prop('checked', false)

$ ->
  $('#enrollment-tab').on "click", '.add-fields',  ->
    $('#enrollment-tab').find("[data-type='submit']").show()
  $('#prescreen-tab').on "click", '.add-fields',  ->
    $('#prescreen-tab').find("[data-type='submit']").show()
  $('#donation-tab').on "click", '.add-fields',  ->
    $('#donation-tab').find("[data-type='submit']").show()

  $("#enrollment-tab form").validate()
