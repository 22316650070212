$ ->
  # Usage:
  # This js will apply to any textarea component with the `expandable` class.
  # It may also optionally indicate a container element that should also be expanded
  # with the class `expandable-container`.
  # See views/proficiency_test_enrollments/_ir_form_summary_of_incident.html.erb for example.
  #
  # If the textarea is invisible on page load, the scrollHeight will be 0px so the
  # textarea will revert to its min-height css property (but it will kick back in on
  # the first keyup event). Make sure `min-height` property is set and `height` is
  # not directly set for this to work as intended.
  #
  # strategy from https://stephanwagner.me/auto-resizing-textarea

  expandTextArea = (textArea, parentContainer = null) ->
    offset = textArea.offsetHeight - textArea.clientHeight
    $(textArea).css('height', 'auto').css('height', textArea.scrollHeight + offset)
    return unless parentContainer
    $(parentContainer).css('height', 'auto').css('height', textArea.scrollHeight + offset + 25)

  # expand the text area both on load and on keyup
  $('textarea.expandable').each ->
    possibleParent = $(this).closest('.expandable-container')
    parentContainer = if possibleParent.length == 1 then possibleParent
    expandTextArea(this, parentContainer)
    $(this).keyup ->
      expandTextArea(this, parentContainer)
