$ ->
  window.Assays =
    templates: {}
    selectedWellContents: {}
    selectedWellUsage: {}
    concentrationTO: false
    wellTooltipTO: false

    initialize: ->

      @initializeTabs()
      @initializeTooltipster()
      @compileTemplates()
      @bindEvents()

    initializeTabs: ->
      $("[data-tabs='assays']").tabs(
        select: (event, ui) ->
          if ui.index == 1
            $('.assay-view-plate-manager').click()
      )

    initializeTooltipster: ->
      $('td[data-well]').tooltipster(
        position: 'bottom'
        updateAnimation: false
      )

    compileTemplates: ->
      self = @
      $('script[type="text/x-handlebars-template"][data-for="assays"]').each (index, item) ->
        $item = $(item)
        self.templates[$item.attr('id')] = Handlebars.compile($item.html())

    sizePlateManager: ->
      manager = $('.assay-plate-manager')
      inventoryPalette = $('.assays-inventory-palette')
      plateList = $('.assay-plates-list')
      setTimeout(->
        newHeight = manager.height() - (inventoryPalette.outerHeight() + 16)
        plateList.height newHeight
        Assays.sizeSidebar newHeight
      , 100)

    sizeSidebar: (initialHeight) ->
      $sidebar = $('.assay-plates-sidebar')
      $sidebar.height initialHeight

    bindEvents: ->
      @initializeSelect2()
      @handleUnsavedChanges()
      @enablePlateTools()
      @bindAddNewPlate()
      self = @
      $('.assay-view-plate-manager').on 'click', (e) ->
        e.preventDefault()

        $('.assay-plates-overlay').show(0, ->
          self.sizePlateManager()
        )

      $('.assay-plate-manager-close').on 'click', (e) ->
        $('.assay-plates-overlay').hide()

      $(window).resize @sizePlateManager

      $('.assays_controller').on 'click', '.order-toggle-button', (e) ->
        orderId = $(this).attr('id')
        $("#order_#{orderId}_row").toggle()

      $plateList = $('.assay-plates-list')
      $plateList.on 'click', '.assay-plate-wells td:not(.non-selectable)', @selectCell
      $plateList.on 'click', '.assay-plate-wells th:gt(0)', @selectColumn
      $plateList.on 'click', '.assay-plate-wells td.non-selectable', @selectRow
      $plateList.on 'click', '.assays-plate-deselect-all-wells', @deselectAllWells

      $('.control-button').on 'click', @controlButton

      $inventoryPalette = $('.assays-inventory-palette')
      $inventoryPalette.on 'click', '.assay-plate-show-add', @showInventoryAddPanel
      $inventoryPalette.on 'click', '.close-add-options', @hideInventoryAddPanel
      $inventoryPalette.on 'change', 'select.assay-pallet-tools-select', @addInventorySelectPanel
      $inventoryPalette.on 'click', '.assay-remove-selector-button', @removeInventorySelectPanel

      $inventoryPalette.on 'click', '.assay-plate-add-inventory-link[data-for!="new"]', @showAddInventoryToSelectorModal

      $document = $(document)
      $document.off "click", ".add_reagent_link"
      $document.on "click", ".add_reagent_link:not(.add_from_kit_link)", @addReagentToInventory
      $document.off "click", ".add_specimen_link"
      $document.on "click", ".add_specimen_link", @addSpecimenToInventory
      $document.off "click", ".add_item_from_order_link"
      $document.on "click", ".add_item_from_order_link", @addItemFromOrderToInventory
      $document.off "click", ".add_from_kit_link"
      $document.on "click", ".add_from_kit_link", @addItemFromKitToInventory
      $document.on "click", "[data-add-new-item-button-type]", @addNewItemToInventory

      $document.on 'nested:fieldAdded', @addNewPlateToUI

      $inventoryPalette.on 'change', '.assay-inventory-item-selected', @inventoryItemSelected

      $inventoryPalette.on 'click', '.assay-selected-uncheck-all', @uncheckAllSelected
      $inventoryPalette.on 'click', '.assay-selected-add-to-wells', @addSelectedItemsToWells

      $('.assays-inventory-selected-items-list').on 'change', '.assay-selected-inventory-item', @uncheckSelectedItem

      $('.assay-remove-well-contents-button').on 'click', @removeWellContents

      $('.assay-selected-wells').on 'click', '.remove-item-from-selected-wells', @removeItemFromSelectedWells

      $inventoryPalette.on 'keyup', ".assay-inventory-item-concentration", @concentrationUpdated
      $inventoryPalette.on 'keyup', ".assay-inventory-item-concentration-units", @concentrationUpdated
      $inventoryPalette.on 'change', ".assay-inventory-item-study-type", @itemStudyTypeUpdated

      $('.assay-item-add-concentration-button').on 'click', @addItemConcentration
      $('.assay-item-add-concentration-cancel-button').on 'click', @removeNewItem
      $('.assay-plate-add-info-button').on 'click', (e) ->
        action = $('#plate-info-modal').data('action')
        if action == 'add'
          Assays.addPlateInfo(e)
        else
          PlateTools.duplicatePlate(e)
      $('.assay-plate-add-info-cancel-button').on 'click', @removeAddDuplicatePlate
      $('[data-button="plates-tab-submit"]').on 'click', @checkForComment
      $('[data-button="add-comment"]').on 'click', @addComment

      $inventoryPalette.on 'click', ".assay-inventory-item-options", @inventoryItemOptions
      $inventoryPalette.on 'click', ".assay-inventory-item-option-delete-item", @inventoryItemDeleteOption
      $inventoryPalette.on 'click', ".assay-inventory-item-option-add-concentration", @inventoryItemAddConcentrationOption

      $sidebar = $('.assay-plates-sidebar')
      $sidebar.on 'click', '.collapsible .assays-panel-header', @sidebarPanelHeaderClick
      $sidebar.on 'change', '.assays-sidebar-well-contents-field', @sidebarWellContentDisplay

      $('td[data-well]').on 'mouseover', @wellMouseover
      $('td[data-well]').on 'mouseout', @wellMouseout

    wellMouseover: (e) ->
      if Assays.wellTooltipTO
        clearTimeout Assays.wellTooltipTO

      Assays.wellTooltipTO = setTimeout(->
        $well = $(e.currentTarget)
        tmpl =
          items: []
        $well.find('.assay-well-item').each (index, item) ->
          $row = $("[data-assay-inventory-item-id='#{$(item).data('id')}']").closest('tr')
          tmpl.items.push(
            name: $row.find('[data-field="name"]').text()
            gid: $row.find('[data-field="gid-lot"]').text()
            concentration: $row.find('td:eq(3)').attr('title')
            itemType: $row.find('.assay-inventory-item-study-type').find('option:selected').text()
          )
        tmpl.control = $.trim($well.find('.assay-well-control-field').val())
        switch tmpl.control
          when '' then tmpl.control = "not a control well"
          when '-1' then tmpl.control = 'negative'
          when '1' then tmpl.control = 'positive'
        tmpl.results = $.trim($well.find('[data-spot-forming-cells-field]').val())
        if tmpl.results == ''
          tmpl.results = 'n/a'

        tooltipHtml = Assays.templates.assays_well_tooltip_content(tmpl)
        $well.tooltipster 'content', $(tooltipHtml)
        $well.tooltipster 'show'
      , 500)

    wellMouseout: (e) ->
      if Assays.wellTooltipTO
        clearTimeout Assays.wellTooltipTO
        Assays.wellTooltipTO = false

    checkForComment: (event) ->
      event.preventDefault()
      if Assays._commentAlreadyEntered()
        $(event.target).closest('form').submit()
      else
        Modals.showEditAssayCommentModal()

    _commentAlreadyEntered: ->
      $('.note-text').val().length > 0

    addComment: (event) ->
      comment = $('[data-field="edit-assay-comment"]').val()
      $('[data-assay-comments-section] .note-text').val(comment)
      Modals.hideEditAssayCommentModal()
      $('[data-form-for-assay]').submit()

    bindAddNewPlate: () ->
      $("[data-form-for-assay]").on "click", "[data-add-new-plate-sidebar-button]", (event) ->
        event.preventDefault()
        $("[data-add-new-plate-button]").click()

    sidebarPanelHeaderClick: (e) ->
      $target = $(e.currentTarget)
      $target.next(".control-container-content").toggle 'collapsed'
      $target.find('.triangle').toggleClass 'collapsed'

    sidebarWellContentDisplay: (e) ->
      $checkbox = $(e.currentTarget)

      switch $checkbox.val()
        when "controls" then $('[data-well] .control').toggle()
        when "results" then $('.assay-well-result-display').toggle()
        else $(".assay-well-item[data-keyfield='#{$checkbox.val()}']").toggle()

    refreshWellContentDisplay: (container) ->
      $container = $(container)
      wellDetailsCheckboxes = $('.assays-sidebar-well-contents-field')
      wellDetailsCheckboxes.each (index, item) ->
        $checkbox = $(item)
        wellDetailIdentifier = $checkbox.val() # either a KeyFieldName id or a string, such as 'results' or 'controls'
        wellDetailSelector = switch wellDetailIdentifier
          when "controls" then '[data-well] .control'
          when "results" then '.assay-well-result-display'
          else ".assay-well-item[data-keyfield='#{wellDetailIdentifier}']"
        if $checkbox.prop('checked')
          $container.find(wellDetailSelector).show()
        else
          $container.find(wellDetailSelector).hide()

    concentrationUpdated: (e) ->
      if Assays.concentrationTO
        clearTimeout Assays.concentrationTO

      Assays.concentrationTO = setTimeout(->
        $row = $(e.currentTarget).closest('tr')
        itemId = $row.data('assay-inventory-item-id')
        $concentration = $row.find('.assay-inventory-item-concentration')
        concentration = $concentration.val()
        units = $row.find('.assay-inventory-item-concentration-units').val()
        $concentration.closest('td').attr 'title', "#{concentration} #{units}"

        Assays.sendConcentrationUpdate itemId, concentration, units

        Assays.concentrationTO = false
      , 750)

    sendConcentrationUpdate: (itemId, concentration, units) ->
      dataToSend =
        concentration: concentration
        concentration_units: units
      $.ajax(
        type: 'PUT'
        url: "/assay_inventory_items/#{itemId}"
        data: dataToSend
        success: (data, status, xhr) ->
          if data.success
            $row = $(".assays-inventory-list tr[data-assay-inventory-item-id='#{itemId}']")
            $cell = $row.find('.assay-inventory-item-concentration').closest('td')
            $cell.css
              'background-color': '#CDE9FF'
            setTimeout(->
              $cell.animate(
                { 'background-color': $cell.closest('tr').css('background-color') }
                500
              )
            , 1500)

            # clear out the modal
            $dialog = $('.assay-item-concentration-fields')
            $dialog.find('.assay-new-item-concentration').val ''
            $dialog.find('.assay-new-item-concentration-units').val ''
        dataType: 'json'
      )

    itemStudyTypeUpdated: (e) ->
      $select = $(e.currentTarget)
      $row = $select.closest('tr')
      dataToSend =
        item_study_type: $select.val()
      $.ajax(
        type: 'PUT'
        url: "/assay_inventory_items/#{$row.data('assay-inventory-item-id')}"
        data: dataToSend
        success: (data, status, xhr) ->
          if data.success
            $cell = $select.closest('td')
            $cell.css
              'background-color': '#CDE9FF'
            setTimeout(->
              $cell.animate(
                { 'background-color': '#fff' }
                500
              )
            , 1500)
        dataType: 'json'
      )

    addNewPlateToUI: (e) ->
      if $('.assay-plate-wells[data-plate=""]').length > 0
        $('.assay-plate-wells[data-plate=""]').each (index, item) ->
          $plate = $(item)
          $plateContainer = Assays.plateContainerFor($plate)
          PlateTools.setNewPlatePlateToolsDisabledMessage($plateContainer)
          Modals.showPlateInfoModal $plate, 'add'

    plateContainerFor: (plate) ->
      $(plate).closest('[data-assay-plate-container]')

    inventoryItemSelected: (e) ->
      $checkbox = $(e.currentTarget)

      if $checkbox.is(':checked')
        Assays.addInventoryItemToSelected $checkbox.closest('tr')
      else
        Assays.removeInventoryItemFromSelected $checkbox.closest('tr')

    addInventoryItemToSelected: ($inventoryItem) ->
      concentration = $inventoryItem.find('[data-name="concentration"]').val()
      concentrationUnits = $inventoryItem.find('[data-name="concentration_units"]').val()
      $inventory = $inventoryItem.closest('.assay-inventory-selector')
      $selectedInventory = $('.assays-inventory-selected-items-list')
      tmpl =
        item: $inventory.find('h3').text()
        name: $inventoryItem.find('[data-field="name"]').text()
        lotGID: $inventoryItem.find('[data-field="gid-lot"]').text()
        concentration: "#{concentration} #{concentrationUnits}"
        id: $inventoryItem.data('assay-inventory-item-id')
        inventory: $inventory.data('selector-id')

      if $selectedInventory.find("tr[data-inventory='#{tmpl.inventory}']").length > 0
        if confirm "There is already one item from #{tmpl.item} in the selected list.
 Are you sure you would like add another?"
          @buildSelectedInventoryItem tmpl
        else
          $checkbox = $inventoryItem.find('[data-checkbox="select-assay-inventory-item"]')
          $checkbox.prop('checked', false)
      else
        @buildSelectedInventoryItem tmpl

    buildSelectedInventoryItem: (tmpl) ->
      selectedItem = @templates.selected_inventory_item(tmpl)
      $('.assays-inventory-selected-items-list').find('tbody').append selectedItem

    removeInventoryItemFromSelected: ($inventoryItem) ->
      id = $inventoryItem.data('assay-inventory-item-id')
      $('.assays-inventory-selected-items-list').find("tr[data-id='#{id}']").remove()

    uncheckAllSelected: (e) ->
      e.preventDefault()

      $inventory = $('.assays-inventory-selected-items-list')
      $inventory.find('.assay-selected-inventory-item:checked').each (index, item) ->
        $(item).change()

    uncheckSelectedItem: (e) ->
      $checkbox = $(e.currentTarget)

      $(".assay-inventory-item-selected[value='#{$checkbox.val()}']").click()

    addSelectedItemsToWells: (e) ->
      e.preventDefault()

      $inventory = $('.assays-inventory-selected-items-list')
      data = []
      $inventory.find('.assay-selected-inventory-item').each (index, item) ->
        $row = $(item).closest 'tr'
        id = $row.data('id')
        name = "#{$row.find('td:eq(2)').text()} #{$row.find('td:eq(3)').text()}"
        keyfield = $row.data('inventory')
        display = ''
        unless $(".assays-sidebar-well-contents-field[value='#{keyfield}']").is(':checked')
          display = ' style="display: none;"'
        tmpl = "<div class=\"assay-well-item\" data-id=\"#{id}\" data-keyfield=\"#{keyfield}\" data-unsaved-well-inventory-item#{display}>
  <input name=\"well_%PLATE%_%WELL%_items[]\" value=\"#{id}\" type=\"hidden\" class=\"assay-well-inventory-item-field\">
  #{name}
</div>"
        data.push
          item:
            name: name
            id: id
          tmpl: tmpl

      $('.assay-plate-wells').find('td.selected').each (index, cell) ->
        $cell = $(cell)

        for index, item of data
          plate = $cell.closest('.assay-plate-wells').data('plate')
          well = $cell.data('well-id')
          $cell.find("input[name='remove_well_#{plate}_#{well}_items[]'][value='#{item.item.id}']").remove()
          if $cell.find(".assay-well-item[data-id='#{item.item.id}']").length == 0
            $cell.find('.fields').append $(item.tmpl.replace('%WELL%', well).replace('%PLATE%', plate))
            Assays.addWellContentsToSelectedDisplay $cell

    removeWellContents: (e) ->
      if confirm "Are you sure you want to remove all contents from the selected well?"
        $('.assay-plate-wells').find('td.selected').each (index, well) ->
          $well = $(well)
          $well.find('.assay-well-control-field').val('')
          $well.find('.control').removeClass('negative positive').attr('title', '').html('')
          $well.find('.assay-well-item').each (itemIndex, item) ->
            Assays.removeWellItem $well, $(item)

    removeWellItem: ($well, $item) ->
      hidden = $item.find('.assay-well-inventory-item-field')
      hidden.remove()
      hidden.attr('name', 'remove_' + hidden.attr('name'))
      $well.append hidden
      $item.remove()

    removeItemFromSelectedWells: (e) ->
      e.preventDefault()
      $button = $(e.currentTarget)

      if confirm "Are you sure you want to remove '#{$.trim($button.text())}' from the selected well(s)?"
        $('.assay-plate-wells').find('td.selected').each (index, well) ->
          $well = $(well)
          $well.find(".assay-well-item[data-id='#{$button.data('id')}']").each (itemIndex, item) ->
            Assays.removeWellItem $well, $(item)

      delete Assays.selectedWellUsage[$button.data('id')]
      delete Assays.selectedWellContents[$button.data('id')]
      Assays.refreshSelectedWellContentList null, [$button.data('id')]

    selectCell: (e) ->
      $cell = $(e.currentTarget)
      if $cell.hasClass('non-selectable')
        return

      $table = $cell.closest('table')
      $table.find('th.selected-column').removeClass('selected-column')
      $table.find('td.selected-row').removeClass('selected-row')

      unless e.shiftKey
        unless e.ctrlKey || e.metaKey
          $table.find('td.selected').not($cell).each (index, wasSelected) ->
            $wasSelected = $(wasSelected)
            $wasSelected.removeClass 'selected'
            Assays.removeWellContentsFromSelectedDisplay $wasSelected

#        $cell.toggleClass 'selected'
        if $cell.hasClass('selected')
          $cell.removeClass 'selected'
          Assays.removeWellContentsFromSelectedDisplay $cell
        else
          $cell.addClass 'selected'
          Assays.addWellContentsToSelectedDisplay $cell
      else
        unless $table.find('td[data-drag-start]').length > 0
          $cell.attr('data-drag-start', 1)
          $cell.closest('table').find('td').not('.selectable').removeClass 'selected'
        else
          $start = $table.find('[data-drag-start]')
          startRow = Number $start.data('row')
          startColumn = Number $start.data('column')
          endRow = Number $cell.data('row')
          endColumn = Number $cell.data('column')
          selecting = !$start.hasClass('selected')

          if endRow > startRow
            y1 = startRow
            y2 = endRow
          else
            y1 = endRow
            y2 = startRow

          if endColumn > startColumn
            x1 = startColumn
            x2 = endColumn
          else
            x1 = endColumn
            x2 = startColumn

          for column in [x1..x2]
            for row in [y1..y2]
              if (selecting)
                $cell = $table.find("td[data-row='#{row}'][data-column='#{column}']")
                $cell.addClass('selected')
                Assays.addWellContentsToSelectedDisplay $cell
              else
                $cell = $table.find("td[data-row='#{row}'][data-column='#{column}']")
                $cell.removeClass('selected')
                Assays.removeWellContentsFromSelectedDisplay $cell

          $start.removeAttr('data-drag-start')

    deselectAllWells: (e) ->
      e.preventDefault()

      $('td[data-well].selected').removeClass 'selected'
      $('th.non-selectable.selected-column').removeClass 'selected-column'
      $('td.non-selectable.selected-row').removeClass 'selected-row'

    selectColumn: (e) ->
      $column = $(e.currentTarget)
      column = e.currentTarget.cellIndex
      $column.toggleClass('selected-column')
      $(e.currentTarget).closest('table').find("tr").find("td:eq(#{column})").each (index, item) ->
        $item = $(item)
        if $column.hasClass('selected-column')
          $item.addClass 'selected'
          Assays.addWellContentsToSelectedDisplay $item
        else
          $item.removeClass 'selected'
          Assays.removeWellContentsFromSelectedDisplay $item

    selectRow: (e) ->
      $row = $(e.currentTarget)
      $row.toggleClass('selected-row')
      $row.closest('tr').find('td:gt(0)').each (index, item) ->
        $item = $(item)
        if $row.hasClass('selected-row')
          $item.addClass 'selected'
          Assays.addWellContentsToSelectedDisplay $item
        else
          $item.removeClass 'selected'
          Assays.removeWellContentsFromSelectedDisplay $item

    addWellContentsToSelectedDisplay: ($well) ->
      self = @
      toAdd = []
      $well.find('.assay-well-item').each (index, item) ->
        $item = $(item)
        unless self.selectedWellContents.hasOwnProperty($item.data('id'))
          self.selectedWellContents[$item.data('id')] = $.trim($item.text())
          toAdd.push $item.data('id')
        unless self.selectedWellUsage.hasOwnProperty($item.data('id'))
          self.selectedWellUsage[$item.data('id')] = []
        unless self.selectedWellUsage[$item.data('id')].indexOf($well.data('well-id')) > -1
          self.selectedWellUsage[$item.data('id')].push $well.data('well-id')

      @refreshSelectedWellContentList toAdd

    removeWellContentsFromSelectedDisplay: ($well) ->
      self = @
      toRemove = []
      $well.find('.assay-well-item').each (index, item) ->
        $item = $(item)
        itemPos = self.selectedWellUsage[$item.data('id')].indexOf($well.data('well-id'))
        self.selectedWellUsage[$item.data('id')].splice itemPos, 1
        if self.selectedWellUsage[$item.data('id')].length == 0
          delete self.selectedWellUsage[$item.data('id')]
          delete self.selectedWellContents[$item.data('id')]
          toRemove.push $item.data('id')

      @refreshSelectedWellContentList null, toRemove

    refreshSelectedWellContentList: (toAdd = null, toRemove = null) ->
      $allList = $('.assays-selected-items-list.common')
      $someList = $('.assays-selected-items-list.some')
      $allLists = $('.assays-selected-items-list')
      numSelected = $('td.selected').length
      self = @

      if toAdd
        for item in toAdd
          itemData = @selectedWellContents[item]
          $item = $("<a class=\"delete-link remove-item-from-selected-wells\" data-id=\"#{item}\" href=\"#\">#{itemData}</a>")
          if numSelected == @selectedWellUsage[item].length
            $allList.append $item
          else
            $someList.append $item

      if toRemove
        for item in toRemove
          $allLists.find(".remove-item-from-selected-wells[data-id='#{item}']").remove()

      $allList.find('.remove-item-from-selected-wells').each (index, item) ->
        $item = $(item)
        unless numSelected == self.selectedWellUsage[$item.data('id')].length
          $item.remove()
          unless $someList.find(".remove-item-from-selected-wells[data-id='#{$item.data('id')}']").length > 0
            $someList.append $item
      $someList.find('.remove-item-from-selected-wells').each (index, item) ->
        $item = $(item)
        if numSelected == self.selectedWellUsage[$item.data('id')].length
          $item.remove()
          unless $allList.find(".remove-item-from-selected-wells[data-id='#{$item.data('id')}']").length > 0
            $allList.append $item

    controlButton: (e) ->
      rows = ["A", "B", "C", "D", "E", "F", "G", "H"]
      controlType = $(e.currentTarget).data('type')
      alreadyPositiveCells = []
      alreadyNegativeCells = []
      $('.assay-plate-wells').find('td.selected').each (index, item) ->
        $item = $(item)
        $control = $item.find('.control')
        $field = $item.find('.assay-well-control-field')
        if $control.hasClass('positive') && controlType == 'negative'
          alreadyPositiveCells.push  "#{rows[Number($item.data('row')) - 1]}#{$item.data('column')}"
        else if $control.hasClass('negative') && controlType == 'positive'
          alreadyNegativeCells.push  "#{rows[Number($item.data('row')) - 1]}#{$item.data('column')}"
        else if $control.hasClass("#{controlType}")
          $control.removeClass controlType
          $control.html ''
          $field.val null
        else
          $control.addClass controlType
          if controlType == 'negative'
            $field.val -1
            $control.html '&ndash;'
            $control.attr 'title', "Cell is a Negative Control"
          else
            $field.val 1
            $control.html '+'
            $control.attr 'title', "Cell is a Positive Control"
      if alreadyPositiveCells.length > 0
        alert "The following cells have already been marked as positive controls: #{alreadyPositiveCells.join(", ")}"
      if alreadyNegativeCells.length > 0
        alert "The following cells have already been marked as negative controls: #{alreadyNegativeCells.join(", ")}"

    showInventoryAddPanel: (e) ->
      e.preventDefault()

      $(e.currentTarget).closest('.assays-inventory-list-options').find(".assay-plate-add-options").show()

    hideInventoryAddPanel: (e) ->
      e.preventDefault()

      $(e.currentTarget).closest(".assay-plate-add-options").hide()

    addInventorySelectPanel: (e) ->
      $select = $(e.currentTarget)
      $selected = $(e.currentTarget).find('option:selected')

      keyFieldId = $selected.val()
      if keyFieldId == 'Palette Tools'
        return

      tmpl =
        header: $selected.text()
        type: $selected.text().toLowerCase()
        removable: true
        id: keyFieldId
      selectorHtml = window.Assays.templates.assays_inventory_selector(tmpl)
      $selectorsList = $('.assays-inventory-selectors')
      $selectorsList.append selectorHtml
      numSelectors = $selectorsList.find('.assay-inventory-selector').length
      $selectorsList.width(385 * numSelectors)

      $selected.attr 'disabled', true
      $select.find('option:first').prop 'selected', true
      Assays.addKeyFieldToPlatesSidebar(keyFieldId)

    addKeyFieldToPlatesSidebar: (keyFieldId) ->
      $wellDetailsKeyField = $("[data-well-details-key-field-id='#{keyFieldId}']")
      $wellDetailsKeyField.show()
      $wellDetailsKeyField.find('[data-well-details-key-field-checkbox]').prop('checked', true)

    removeInventorySelectPanel: (e) ->
      e.preventDefault()

      if confirm("Are you sure you want to remove the selected panel?
The inventory items from this panel will be removed from all wells.")
        $selector = $(e.currentTarget).closest('.assay-inventory-selector')
        $selector.remove()

        # remove the inventory from this selector from all wells and the sidebar
        toRemove = []
        $selector.find('tr[data-assay-inventory-item-id]').each (index, item) ->
          itemId = $(item).data('assay-inventory-item-id')
          toRemove.push itemId
          $(".remove-item-from-selected-wells[data-id='#{itemId}']").remove()

        # re-enable in the drop-down
        $select = $('.assays-inventory-palette').find('select.assay-pallet-tools-select')
        $select.find("option[value='#{$selector.data('selector-id')}']").removeAttr 'disabled'

        # remove items from the assay's inventory item collection
        Assays.removeItemsFromSystem toRemove
        keyFieldId = $selector.data('selector-id')
        Assays.removeKeyFieldFromPlatesSidebar(keyFieldId)

    removeItemsFromSystem: (toRemove) ->
      unless typeof toRemove == "object"
        toRemove = [toRemove]

      #remove the item from all wells and the selected panel
      for itemId in toRemove
        $(".assay-well-item[data-id='#{itemId}']").remove()
        $(".assay-selected-inventory-item[value='#{itemId}']").closest('tr').remove()

      # remove items from the assay's inventory item collection
      dataToSend =
        items: toRemove
      $.post(
        '/assay_inventory_items/delete_inventory_items'
        dataToSend
        (data, status, xhr) ->
          # nothing to do as this was clean up
        'json'
      )

    removeKeyFieldFromPlatesSidebar: (keyFieldId) ->
      $("[data-well-details-key-field-id='#{keyFieldId}']").hide()

    showAddInventoryToSelectorModal: (e) ->
      e.preventDefault()

      $button = $(e.currentTarget)
      type = $button.data('for')
      $('.assays-inventory-selection-container').removeClass 'selected'
      $inventory = $button.closest('.assays-inventory-selection-container')
      $inventory.addClass('selected')

      Modals["show#{type}Modal"](e)

      if type == "Orders"
        $('.assays_controller').find('.order_item_fulfillment_rows').hide()

      $button.closest('.assay-plate-add-options').hide()

    addReagentToInventory: (e) ->
      e.preventDefault()

      link = $(e.currentTarget)
      resultId = link.closest('tr').attr('id')
      sectionType = link.attr('id') # "new", "old", or "test"
      resultName = link.closest('tr').find('.result_name').html()
      lotNumer = link.closest('tr').find('[data-lot-number]').data('lot-number')

      tmpl =
        resultName: resultName
        resultId: resultId
        itemStudyType: sectionType
        lotNumber: lotNumer
        numberIdentifier: lotNumer
        type: 'Lot'
      Assays.addItemToInventory tmpl

    addItemFromKitToInventory: (e) ->
      e.preventDefault()

      link = $(e.currentTarget)
      $row = link.closest('tr')

      resultId = $row.attr('id')
      sectionType = link.attr('id')
      resultName = $row.find('[data-result-name]').data('result-name')
      numberIdentifier = $row.find('[data-number-identifier]').val() # lot_number or gid
      partType = $row.find('[data-part-type]').data('part-type') # Specimen or ReagentVolume

      tmpl =
        resultName: resultName
        resultId: resultId
        itemStudyType: sectionType
        numberIdentifier: numberIdentifier
      if partType == 'Specimen'
        tmpl.type = 'Specimen'
        tmpl.gid = numberIdentifier
      else
        tmpl.type = "Lot"
        tmpl.lotNumber = numberIdentifier
      Assays.addItemToInventory tmpl

    addNewItemToInventory: (event) ->
      event.preventDefault()

      link = $(event.currentTarget)

      $form = link.closest('form')
      if Assays.formValid($form)

        tmpl = Assays.templateForNewItem(link)
        Assays.addItemToInventory tmpl
        Assays.clearNewItemModalFields()

    addItemConcentration: (e) ->
      concentration = ''
      units = ''

      if e
        e.preventDefault()
        $form = $(e.currentTarget).closest('.assay-item-concentration-fields')
        concentration = $.trim($form.find('.assay-new-item-concentration').val())
        units = $.trim($form.find('.assay-new-item-concentration-units').val())

        if concentration == ''
          alert 'A concentration/dilution value is required.'
          return

      $row = $('#item-concentration-dilution-modal').data 'row'
      $concentration = $row.find('.assay-inventory-item-concentration')
      $concentration.val concentration
      $concentration.closest('td').attr 'title', "#{concentration} #{units}"
      $row.find('.assay-inventory-item-concentration-units').val units

      Modals.hideItemConcentrationModal()

      itemId = $.trim($row.data('item-id'))
      if itemId == 'foo'
        itemId = null
      itemType = $.trim($row.data('item-type'))
      if itemType == ''
        itemType = $.trim($row.data('type'))
      dataToSend =
        assay_inventory_item:
          item_id: itemId
          item_type: itemType
          concentration: concentration
          concentration_units: units
          item_study_type: $row.find('select[data-name="item_study_type"]').val()
          key_field_name_id: $row.closest('.assay-inventory-selector').data('selector-id')
          assay_id: $('.edit_assay').data('id')
          id1_name: $.trim($row.find('[data-field="name"]').text())
          gid_lot_number: $.trim($row.find('[data-field="gid-lot"]').text())
      if itemId == null
        dataToSend.new_assay_item_attributes =
          name: dataToSend.assay_inventory_item.id1_name
          number_identifier: dataToSend.assay_inventory_item.gid_lot_number
          item_type: itemType

      $.post(
        '/assay_inventory_items.json'
        dataToSend#tmpl
        (data) ->
          $row.data 'item-id', data.item.item_id
          $row.attr 'data-item-id', data.item.item_id
          $row.data 'type', data.item.item_type
          $row.attr 'data-type', data.item.item_type
          $row.data 'assay-inventory-item-id', data.item.id
          $row.attr 'data-assay-inventory-item-id', data.item.id
          $row.find('.assay-inventory-item-selected').val data.item.id

           # re-enable actions now that it's saved
          $row.find('input, select').prop 'disabled', false
          $row.find('.assay-inventory-item-options').show()

          # reset the modal
          if e
            $form.find('.assay-new-item-concentration').val ''
            $form.find('.assay-new-item-concentration-units').val ''
        'json'
      )

    removeNewItem: (e) ->
      $('#item-concentration-dilution-modal').data('row').remove()
      Modals.hideItemConcentrationModal()
      $('#item-concentration-dilution-modal').data 'row', null

    addPlateInfo: (e) ->
      e.preventDefault()

      $form = $(e.currentTarget).closest('.assay-plate-info-fields')
      [lotNumber, uniqueId] = Assays.getPlateInfoFormData $form

      $plate = $('#plate-info-modal').data 'plate'
      $plateContainer = $plate.closest('.assays-plate')
      @refreshWellContentDisplay($plateContainer)
      Assays.setPlateInfo $plateContainer, lotNumber, uniqueId

      Modals.hidePlateInfoModal()
      Assays.resetPlateInfoForm $form

      $.post(
        "/plates_for_assays.json"
        {
          assay: $('.edit_assay').data('id')
          lot_number: lotNumber
          unique_id: uniqueId
        }
        (data) ->
          if data.error_messages.length  > 0
            $plateContainer.remove()
            alert("Error: Unable to create plate. #{data.error_messages}")
          else
            $plate.data 'plate', data.plate.id
            $plate.attr 'data-plate', data.plate.id
            $plateContainer.find('[data-plate-id]').val(data.plate.id)
            $plateContainer.attr 'data-plate-id', data.plate.id
            $plateContainer.data 'plate-id', data.plate.id
            for index, well of data.wells
              $well = $plate.find "td[data-row='#{well.well_row}'][data-column='#{well.well_column}']"
              $well.data 'well-id', well.id
              $well.attr 'data-well-id', well.id
              $well.find("[data-well-id-field]").val(well.id)
            PlateTools.enablePlateToolsMenuInContainer($plateContainer)
            PlateTools.clearPlateToolsMessage($plateContainer)

        'json'
      )

    removeAddDuplicatePlate: (e) ->
      $modal = $('#plate-info-modal')
      $modal.data('plate').closest('.assays-plate').remove()

      $originalPlate = $('#plate-info-modal').data('originalPlate')
      Modals.hidePlateInfoModal()
      PlateTools.enablePlateToolsMenuInContainer($originalPlate)
      PlateTools.clearPlateToolsMessage($originalPlate)
      Modals.resetPlateInfoData()

    getPlateInfoFormData: ($form) ->
      lotNumber = $.trim($form.find('.assay-new-plate-lot-number').val())
      uniqueId = $.trim($form.find('.assay-new-plate-unique-id').val())

      if lotNumber == ''
        alert 'A plate lot number is required.'
        return [null, null]
      if uniqueId == ''
        alert 'A plate unique ID is required.'
        return [null, null]

      [lotNumber, uniqueId]

    resetPlateInfoForm: ($form) ->
      $form.find('.assay-new-plate-lot-number').val ''
      $form.find('.assay-new-plate-unique-id').val ''

    setPlateInfo: ($plateContainer, lotNumber, uniqueId) ->
      $plateContainer.find('.assays-plate-lot-number-field').val lotNumber
      $plateContainer.find('.assays-plate-unique-id-field').val uniqueId

    inventoryItemOptions: (e) ->
      $button = $(e.currentTarget)
      $button.find('.assay-inventory-item-options-panel').toggle()

    inventoryItemDeleteOption: (e) ->
      e.preventDefault()
      $button = $(e.currentTarget)
      $button.find('.assay-inventory-item-options-panel').hide()

      if confirm("Are you sure you want to delete this inventory item?
 It will also be removed from any wells it is currently added to.")
        $row = $(e.currentTarget).closest('tr')
        itemId = $row.data('assay-inventory-item-id')
        Assays.removeItemsFromSystem [itemId]
        $(".assay-well-inventory-item-field[value='#{itemId}']").remove()

        # remove item from inventory list
        $row.remove()

    inventoryItemAddConcentrationOption: (e) ->
      e.preventDefault()
      $target = $(e.currentTarget)

      $row = $target.closest('tr')
      $target.find('.assay-inventory-item-options-panel').hide()
      $row.closest('.assays-inventory-selection-container.selected').removeClass 'selected'
      $row.closest('.assays-inventory-selection-container').addClass 'selected'

      tmpl =
        resultName: $row.find('[data-field="name"]').text()
        resultId: $row.data('item-id')
        itemStudyType: $row.find('.assay-inventory-item-study-type').find('option:selected').text().toLowerCase()
        type: $row.data('type')
        numberIdentifier: $row.find('[data-field="gid-lot"]').text()
      switch tmpl.type
        when "Specimen"
          tmpl.gid = $row.find('[data-field="gid-lot"]').text()
        when "NewAssayItem"
          tmpl.resultId = 'foo'
          tmpl.new_assay_item_attributes = # for params in ajax post
            name: tmpl.resultName
            number_identifier: $row.find('[data-field="gid-lot"]').text()
            item_type: $row.data('item-type')
        else # eg. when 'Lot' or 'ReagentTransaction'
          tmpl.lotNumber = $row.find('[data-field="gid-lot"]').text()
      Assays.addItemToInventory tmpl

    templateForNewItem: (addNewItemButton) ->
      newItemForm = $('[data-form-for="new-assay-item"]')

      sectionType = addNewItemButton.data("add-new-item-button-type")
      resultName = newItemForm.find('[data-attribute-name="name"]').val()
      numberIdentifier = newItemForm.find('[data-attribute-name="number-identifier"]').val()
      itemType = newItemForm.find('[data-attribute-name="type"]').val()

      tmpl =
        resultName: resultName
        itemStudyType: sectionType
        resultId: 'foo'
        numberIdentifier: numberIdentifier
        new_assay_item_attributes: # for params in ajax post
          name: resultName
          number_identifier: numberIdentifier
          item_type: itemType

      if itemType == "Specimen"
        tmpl.gid = numberIdentifier
        tmpl.type = 'Specimen'
      else
        tmpl.lotNumber = numberIdentifier
        tmpl.type = 'Lot'

      tmpl

    formValid: (form) ->
      validator = $(form).validate()
      validator.form()

    clearNewItemModalFields: () ->
      $('input[data-attribute-name], select[data-attribute-name]', '[data-form-for="new-assay-item"]').each (_index, field) ->
        $(field).val("")

    addSpecimenToInventory: (e) ->
      e.preventDefault()

      link = $(e.currentTarget)
      resultId = link.closest('div.reagent-fields').children('#specimen_id').html()
      sectionType = link.attr('id') # "new", "old", or "test"
      resultName = link.closest('div.reagent-fields').children('#result_name').html()#id1
      gid = link.closest('div.reagent-fields').find('[data-gid]').data('gid')

      tmpl =
        resultName: resultName
        resultId: resultId
        itemStudyType: sectionType
        gid: gid
        numberIdentifier: gid
        type: 'Specimen'
      Assays.addItemToInventory tmpl

    addItemFromOrderToInventory: (e) ->
      e.preventDefault()

      link = $(e.currentTarget)

      $fields = link.closest('div.reagent-fields')

      resultId = $fields.data('reagent-id')
      sectionType = link.attr('id') # "new", "old", or "test"
      resultName = $fields.data('item-name-id1') # name or id1
      resultType = $fields.data('item-type')
      gid = $fields.data('item-gid-lot-num') # gid or lot number

      tmpl =
        resultName: resultName
        resultId: resultId
        itemStudyType: sectionType
        gid: gid
        numberIdentifier: gid
        type: resultType
      Assays.addItemToInventory tmpl

    addItemToInventory: (tmpl) ->
      $inventory = $('.assays-inventory-selection-container.selected')
      newRow = Assays.templates.assays_inventory_item(tmpl)
      $inventory.find('.assays-inventory-list tbody').append newRow
      $('.ui-dialog-content:visible').dialog 'close'
      Assays.sizePlateManager()

      $newRow = $inventory.find("tr[data-item-id='#{tmpl.resultId}']:last")

      if $inventory.data('type') == 'detecting antibodies' || $inventory.data('type') == 'substrate'
        $('#item-concentration-dilution-modal').data 'row', $newRow
        Assays.addItemConcentration()
      else
        Modals.showItemConcentrationModal $newRow

    initializeSelect2: () ->
      $keyFieldSelectTags = $("[data-key-field-select]")
      @activateSelect2($keyFieldSelectTags)

    activateSelect2: (selectField) ->
      $selectField = $(selectField)
      select2Options = {
        placeholder: "Select a key field",
        allowClear: false,
        width: 'resolve'
      }
      if selectField.closest('[data-mode="edit"]').length > 0
        select2Options.allowClear = true
      $selectField.select2(select2Options)

    handleUnsavedChanges: () ->
      @_markChangedFields()
      @_cleanupMarkedFieldsOnFormSubmit()
      @_warnUnsavedChanges()

    _markChangedFields: () ->
      $('[data-mode="edit"] [data-form-for-assay]').on 'change', 'input, textarea, select', (event) ->
        $(event.target).addClass('changed-input')

    _cleanupMarkedFieldsOnFormSubmit: () ->
      $('[data-mode="edit"] [data-form-for-assay]').on 'submit', () ->
        $('.changed-input').removeClass('changed-input')

    _warnUnsavedChanges: () ->
      $(window).on 'beforeunload', (event) ->
        if $('.changed-input').length > 0
          return "You have unsaved changes."

    enablePlateTools: () ->
      $('[data-plate-tools-menu]').prop('disabled', false)

  window.Assays.initialize() if $("[data-container-for-assay]").length > 0
