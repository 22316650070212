window.LagSubmissionForm = do ->
  initialized = false
  self = {}

  init = ->
    unless initialized
      initialized = true
      self.$pasteColumns = $('[data-paste-columns]')
      self.$naAllConfirmatory = $("[data-lag-submission='na-all']")
      self.$naConfirmatory = $("[data-lag-submission='na']")
      setInitialState()
      attachBehaviors()

  setInitialState = ->
    disableConfirmatoryResults()

  attachBehaviors = ->
    bindPasteCalibratorColumns()
    bindCheckNaAll()
    bindCheckNa()

  bindPasteCalibratorColumns = ->
    self.$pasteColumns.on 'paste', ->
      setTimeout =>
        $input = $(@)
        values = $input.val().split(/\s/)
        targetContainerId = $input.attr('data-paste-columns')
        $targets = $('#' + targetContainerId).find('.paste-input')
        $targets.each (i, target) =>
          $(target).val(values[i])
        $input.val('')
      , 100

  bindCheckNaAll = ->
    self.$naAllConfirmatory.on 'click', (e) ->
      $masterCheckbox = $(e.target)
      checked = $masterCheckbox.prop('checked')
      $checkboxes = $masterCheckbox.parents('table').find("[data-lag-submission='na']")
      $checkboxes.each (_, checkbox) ->
        $checkbox = $(checkbox)
        if checked
          $checkbox.prop('checked', true)
        else
          $checkbox.prop('checked', false)
        $checkbox.trigger('change')

  bindCheckNa = ->
    self.$naConfirmatory.on 'change', (e) ->
      disableAffectedByNa($(e.target))

  disableConfirmatoryResults = ->
    self.$naConfirmatory.each (_, e) ->
      disableAffectedByNa($(e))

  disableAffectedByNa = ($checkbox) ->
    readonly = $checkbox.is(':checked')
    $affectedFields = $checkbox.parents('tr').find("[data-lag-submission='affected-by-na']")
    $affectedFields.prop('readonly', readonly)
    $affectedFields.each ->
      # this will clear fields if 'n/a' is readonly, and restore the cleared value if they uncheck 'n/a'
      $field = $(@)
      if readonly
        $field.data('pre-disabled-value', $field.val())
        $field.val('')
      else if $field.data('pre-disabled-value')
        $field.val($field.data('pre-disabled-value'))

  init: init

$ ->
  window.LagSubmissionForm.init() if $("[data-lag-submission='na']").length
