$.toggle_dhe_id = () ->
  dhe = $(".dhe_auth:checked").val()
  if(dhe is "true")
    field = $("#user_dhe_id")

    # If the value has been emptied, reset to the default value when we show it again.
    if(field.val() == "")
      field.val(field.attr("defaultValue"))

    $("#dhe-id").show()
    field.focus()
  else
    $("#dhe-id").hide()
    $("#user_dhe_id").val('') # Clear out the dhe_id field since this is not a dhe user

$ ->
  $.toggle_dhe_id()

  $(".user_form").on 'click', '.dhe_auth', ->
    $.toggle_dhe_id()