$.addNewUser = () ->
  new_user = $("#user-template").clone()
  index = $("div.user-fields").length
  new_user.attr("id", "user-fields-#{index}")
  new_user.adjust_rails_form_fields(index)
  $("div#users").append(new_user)
  new_user.show()

$.addAddress = () ->
  new_address = $("#address-template").clone()
  index = $("div.address-fields").length
  new_address.attr id: "address-fields-#{index}"
  new_address.adjust_rails_form_fields(index)
  $("div#addresses").append(new_address)
  new_address.show()

$.addPhoneNumber = () ->
  new_phone = $("#phone-number-template").clone()
  index = $("div.phone-number-fields").length
  new_phone.attr("id", "phone-number-fields-#{index}")
  new_phone.adjust_rails_form_fields(index)
  $("div#phone-numbers").append(new_phone)
  new_phone.show()

$.addIqaDonor = () ->
  index = $("div.iqa-donor-fields").length

  return null if index > $.maxNumberOfDonors()

  new_index = parseInt(index) + 1
  new_iqa_donor = $("#iqa-donor-template").clone()
  new_iqa_donor.attr("id", "iqa-donor-fields-#{new_index}")
  new_iqa_donor.adjust_rails_form_fields(new_index)

  name_field = new_iqa_donor.find("input[name='proficiency_test[iqa_donors_attributes][#{new_index}][name]']")
  name_field.val($.iqaDonorName(index))

  $("div#iqa-donors").append(new_iqa_donor)
  new_iqa_donor.show()
  $.hideAddIqaDonorButton() if new_index > $.maxNumberOfDonors()

$.iqaDonorName = (index) ->
  ['A', 'B', 'C', 'D', 'E', 'F'][index - 1]

# 6 is the max number of donors per trial
$.maxNumberOfDonors = () ->
  6
