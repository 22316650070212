$ ->
  window.PerformanceReportForm =
    programId: null
    report_type: 'site_report'
    kit_url: "/performance_reports/kit_types"
    ep_url: "/performance_reports/eps"
    performance_url: "/performance_reports/criteria"
    site_url: "/performance_reports/sites"

    bindEvents: ->
      @disableSubmitButton()
      @bindSelectProgram()
      @bindSelectKit()
      @bindSelectEps()
      @bindSelectPerformance()
      @bindSelectSites()
      @bindSiteDetailSwitch()
      @bindSubmitPerformanceReport()
      @highlightFieldsOnSelect()
      @disableFieldsWithDependencies()
      @backfillFormFromUrlParameters('program_id')

    disableSubmitButton: ->
      $('.report_submit input[type=submit]').attr('disabled', 'disabled').addClass('disabled')

    bindSelectProgram: ->
      $("#performance_report_program_id").select2(width: 'resolve')
      $("#performance_report_program_id").on 'change backfill', (event) =>
        programId = $(event.currentTarget).val()
        @setProgramId programId
        @fetchKitOptions programId
        @clearField($("#performance_report_ep_ids"), 'ep_ids')
        $('.report_submit input[type=submit]').removeAttr('disabled').removeClass('disabled')

    bindSelectKit: ->
      $kitTypeFields = $("#performance_report_kit_types")
      $kitTypeFields.select2(data: [], width: 'resolve', multiple: true)
      $kitTypeFields.on 'change backfill', (event) =>
        @clearField($("#performance_report_ep_ids"), 'ep_ids')
        @fetchEpOptions @programId, $(event.currentTarget).val().split(",")

    bindSelectEps: ->
      $epFields = $("#performance_report_ep_ids")
      $epFields.select2(data: [], width: 'resolve', multiple: true)
      $epFields.on 'change backfill', (event) =>
        @fetchPerformanceOptions @programId
        @fetchSiteOptions $(event.currentTarget).val()
        @backfillSwitchFromUrlParameters('show_site_details')

    bindSelectPerformance: ->
      $("#performance_report_criteria_names").select2(data: [], width: 'resolve')

    bindSelectSites: ->
      $("#performance_report_site_ids").select2(data: [], width: 'resolve', multiple: true)

    bindSiteDetailSwitch: ->
      $detailCheckbox = $('#performance_report_show_site_details')
      $('.switch .yes').on 'click', (event) =>
        @report_type = 'site_report'
        $detailCheckbox.prop('checked', true)
        $('.switch .no').removeClass('active')
        $(event.currentTarget).addClass('active')
        $("#performance_report_site_ids").select2("enable", true)
        $("#performance_report_site_ids").parents('dl').removeClass('js-disabled').addClass('active')
      $('.switch .no').on 'click', (event) =>
        @report_type = 'overall_report'
        $('.switch .yes').removeClass('active')
        $(event.currentTarget).addClass('active')
        $detailCheckbox.prop('checked', false)
        $("#performance_report_site_ids").select2("enable", false)
        $("#performance_report_site_ids").parents('dl').addClass('js-disabled')

    bindSubmitPerformanceReport: ->
      $('form[name=form-performance_report]').submit (e) => @updateFormAction($(e.currentTarget))

    highlightFieldsOnSelect: ->
      $('input[type=hidden], select').on 'select2-open', (event) ->
        $('dl.report_field').removeClass('active')
        $(event.currentTarget).parents('dl').addClass('active')
      $('input[type=hidden], select').on 'select2-blur', (event) ->
        $(event.currentTarget).parents('dl').removeClass('active')

    disableFieldsWithDependencies: ->
      # https://groups.google.com/forum/#!topic/select2/RjJcxghMXRM
      # when they have .select2-hidden-accessible it means select2 is initialized
      $("dl.js-disabled").find('input').select2("enable", false)

    backfillFormFromUrlParameters: (paramName) ->
      parameters = @urlParameters()
      paramVal = parameters["performance_report[#{paramName}]"]
      if paramVal
        if paramVal.indexOf("+") != -1
          paramVal = paramVal.replace(/\+/g, ' ')
        if paramName.indexOf("_ids") != -1 || paramName.indexOf("criteria_names") != -1 || paramName.indexOf("kit_types") != -1
          paramVal = paramVal.split(',')
        $("#performance_report_#{paramName}").select2('val', paramVal)
        $("#performance_report_#{paramName}").trigger('backfill')

    backfillSwitchFromUrlParameters: (paramName) ->
      parameters = @urlParameters()
      paramVal = parameters["performance_report[#{paramName}]"]
      if paramVal
        $("#site_details_switch a.yes").addClass('active')
        $("#site_details_switch a.no").removeClass('active')
        $("#performance_report_show_site_details").prop('checked', true)
      else
        $("#site_details_switch a.yes").removeClass('active')
        $("#site_details_switch a.no").addClass('active')
        $("#performance_report_show_site_details").prop('checked', false)

    setProgramId: (id) ->
      @programId = id

    fetchKitOptions: (id) ->
      $.getJSON "#{@kit_url}?program_id=#{id}", (kits) =>
        display = "block"
        if !kits.length
          display = "none"
          @fetchEpOptions(id)
        $("#performance_report_kit_types").parents('dl').css(display: display)
        kits = ({id: kit, text: kit} for kit in kits)
        $("#performance_report_kit_types").select2
          data: kits
          width: 'resolve'
          multiple: true
        $("#performance_report_kit_types").select2("enable", true)
        $('dl').removeClass('active')
        $("#performance_report_kit_types").parents('dl').removeClass('js-disabled').addClass('active')
        $("#performance_report_ep_ids").select2("enable", false)
        $("#performance_report_ep_ids").parents('dl').addClass('js-disabled')
        @backfillFormFromUrlParameters('kit_types')

    epName: (name, kitType) ->
      name = "EP#{name} "
      kitType = kitType || ""

      if kitType == "EQAPOL"
        return name
      else
        return name + kitType

    fetchEpOptions: (id, kitTypes=[]) ->
      kitParams = @parameterizeKitTypes(kitTypes)
      $.getJSON "#{@ep_url}?program_id=#{id}#{kitParams}", (eps) =>
        eps = ({id: ep.id, text: @epName(ep.name, ep.kitType)} for ep in eps)
        $("#performance_report_ep_ids").select2
          data: eps
          width: 'resolve'
          multiple: true
        $("#performance_report_ep_ids").select2("enable", true)
        $('dl').removeClass('active')
        $("#performance_report_ep_ids").parents('dl').removeClass('js-disabled').addClass('active')
        $("#performance_report_criteria_names").select2("enable", false)
        $("#performance_report_criteria_names").parents('dl').addClass('js-disabled')
        $("#performance_report_site_ids").select2("enable", false)
        $("#performance_report_site_ids").parents('dl').addClass('js-disabled')
        @backfillFormFromUrlParameters('ep_ids')

    parameterizeKitTypes: (kitTypes=[]) ->
      params = ''
      (params += "&kit_types[]=#{kit}" if kit.length) for kit in kitTypes
      params

    fetchPerformanceOptions: (id) ->
      $.getJSON "#{@performance_url}?program_id=#{id}", (criteria) =>
        allPerformanceCriteria = (criterion.name for criterion in criteria)
        allPerformanceCriteria.push('Overall')
        allPerformanceCriteria = allPerformanceCriteria.join()
        criteria = ({id: criterion.name, text: "#{criterion.name}"} for criterion in criteria)
        criteria.unshift({id: "Overall", text: "Overall"})
        criteria.unshift({id: allPerformanceCriteria, text: "All Performance Criteria"})
        $("#performance_report_criteria_names").select2
          data: criteria
          width: 'resolve'
        $("#performance_report_criteria_names").select2("enable", true)
        $("#performance_report_criteria_names").select2("val", allPerformanceCriteria)
        $('dl').removeClass('js-disabled').removeClass('active')
        if $('.switch .no').hasClass("active")
          $("#performance_report_site_ids").parents('dl').addClass('js-disabled')
        $("#performance_report_criteria_names").parents('dl').addClass('active')
        @backfillFormFromUrlParameters('criteria_names')
        @backfillFormFromUrlParameters('site_ids')

    fetchSiteOptions: (ep_id) ->
      $.getJSON "#{@site_url}?ep_id=#{ep_id}", (sites) =>
        sites = ({id: site.id, text: "#{site.site_number}"} for site in sites)
        $("#performance_report_site_ids").select2
          data: sites
          width: 'resolve'
          multiple: true
        if $('.switch .yes').hasClass("active")
          $("#performance_report_site_ids").select2("enable", true)
          @backfillFormFromUrlParameters('site_ids')

    updateFormAction: ($form) ->
      submitUrl = window.location.href.replace(/new/, "#{@programId}/#{@report_type}")
      formParams = $form.serialize()
      $form.attr('action', "#{submitUrl}?#{formParams}")

    urlParameters: ->
      search = location.search.substring(1)
      return {} unless !!search.length
      search = decodeURI(search).replace(/&/g, '","').replace(/\=/g,'":"')
      return JSON.parse "{\"#{search}\"}", (key, value) ->
        return value unless !!key.length
        return decodeURIComponent(value)

    clearField: ($field, backfill) ->
      $field.select2(data: [], width: 'resolve', multiple: true)
      $field.val('')
      @backfillFormFromUrlParameters(backfill)

  window.PerformanceReportForm.bindEvents() if $('.new_performance_report').length > 0
