$ ->
  window.PerformanceReportForm =
    programId: null
    kit_url: "/ep_reports/kit_types"
    ep_url: "/ep_reports/eps"

    bindEvents: ->
      @disableSubmitButton()
      @bindSelectProgram()
      @bindSelectKit()
      @bindSelectEps()
      @bindSubmitEpReport()
      @highlightFieldsOnSelect()
      @backfillFormFromUrlParameters('program_id')
      @backfillFormFromUrlParameters('ep_id')
      @backfillFormFromUrlParameters('kit_type')

    disableSubmitButton: ->
      $('.report_submit input[type=submit]').attr('disabled', 'disabled').addClass('disabled')

    bindSelectProgram: ->
      $("#ep_report_program_id").select2({ width: 'resolve' })
      $("#ep_report_program_id").on 'change backfill', (event) =>
        programId = $(event.currentTarget).val()
        @setProgramId programId
        @fetchKitOptions programId
        @clearField("#ep_report_ep_id", 'ep_id')
        @clearField("#ep_report_kit_type", 'kit_types')

    bindSelectKit: ->
      $kitTypeFields = $("#ep_report_kit_type")
      $kitTypeFields.select2(data: [], width: 'resolve')
      $kitTypeFields.select2("enable", false)
      $kitTypeFields.on 'change backfill', (event) =>
        @clearField("#performance_report_ep_ids", 'ep_ids')
        @fetchEpOptions @programId, $(event.currentTarget).val().split(",")

    bindSelectEps: ->
      $epFields = $("#ep_report_ep_id")
      $epFields.select2(data: [], width: 'resolve')
      $epFields.select2("enable", false)
      $epFields.on 'select2-close backfill', (event) =>
        $('.report_submit input[type=submit]').removeAttr('disabled').removeClass('disabled')

    bindSubmitEpReport: ->
      $('form[name=form-ep_report]').submit (e) => @updateFormAction($(e.currentTarget))

    highlightFieldsOnSelect: ->
      $('input[type=hidden], select').on 'select2-open', (event) ->
        $('dl.report_field').removeClass('active')
        $(event.currentTarget).parents('dl').addClass('active')
      $('input[type=hidden], select').on 'select2-blur', (event) ->
        $(event.currentTarget).parents('dl').removeClass('active')

    backfillFormFromUrlParameters: (paramName) ->
      parameters = @parameters()
      paramVal = parameters["ep_report[#{paramName}]"]
      if paramVal
        if paramVal.indexOf("+") != -1
          paramVal = paramVal.replace(/\+/, ' ')
        if paramName.indexOf("_ids") != -1 || paramName.indexOf("criteria_names") != -1 || paramName.indexOf("kit_type") != -1
          paramVal = paramVal.split(',')
        $("#ep_report_#{paramName}").select2('val', paramVal)
        $("#ep_report_#{paramName}").trigger('backfill')

    setProgramId: (id) ->
      @programId = id

    fetchKitOptions: (id) ->
      $.getJSON "#{@kit_url}?program_id=#{id}", (eps) =>
        display = "block"
        if !eps.length
          display = "none"
          @fetchEpOptions(id)
        $("#ep_report_kit_type").parents('dl').css(display: display)
        eps = ({id: ep, text: ep} for ep in eps)
        $("#ep_report_kit_type").select2
          data: eps
          width: 'resolve'
        $("#ep_report_kit_type").select2("enable", true)
        $('dl').removeClass('active')
        $("#ep_report_kit_type").parents('dl').removeClass('js-disabled').addClass('active')
        $("#ep_report_ep_id").select2("enable", false)
        $("#ep_report_ep_id").parents('dl').addClass('js-disabled') 
        @backfillFormFromUrlParameters('kit_type')

    epName: (name, kitType) ->
      name = "EP#{name} "
      kitType = kitType || ""
      
      if kitType == "EQAPOL"
        return name
      else
        return name + kitType

    fetchEpOptions: (id, kitTypes=[]) ->
      kitParams = @parameterizeKitTypes(kitTypes)
      $.getJSON "#{@ep_url}?program_id=#{id}#{kitParams}", (eps) =>
        eps = ({id: ep.id, text: @epName(ep.name, ep.kitType)} for ep in eps)
        $("#ep_report_ep_id").select2
          data: eps
          width: 'resolve'
        $("#ep_report_ep_id").select2("enable", true)
        $('dl').removeClass('active')
        $("#ep_report_ep_id").parents('dl').removeClass('js-disabled').addClass('active')
        @backfillFormFromUrlParameters('ep_id')

    parameterizeKitTypes: (kitTypes=[]) ->
      params = ''
      (params += "&kit_types[]=#{kit}" if kit.length) for kit in kitTypes
      params

    updateFormAction: ($form) ->
      submitUrl = window.location.href.replace(/new/, "#{@programId}")
      formParams = $form.serialize()
      $form.attr('action', "#{submitUrl}?#{formParams}")

    parameters: ->
      search = location.search.substring(1)
      return {} unless !!search.length
      search = decodeURI(search).replace(/&/g, '","').replace(/\=/g,'":"')
      return JSON.parse "{\"#{search}\"}", (key, value) ->
        return value unless !!key.length
        return decodeURIComponent(value)

    clearField: (field, backfill) ->
      $(field).select2(data: [], width: 'resolve')
      @backfillFormFromUrlParameters(backfill)

  window.PerformanceReportForm.bindEvents() if !!$('.new_ep_report').length
