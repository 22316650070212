$(document).ready ->
  $('#immunology').on 'change', '[data-row-type]select', (e) ->
    selectElement = $(e.target)
    selectedValue = selectElement.val()

    thisRowType = selectElement.data('row-type')

    form = selectElement.closest('form')
    allSelects = form.find("select[data-row-type='#{thisRowType}']")

    allBlankSelects = allSelects.filter ->
      filledSelects = $(@).not(selectElement).filter ->
        $(@).val() != ''
      filledSelects.length == 0

    otherSelects = allBlankSelects.not(selectElement)

    otherSelects.val(selectedValue)

  $('#immunology').on 'change', "[data-row-type]input[type='checkbox']", (e) ->
    checkboxElement = $(e.target)

    thisRowType = checkboxElement.data('row-type')

    form = checkboxElement.closest('form')
    allCheckboxes = form.find("input[type='checkbox'][data-row-type='#{thisRowType}']")

    allBlankCheckboxes = allCheckboxes.filter ->
      uncheckedBoxes = $(@).not(checkboxElement).filter ->
        $(@).prop('checked')
      uncheckedBoxes.length == 0

    otherCheckboxes = allBlankCheckboxes.not(checkboxElement)

    if checkboxElement.prop('checked')
      otherCheckboxes.prop('checked', true)
    else
      otherCheckboxes.prop('checked', false)