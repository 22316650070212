$(document).ready ->
  #

  # Add shelfs to the form.
  $('.add-shelf').on "click", (e) ->
    e.preventDefault()
    template = $(".shelf-entry:last").clone()
    template.find("input:text").val ""
    template.adjust_rails_form_fields($(".shelf-entry").length)
    $(".shelf-entry:last").after template
    # If there isn't a discard button on the first shelf, then add one.
    if $(".shelf-entry:first").children(".discard").children().length == 0
      $(".shelf-entry:first").children(".discard").append('<a class="delete-link remove-shelf">Remove</a>')
      $(".shelf-entry:last").children(".discard").append('<a class="delete-link remove-shelf">Remove</a>')

  # Remove shelfs from the form.
  $('.remove-shelf').on "click", (e) ->
    e.preventDefault()
    self = $(e.target)
    self.parent().parent().remove()
    # If there is only one shelf, then remove the ability to remove it. We
    # cannot allow all of the shelfs to be removed since we use them as a
    # template for new shelfs.
    if $(".shelf-entry").length == 1
      $(".shelf-entry:first").children(".discard").children().remove()

  $('#location_id').on "change", (e) ->
    selectedLocation= $(e.currentTarget)
    locationShelves = getShelf(selectedLocation.val())
    populateShelves(selectedLocation.parent().parent(), locationShelves, selectedLocation.val())

  getShelves = () ->
    JSON.parse($("#locations").val())

  getShelf = (locationId) ->
    getShelves()[locationId]

  populateShelves = (parent, shelves, selectedValue) ->
    parent.children().children(".selected_shelf").children().remove()
    for shelf in shelves
      parent.children().children(".selected_shelf").append("<option value='#{shelf[1]}'>#{shelf[0]}</option>")
