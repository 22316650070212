$ ->
  $(document).on 'click', '.remove-fields', ->
    if confirm('Are you sure you want to remove this entry?')
      $(this).prev('input[type=hidden]').val('1')
      $(this).closest('.fields-for').hide()
      event.preventDefault()

  $('.donor-subsection').on 'click', '.remove-and-submit', ->
    if confirm('Are you sure you want to remove this visit?')
      $(this).prev('input[type=hidden]').val('1')
      $(this).closest('form.edit_donor').submit()
      event.preventDefault()

  $('#complex-form').on 'click', '.add-fields', ->
    self = $(this)
    time = new Date().getTime()
    regexp = new RegExp(self.data('id'), "g")
    if self.hasClass("add-link-to-table")
      table = $.merge(self.prev('table'), self.parent().prev('table')).first()
      table.find('tr:last').after(jQuery.parseJSON(self.data('fields')).replace(regexp, time))
    else
      self.before(jQuery.parseJSON(self.data('fields')).replace(regexp, time))

    self.trigger('elementAdded')
    event.preventDefault()

  $('#donor-tabs').on 'click', '.submit-fields-link', ->
    $(this).closest('form').submit()
