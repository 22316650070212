$.toggle_inputs_starting_with = (input_base_name,focus_on_field) ->

  $("[name^='#{input_base_name}']").each (i,elt) ->
    elt.disabled = !elt.disabled
    if elt.disabled
      $(elt).addClass('disabled')
    else
      $(elt).removeClass('disabled')
    true

  $("[name^='#{input_base_name}'][type!='hidden'].hide-on-load").toggle()

  label_base_for = input_base_name.replace(/\]\[|[ \[ \] ]/g,'_')
  $("label[for^='#{label_base_for}'].hide-on-load").toggle()
  $("input[name^='#{input_base_name}[#{focus_on_field}]']").focus()

$(document).ready ->
  ###
  Enables the submit button when a field is selected
  ###
  $('form.edit_assembly input,textarea').keydown ->
    $(this).parents('form').find('input[type="submit"]:disabled').attr('disabled',false)

  ###
  Attaches a handler to the CKEDITOR to enable a form
  ###
  CKEDITOR.on 'instanceReady', (event) ->
    event.editor.on('key',-> $(this.element.$).parents('form').find('input[type="submit"]:disabled').attr('disabled',false) )

  ###
  Disables all of the hidden fields on the search table
  ###
  $('.change-quantity').data('nextStep','Cancel Edit')
  $('.assembly-table input:hidden').attr('disabled', true)
  $('.hide-on-load').addClass('hide')
  $('.hide-on-load,.disable-on-load').attr('disabled',true)
  $('.change-quantity').click (e) ->
    $('#assembly_components_submit').attr('disabled',false)
    $.toggle_inputs_starting_with($(this).data('base-field-name'),'part_count')
    hide_selector = '[id^="'+'current_'+$(this).data('base-field-name').replace(/\]\[|[ \[ \] ]/g,'_')+'"]'
    $(hide_selector).toggle()
    currentStep = $(this).text()
    $(this).text($(this).data('nextStep'))
    $(this).data('nextStep', currentStep)
    return true


  $('.assembly-table input:hidden').attr('disabled', true)
  $('.disable-on-load').attr('disabled',true)
  $('.disable-on-load').addClass('disabled')

  ###
  Registers callback functions to set the part count to 1 and enable form
  fields on the assembly search screens.
  ###
  $('.toggle-inputs').click ->
     $("input[name='"+$(this).data('base-field-name')+"[part_count]']").val('1')
     $.toggle_inputs_starting_with($(this).data('base-field-name'),'part_count')
     return true

  ###
  Triggers the 'click' callback function for all selected elements
  This alows users to use the back button
  ###
  $('.toggle-inputs:checked').each (i,elt) ->
    $(elt).triggerHandler('click')
    return true

  toggleOrderableOptions = ->
    if $("#assembly_externally_orderable").is(':checked')
      $(".orderable_options").show()
    else
      $(".orderable_options").hide()   

  toggleOrderableOptions()

  $("#assembly_externally_orderable").click ->
    toggleOrderableOptions()

  $('.empty-assembly-row').find('input:checkbox').click ->
    checkbox = this
    if checkbox.checked
      $("#dialog-confirm").dialog
        resizable: false
        height: 180
        width: 500
        modal: true
        buttons:
          "Ok": ->
            $( this ).dialog "close"
          Cancel: ->
            $(checkbox).attr(checked: false)
            $.toggle_inputs_starting_with($(checkbox).data('base-field-name'), 'part_count')
            $( this ).dialog "close"

