$ ->
  window.PerformanceReport =
    bindEvents: ->
      @createExportToCsvLinks()
      @createGoBackLinks()

    createExportToCsvLinks: ->
      for csvExportLink in $('.js-csv_export')
        $csvExportLink = $(csvExportLink)
        reportUrl      = $csvExportLink.attr('id')
        reportType     = $csvExportLink.attr('href').split('/')
        reportParams   = "&report_type=#{reportType[0]}&criteria=#{reportType[1] || ''}"
        csvExportUrl   = window.location.href.replace((new RegExp(reportUrl)), "#{reportUrl}.csv") + reportParams
        $csvExportLink.attr('href', csvExportUrl)

    createGoBackLinks: ->
      for goBackLink in $('.js-go_back')
        $goBackLink = $(goBackLink)
        goBackUrl = $goBackLink.attr('href') + window.location.search
        $goBackLink.attr('href', goBackUrl)

  window.PerformanceReport.bindEvents() if !!$('.js-csv_export, .js-go_back').length
