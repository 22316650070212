$ ->
  $('a.note-flag').on "click", (e) ->
    e.preventDefault()
    self = $(e.target)
    parent = self.parent()
    $.get self.attr("href"), {}, ->
      if(self.text() == "Flag")
        parent.closest(".note").addClass("flagged")
        parent.prev().after("<img src='/images/flag.png' class='flag' />")
        self.text("Remove Flag")
      else
        parent.closest(".note").removeClass("flagged")
        parent.prev().remove()
        self.text("Flag")

  $('.tipsy').tipsy(html: true)
