# enable other field
$.enable_other = (e) ->
  select = $(e.target)
  if(select.val() == "Other")
    select.closest(".new-row").find("div.other-field-container").show().find("input:text").attr "disabled", false
  else
    select.closest(".new-row").find("div.other-field-container").hide().find("input:text").attr "disabled", true

$.show_optional_antigen_fields = ->
  $("select.optional_antigen").each (i, select) ->
    self = $(select)
    if self.val()
      self.closest(".optional-antigen").find(".optional-antigen-results").find(".percent").text(self.val() + " %")
      self.closest(".optional-antigen").find(".optional-antigen-results").find(".abs").text(self.val() + " Abs")
      self.closest(".optional-antigen").find(".optional-antigen-results").show()
    else
      self.closest(".optional-antigen").find(".optional-antigen-results").hide()

$.hide_iqa_validation_messages = ->
  $(".field-with-validation.invalid").each ->
    $(this).tipsy('hide')

$.iqa_form_tab_selected = (e, ui) ->
  $.hide_iqa_validation_messages()
  $("#current_tab").val(ui.index)

$.validate_unreported_phenotype = (field) ->
  valid = false
  sample_select = field.find(".unreported-phenotype-sample").first()
  parameter_select = field.find(".unreported-phenotype-parameter").first()
  explanation_select = field.find(".unreported-phenotype-explanation").first()
  other_explanation_field = field.find(".other_explanation").first()

  if(((sample_select.val() == "") && (parameter_select.val() == "") && (explanation_select.val() == "")) ||
    ((sample_select.val() != "") && (parameter_select.val() != "") && (explanation_select.val() != "")))
      if((explanation_select.val() != "Other") || (other_explanation_field.val() != ""))
        valid = true

  if(valid)
    field.removeClass('invalid')
    field.attr('original-title', "")
    field.tipsy('hide')
  else
    field.addClass('invalid')
    field.data('errors', "Please choose a sample, parameter and explanation.")
    field.attr('original-title', "Please choose a sample, parameter and explanation for each unreported phenotype.")
    $.hide_iqa_validation_messages()
    field.tipsy('show')

  return valid

# This function handles everything needed to persist clicked subtabs between reloads.
# see https://codepen.io/angelorubin/pen/qZzjdX for window.localStorage example
#
# @param subtabs [JQuery Object] a jquery object containing all the relevant subtabs
# @param tabsEnclosingDiv [JQuery Object] the div enclosing the entire tab menu
# @param subtabStorageKey [String] the key used to store and retrieve the last clicked tab's href
# @param tabIndexFromHref [Func(String)] a function that accepts the href of the tabs as a string
#   and returns the tab index of that href
#
$.persistSubtab = (subtabs, tabsEnclosingDiv, subtabStorageKey, tabIndexFromHref) ->
  subtabs.each (_index, tabLink) ->
    $(tabLink).on 'click', (e) ->
      $.setPersistedSubtab(subtabStorageKey, $(e.target).attr('href'))
  $.activatePersistedSubtab(tabsEnclosingDiv, subtabStorageKey, tabIndexFromHref)

$.activatePersistedSubtab = (tabsEnclosingDiv, subtabStorageKey, tabIndexFromHref) ->
  subtabToActivate = $.getPersistedSubtab(subtabStorageKey)
  if subtabToActivate
    tabsEnclosingDiv.tabs({
      active: tabIndexFromHref(subtabToActivate)
    })

$.setPersistedSubtab = (subtabStorageKey, tabIndex) ->
  window.localStorage.setItem(subtabStorageKey, tabIndex)

$.getPersistedSubtab = (subtabStorageKey) ->
  window.localStorage.getItem(subtabStorageKey)

iqa_web_form =
  propagate_row_selects: ->
    # if the user changes a select field, and all the select fields in that row still contain the default value (""),
    # then propagate the newly selected value to all the other select fields in the row
    $("#immunology select, #optional-antigens select").change ->
      thisSelectsCssClass = $(@).attr('class')
      otherSelectsInRow = $(@).closest(".group").find("select." + thisSelectsCssClass)

      # hack to only get the selected row when dealing w/ sections that have 2 dye rows listed
      if otherSelectsInRow.length == 10
        isTopRow = otherSelectsInRow.index($(@)) % 2 == 0
        if isTopRow
          otherSelectsInRow = otherSelectsInRow.filter(":even")
        else
          otherSelectsInRow = otherSelectsInRow.filter(":odd")

      otherSelectsInRow = otherSelectsInRow.not(@)
      selectsInRowWithDefaultValue = otherSelectsInRow.filter ->
        $(@).val() == ''
      allSelectsInRowHaveDefaultValue = !otherSelectsInRow.not(selectsInRowWithDefaultValue).length

      if allSelectsInRowHaveDefaultValue
        thisSelectsValue = $(@).val()
        otherSelectsInRow.val(thisSelectsValue)


  checkForChangesPresentVisibility: (changeIcon) ->
    correspondingField = changeIcon.prev()

    if correspondingField.is(":visible")
      changeIcon.show()
    else
      changeIcon.hide()


# this will determine if the explanation field needs to be shown.
# only show if "Other" has been selected from the el dropdown.
checkIfOtherExplanationTextfieldNeedsToBeShown = ($explanationSelectDropdown) ->
  $otherExplantionField = $explanationSelectDropdown.closest(".unreported-item").find("[id$=other_explanation]")
  if $explanationSelectDropdown.val() == "Other" then $otherExplantionField.show() else $otherExplantionField.hide()

  iqa_web_form.checkForChangesPresentVisibility($otherExplantionField.next()) if $otherExplantionField.next().hasClass("changes-present")
  iqa_web_form.checkForChangesPresentVisibility($explanationSelectDropdown.next()) if $explanationSelectDropdown.next().hasClass("changes-present")

  iqa_web_form.propagate_row_selects()

###### ON-LOAD ######
$ ->
  $('.jquery-hide').hide()

  $("[data-other-explanation]").each ->
    checkIfOtherExplanationTextfieldNeedsToBeShown($(@))

  $("[data-iqa-web-forms]").on "change", "select[data-other-explanation]", (event)->
    checkIfOtherExplanationTextfieldNeedsToBeShown($(event.target))

  $.add_datepickers()

  $("#cryo-sample, #cryo-vial, #ir-form, #iqa-review-form :input").change ->
    $("#cryo-sample, #cryo-vial, #ir-form, #iqa-review-form").data("changed",true);

  $(window).on 'beforeunload', () ->
    if !($(document.activeElement).val() == 'Submit' || $(document.activeElement).val() == 'Save' || $(document.activeElement).attr('id') == 'add-review-comment')
      if ($("#cryo-sample, #cryo-vial, #ir-form, #iqa-review-form").data("changed"))
        return "You have unsaved changes."

  # set up selects with other option on the iqa form
  $("#iqa-result select").each (i, select) ->
    if($(select).closest(".new-row").find("div.other-field-container").length > 0)
      other_field = $(select).closest(".new-row").find("div.other-field-container").find("input:text")
      unless $(select).val() == "Other"
        other_field.attr "disabled", true
        other_field.closest(".other-field-container").hide()
      $(select).change $.enable_other
      $(select).addClass("has-other-option")
      other_field.addClass("other-field")

      $.enable_other({target: select}) if $(select).val() == "Other"

  # only show the antigen fields when something is selected
  $.show_optional_antigen_fields()
  $("select.optional_antigen").on "change", $.show_optional_antigen_fields

  # this is for removing options from the program select option form
  $(".option-destroy").click (e) ->
    e.preventDefault()
    if(confirm("Are you sure you want to remove this field option? This cannot be undone."))
      $.ajax $(e.target).attr("href"), success: =>
        $(e.target).parent(".option-fields").html("")

  # add option to program select option form
  $(".add-option").click (e) ->
    e.preventDefault()
    new_option = $(".option-fields:last").clone()
    new_option.adjust_rails_form_fields($(".option-fields").length)
    $(".option-fields:last").after(new_option)
    new_option.find("input:text").val("")

  # Non-cryo IQA results tab
  $("#iqa-result").submit (e) ->
    form = $(this)
    return false if form.data('submitting')
    form.data('submitting', true)
    errors_fields = []

    $(".field-with-validation").each ->
      $.before_validation($(@))
      if (! $.validate($(this)))
        errors_fields.push($(this))

    $(".unreported-phenotyping-record").each ->
      if (! $.validate_unreported_phenotype($(this)))
        errors_fields.push($(this))

    if errors_fields.length > 0
      try
        form.data('submitting', false)
        error_objects = errors_fields.map( (x) -> x.attr('original-title') )
        error_str = error_objects.join("\n")
        alert('The results contain errors. Please review entries before saving.')
        tab_id = errors_fields[0].closest(".ui-tabs-panel").attr('id')
        tab_index = $('.ui-tabs-panel').index($('#' + tab_id))
        $("#iqa-web-form-tabs").tabs('option', 'active', tab_index)

        errors_fields[0].focus()

      return false
    else
      return true

  $("#view-edits").click (e) ->
    e.preventDefault()
    $("#phenotype-edits").show()
    $("#phenotyping_record_container").hide()

  $("#hide-edits").click (e) ->
    e.preventDefault()
    $("#phenotype-edits").hide()
    $("#phenotyping_record_container").show()

  $(".premix").each ->
    html = $(@).data('imageHtml')
    $(@).append(html)

  # hide the changes_present icon if the field it represents is also hidden.
  # conversely, show them when the field is shown.
  $(".changes-present").each ->
    iqa_web_form.checkForChangesPresentVisibility($(@))

  $(".checkbox-propagation").click ->
    itemsInRow = $(@).parent().parent().next(".group").find(":checkbox")

    if $(@).hasClass('check') then itemsInRow.prop('checked', true) else itemsInRow.prop('checked', false)

  $.extend($.validations, {
    white_cell_count: $.standard_float
    lymphocyte_percent: $.standard_float
    cd45_bright_cd14: $.bright
    cd3_percent: $.extended_float
    cd3_abs: $.standard_integer
    cd3_cd4_percent: $.standard_float_extra
    cd3_cd4_abs: $.standard_integer
    cd3_cd8_percent: $.standard_float_extra
    cd3_cd8_abs: $.standard_integer
    tot_cd19_percent: $.standard_float
    tot_cd19_abs: $.standard_integer
    cd3_cd19_percent: $.standard_float_extra
    cd3_cd19_abs: $.standard_integer
    cd3_cd1656_percent: $.standard_float_extra
    cd3_cd1656_abs: $.standard_integer
    opt_antigen_percent: $.standard_float
    opt_antigen_abs: $.standard_integer
    standard_float: $.standard_float
    required_float: $.required_float
    required_string: $.required_string
    required_email: $.required_email
    required_integer: $.required_integer
    extended_float: $.extended_float
    required_extended_float: $.required_extended_float
    required_float_no_max: $.required_float_no_max
    required_extended_float_no_max: $.required_extended_float_no_max
  })

###### END ON-LOAD ######
