$ ->
  window.A3R5 =
    disableResultField: (resultField) ->
      resultField.prop('readonly', 'readonly')
      resultField.val('')
      resultField.tipsy('hide')
      resultField.removeClass('invalid')

    enableResultField: (resultField) ->
      if resultField.prop('readonly')
        resultField.prop('readonly', false)
        resultField.siblings('input[value=numeric]').prop('checked', 'checked')

    validate: ->
      errors_fields= @errors_fields()
      if errors_fields.length > 0
        _.each errors_fields, (field) -> $(field).tipsy('show')
        alert('The results contain errors. Please review entries before saving.')
        errors_fields[0].focus()
        $('.reloading').removeClass('reloading')

      errors_fields.length == 0

    errors_fields: ->
      $(".field-with-validation").filter ->
        $.before_validation($(@))
        not $.validate($(@))

  $('.a3r5').on 'change', '.result input[type=text]', (e) ->
    numericResultField = $(e.target)
    numericOption = numericResultField.siblings('input[value=numeric]')
    if numericResultField.val()
      numericOption.prop('checked', 'checked')
    else
      numericOption.prop('checked', false)

  $('.a3r5').on 'change', '.result input[type=radio]', (e) ->
    resultField = $(e.target).siblings('input[type=text]')
    A3R5.disableResultField(resultField)

  $('.a3r5').on 'click', '.result input[type=text]', (e) ->
    resultField = $(e.target)
    A3R5.enableResultField(resultField)

  $('.a3r5').on 'change keyup keydown', 'input, textarea, select', (e) ->
    $(this).addClass('changed-input')

  #Only remove changed-input classes if the user is saving the form
  #Keep changed-input classes if user is submitting the form in case
  #there are validation errors which prevent the user
  #from submitting.
  $('#a3_r5_submission_form_save').on 'click', (e) ->
    $('.changed-input').removeClass('changed-input')
    $('.a3r5').addClass('reloading')

  $(window).on 'beforeunload', (e) ->
    if $('.changed-input').length && !$('.reloading').length
      return "You have unsaved changes."

  $.extend($.validations, {
    a3_r5_result: {
      format: 'integer',
      minimum: 20,
      maximum: 43740,
      require_value_in_group_on_submit: true,
      allow_blank: true
    }
  })

  $('#a3_r5_submission_form_submit').click (e) ->
    if confirm('Are you sure you want to submit your results? Once you submit your results you will no longer be able to make any changes.')
      $('.a3r5').addClass('reloading')
      A3R5.validate()
    else
      false

  window.A3R5Components =
    disableComponentOption: (componentSelects, id) ->
      componentSelects.find("option[value=#{id}]").prop('disabled', 'disabled')

    enableComponentOption: (componentSelects, id) ->
      componentSelects.find("option[value=#{id}]").prop('disabled', false)

    componentSelectsExcept: (componentSelect) ->
      $("[data-action=removeFromOptions]").not(componentSelect)

    updateComponentSelectOptions: (componentSelect) ->
      componentSelects = @componentSelectsExcept(componentSelect)
      if componentSelect.data('previous-value')
        @enableComponentOption(componentSelects, componentSelect.data('previous-value'))
      @disableComponentOption(componentSelects, componentSelect.val())
      componentSelect.data('previous-value', componentSelect.val())

  $a3r5_components = $('.a3r5_components')

  # Disable selected option and enable previously selected option on other selects
  $a3r5_components.on 'change', '[data-action=removeFromOptions]', (e) ->
    componentSelect = $(e.target)
    A3R5Components.updateComponentSelectOptions(componentSelect)

  # Disable selected options on load
  $('.a3r5_components [data-action=removeFromOptions]').each (index, componentSelect) ->
    componentSelects = A3R5Components.componentSelectsExcept(componentSelect)
    A3R5Components.disableComponentOption(componentSelects, $(componentSelect).val())

  $a3r5_components.on 'nested:fieldAdded', (event) ->
    # Disable selected options
    componentSelect = $(event.field).find('select[data-action=removeFromOptions]')
    $('.a3r5_components [data-action=removeFromOptions] option[disabled]').each (index, disabledOption) ->
      componentSelect.find("option[value=#{$(disabledOption).val()}]").prop('disabled', 'disabled')
    # Recalculate display-order
    recalculateAllSortableLists()

  $a3r5_components.on 'nested:fieldRemoved', (event) ->
    # Enable previously selected option
    componentSelect = $(event.field).find('select[data-action=removeFromOptions]')
    componentSelects = A3R5Components.componentSelectsExcept(componentSelect)
    A3R5Components.enableComponentOption(componentSelects, componentSelect.data('previous-value'))
    # Remove display-order hidden field and recalculate display-order
    $(event.target).find('.display-order').remove()
    recalculateAllSortableLists()

  $( ".jquery-sortable" ).sortable
    stop: (event, ui) ->
      recalculateDisplayOrder ui.item.parent()

  recalculateDisplayOrder = (parent) ->
    parent.find('.display-order').each (index, element) ->
      $(element).val(index)

  recalculateAllSortableLists = ->
    $('.jquery-sortable').each ->
      recalculateDisplayOrder($(this))
