$ ->
  window.PlateTools =
    importWellResultsUrl: "/plates_for_assay_wells/import_well_data"
    removePlateUrlPrefix: "/plates_for_assays/"
    duplicatePlateUrlPattern: "/plates_for_assays/plate_id/duplicate"

    initialize: () ->
      @bindPlateToolsMenu()
      @showPlate()

    bindPlateToolsMenu: () ->
      $('[data-form-for-assay]').on 'change', '[data-plate-tools-menu]', (event) =>
        $plateToolsMenu = $(event.target)
        $selectedAction = $plateToolsMenu.find('option:selected')

        actionName = $selectedAction.data("plate-tools-action")

        $plateContainer = $plateToolsMenu.closest('[data-assay-plate-container]')
        $plateContainer.find('[data-plate-results-file]').hide()
        @clearPlateToolsMessage($plateContainer)

        @disablePlateToolsMenu($plateToolsMenu)
        @_handlePlateToolsAction(actionName, $plateContainer)
        @_resetPlateToolsMenu($plateToolsMenu)

    showPlate: () ->
      if window.location.search.replace('?', '').split('=').indexOf('plates') > -1
        $('#assay_tabs').tabs('select', 1)

    # Helpers
    _plateContainer: (subcontainer) ->
      $(subcontainer).closest('[data-assay-plate-container]')

    _handlePlateToolsAction: (actionName, plateContainer) ->
      $plateContainer = $(plateContainer)
      switch actionName
        when "import-results-file" then @_handleImportResultsFile($plateContainer)
        when "remove-results" then @_handleRemoveResults($plateContainer)
        when "remove-plate" then @_handleRemovePlate($plateContainer)
        when "duplicate-plate" then @_handlePlateDuplication($plateContainer)

    enablePlateToolsMenu: (plateToolsMenu) ->
      $(plateToolsMenu).prop('disabled', false)

    enablePlateToolsMenuInContainer: (plateToolsMenuContainer) ->
      $plateToolsMenu = $(plateToolsMenuContainer).find('[data-plate-tools-menu]')
      @enablePlateToolsMenu($plateToolsMenu)

    disablePlateToolsMenu: (plateToolsMenu) ->
      $(plateToolsMenu).prop('disabled', true)

    disablePlateToolsMenuInContainer: (plateToolsMenuContainer) ->
      $plateToolsMenu = $(plateToolsMenuContainer).find('[data-plate-tools-menu]')
      @disablePlateToolsMenu($plateToolsMenu)

    _resetPlateToolsMenu: (plateToolsMenu) ->
      $(plateToolsMenu).find('[data-plate-tools-label-option]').prop('selected', 'selected')

    # Plate tools messages
    clearPlateToolsMessage: (container) ->
      @_setPlateToolsMessage(container, '')

    _setPlateToolsMessage: (container, message) ->
      @_plateToolsMessageContainer(container).html(message)

    _setPlateResultsFileMessage: (container, message) ->
      @_plateResultsFileContainer(container).html(message)

    _plateToolsMessageContainer: (supercontainer) ->
      $(supercontainer).find('[data-plate-tools-messages]')

    _plateResultsFileContainer: (supercontainer) ->
      $(supercontainer).find('[data-plate-results-file-message]')

    setNewPlatePlateToolsDisabledMessage: (plateContainer) ->
      @_setPlateToolsMessage(plateContainer, 'Plate tools is temporarily disabled while the plate is being created.')

    _setRemovingPlateMessage: (plateContainer) ->
      @_setPlateToolsMessage(plateContainer, 'Removing plate...')

    _setSuccessfulImportResultsMessage: (plateContainer) ->
      @_setPlateToolsMessage(plateContainer, 'Results have been successfully imported but will not be saved until the `Update` button is clicked.')

    _setDuplicatingPlateMessage: (plateContainer) ->
      @_setPlateToolsMessage(plateContainer, 'Duplicating plate...')

    _setSuccessfulPlateDuplicationMessage: (plateContainer) ->
      @_setPlateToolsMessage(plateContainer, 'Plate duplicated successfully.')

    _setSuccessfulRemoveResultsMessage: (plateContainer) ->
      @_setPlateToolsMessage(plateContainer, 'Results have been successfully removed but will not be saved until the `Update` button is clicked.')

    # Duplicate plate
    _handlePlateDuplication: (plateContainer) ->
      if @_warnUnsavedWellChanges(plateContainer)
        @_setDuplicatingPlateMessage(plateContainer)

        $clonePlateContainer = PlateTools._duplicatePlateInUi(plateContainer)
        $plate = $clonePlateContainer.find('.assay-plate-wells')

        @disablePlateToolsMenuInContainer($clonePlateContainer)
        PlateTools.setNewPlatePlateToolsDisabledMessage($clonePlateContainer)
        Modals.showPlateInfoModal $plate, 'duplicate'
        $('#plate-info-modal').data 'originalPlate', plateContainer
      else
        @enablePlateToolsMenuInContainer(plateContainer)

    duplicatePlate: (e) ->
      e.preventDefault()

      $form = $(e.currentTarget).closest('.assay-plate-info-fields')
      [lotNumber, uniqueId] = Assays.getPlateInfoFormData $form

      $modal = $('#plate-info-modal')
      $plate = $modal.data 'plate'
      $plateContainer = $plate.closest('.assays-plate')
      $originalPlate = $modal.data('originalPlate')

      Modals.hidePlateInfoModal()
      Assays.resetPlateInfoForm $form

      duplicatePlateUrl = PlateTools._duplicatePlateUrlForPlate($originalPlate)
      dataToSend =
        lot_number: lotNumber
        unique_id: uniqueId
      $.ajax(
        url: duplicatePlateUrl
        type: "POST"
        data: dataToSend
        dataType: 'json'
      ).done((data, textStatus, jqXHR) ->
        if data.error_messages.length  > 0
          $plateContainer.remove()
          alert("Error: Unable to duplicate plate. #{data.error_messages}")
        else
          Assays.setPlateInfo $plateContainer, lotNumber, uniqueId
          PlateTools._updateDuplicatedFieldsWithDatabaseIds($plateContainer, data)
          PlateTools._updateAssayInventoryItemsForWells(data)
          PlateTools._updateNameAndIdAttributesForDuplicatedFields($plateContainer, data)

          Assays.refreshWellContentDisplay($plateContainer)
          Assays.resetPlateInfoForm $form
          PlateTools._setSuccessfulPlateDuplicationMessage($plateContainer)
          PlateTools.enablePlateToolsMenuInContainer($plateContainer)

        $originalPlate = $('#plate-info-modal').data('originalPlate')
        PlateTools.enablePlateToolsMenuInContainer($originalPlate)
        PlateTools.clearPlateToolsMessage($originalPlate)
      )

    _warnUnsavedWellChanges: (plateContainer) ->
      unsavedWellItems = $(plateContainer).find('[data-unsaved-well-inventory-item]')
      if unsavedWellItems.length > 0
        confirm("This plate has unsaved changes (e.g. inventory items added to wells) that will not be duplicated. Are you sure you want to duplicate this plate?")
      else
        true

    _updateAssayInventoryItemsForWells: (data) ->
      plateId = data.plate.id
      for index, well of data.wells
        wellId = well.id
        wellContainer = $("[data-well-id=#{wellId}]")
        for index2, item of well.assay_inventory_items
          itemId = item.id
          name = item.name
          keyFieldNameId = item.key_field_name_id
          wellItemTemplate = "<div class=\"assay-well-item\" data-id=\"#{itemId}\" data-keyfield=\"#{keyFieldNameId}\">
            <input name=\"well_#{plateId}_#{wellId}_items[]\" value=\"#{itemId}\" type=\"hidden\" class=\"assay-well-inventory-item-field\"> #{name}
            </div>"
          wellContainer.append $(wellItemTemplate)

    _updateNameAndIdAttributesForDuplicatedFields: ($plateContainer, jsonData) ->
      plateHtml = $plateContainer.html()
      plate_id = jsonData.plate.id

      lotNumber = $plateContainer.find('.assays-plate-lot-number-field').val()
      uniqueId = $plateContainer.find('.assays-plate-unique-id-field').val()

      plateHtml = plateHtml.replace(/assay_plates_for_assays_attributes_\d+_/g, "assay_plates_for_assays_attributes_#{plate_id}_")
      plateHtml = plateHtml.replace(/assay\[plates_for_assays_attributes\]\[\d+\]/g, "assay\[plates_for_assays_attributes\]\[#{plate_id}\]")
      $plateContainer.html(plateHtml)

      $plateContainer.find('.assays-plate-lot-number-field').val lotNumber
      $plateContainer.find('.assays-plate-unique-id-field').val uniqueId

    _updateDuplicatedFieldsWithDatabaseIds: (plateContainer, jsonData) ->
      @_updateDuplicatedFieldsWithPlateId(plateContainer, jsonData.plate)
      @_updateDuplicatedFieldsWithWellIds(plateContainer, jsonData.wells)

    _updateDuplicatedFieldsWithPlateId: ($plateContainer, jsonPlateData) ->
      plateId = jsonPlateData.id
      $plateContainer.data 'plate-id', plateId
      $plateContainer.attr 'data-plate-id', plateId
      $plateContainer.find('[data-plate]').attr('data-plate', plateId)
      $plateContainer.find('[data-plate-id]').val(plateId)

    _updateDuplicatedFieldsWithWellIds: ($plateContainer, jsonWellsData) ->
      for index, well of jsonWellsData
        $well = $plateContainer.find "td[data-row='#{well.well_row}'][data-column='#{well.well_column}']"
        $well.data 'well-id', well.id
        $well.attr 'data-well-id', well.id
        $well.find("[data-well-id-field]").val(well.id)

    _duplicatePlateInUi: (plateContainer) ->
      $originalPlateContainer = $(plateContainer)
      clonePlateContainer = @_clonePlate($originalPlateContainer)
      @_addNewPlateButton().before(clonePlateContainer)

      clonePlateContainer

    _clonePlate: (originalPlateContainer) ->
      clonePlateContainer = $(originalPlateContainer).clone()
      @_clearPlateFields(clonePlateContainer)
      @_clearPlateWellFields(clonePlateContainer)
      @_clearDisplayedWellResults(clonePlateContainer)
      @_clearPlateResultsFileMessage(clonePlateContainer)
      @_removeAssayInventoryItemFields(clonePlateContainer)

      clonePlateContainer

    _clearPlateFields: (plateContainer) ->
      @_clearFieldsFor(plateContainer, '[data-plate-field="lot_number"]')
      @_clearFieldsFor(plateContainer, '[data-plate-field="unique_id"]')

    _clearPlateWellFields: (plateContainer) ->
      @_clearResultsFields(plateContainer)
      @_clearWellIdFields(plateContainer)

    _clearResultsFields: (plateContainer) ->
      @_clearFieldsFor(plateContainer, '[data-plate-well-field="spot_forming_cells"]')

    _clearWellIdFields: (plateContainer) ->
      @_clearFieldsFor(plateContainer, '[data-well-id-field]')

    _clearFieldsFor: (container, fieldSelector) ->
      $fieldsToClear = $(container).find(fieldSelector)
      $fieldsToClear.each (_index, field) ->
        $(field).val("")
        $(field).attr("value", "")

    _clearDisplayedWellResults: (plateContainer) ->
      $(plateContainer).find('.assay-well-result-display').html("Results:")

    _clearPlateResultsFileMessage: (plateContainer) ->
      @_plateResultsFileContainer(plateContainer).html('')

    _removeAssayInventoryItemFields: (plateContainer) ->
      $(plateContainer).find('.assay-well-item').remove()

    _addNewPlateButton: () ->
      $('[data-add-new-plate-button]')

    _duplicatePlateUrlForPlate: (plateContainer) ->
      $plateContainer = $(plateContainer)
      plateId = $plateContainer.find('[data-plate-id]').val()
      @duplicatePlateUrlPattern.replace(/plate_id/, plateId)

    # Remove plate
    _handleRemovePlate: (plateContainer) ->
      if confirm("Are you sure you want to remove this plate?")
        @_setRemovingPlateMessage(plateContainer)
        removePlateUrl = @_removePlateUrlForPlate(plateContainer)
        $.ajax(
          url: removePlateUrl,
          type: "DELETE"
        ).done((data, textStatus, jqXHR) =>
          $(plateContainer).closest('[data-assay-plate-container]').remove()
        )
      else
        @enablePlateToolsMenuInContainer(plateContainer)

    _removePlateUrlForPlate: (plateContainer) ->
      $plateContainer = $(plateContainer)
      plateId = $plateContainer.find('[data-plate-id]').val()
      "#{@removePlateUrlPrefix}#{plateId}"

    # Import results
    _handleImportResultsFile: (plateContainer) ->
      $fileField = $(plateContainer).find('[data-plate-results-file]')
      $fileField.on 'change', (event) =>
        @_setPlateToolsMessage(plateContainer, 'Importing results...')
        $fileField.hide()
        @_uploadSelectedImportResultsFile(plateContainer)
      $fileField.show()
      @enablePlateToolsMenuInContainer(plateContainer)

    _uploadSelectedImportResultsFile: (plateContainer) ->
      fileData = new FormData()
      $fileField = $(plateContainer).find('[data-plate-results-file]')[0]
      file = $fileField.files[0]

      if file
        @disablePlateToolsMenuInContainer(plateContainer)
        @_setPlateResultsFileMessage(plateContainer, 'New file: ' + file.name)

        fileData.append("plate_results", file)
        $.ajax(
          url: "#{@importWellResultsUrl}",
          type: 'POST',
          data: fileData,
          dataType: "json",
          processData: false,
          contentType: false
        ).done((data, textStatus, jqXHR) =>
          if data.data_import_successful
            @_handleImportResultsJsonResponse(data, plateContainer)
          else
            @_setPlateToolsMessage(plateContainer, 'Error: Unable to import results. The file was unable to be opened as a .xls file.')
          @enablePlateToolsMenuInContainer(plateContainer)
        )

    _handleImportResultsJsonResponse: (data, plateContainer) ->
      @_updateSpotFormingCellFields(data, plateContainer)
      @_setSuccessfulImportResultsMessage(plateContainer)

    _updateSpotFormingCellFields: (data, plateContainer) ->
      $plateContainer = $(plateContainer)
      spot_forming_cells_data = JSON.parse(data.spot_forming_cells)
      for row_number in [1..spot_forming_cells_data.length]
        row = spot_forming_cells_data[row_number-1]
        for column_number in [1..row.length]
          $well = $plateContainer.find("[data-well][data-row='#{row_number}'][data-column='#{column_number}']")
          $spotFormingCellsField = $well.find("[data-spot-forming-cells-field]")
          $spotFormingCellsField.val(row[column_number-1])
          $well.find(".assay-well-result-display").html "Results: #{row[column_number-1]}"

    # Remove results
    _handleRemoveResults: (plateContainer) ->
      if @_noResultsToRemove(plateContainer)
        alert("Results have not been imported for this plate yet. There are no results to remove.")
      else if confirm("Are you sure you want to remove the well results from this plate?")
        @_clearDisplayedWellResults(plateContainer)
        @_clearResultsFields(plateContainer)
        @_setSuccessfulRemoveResultsMessage(plateContainer)
      @enablePlateToolsMenuInContainer(plateContainer)

    _noResultsToRemove: (plateContainer) ->
      $(plateContainer).find('[data-spot-forming-cells-field]').val().length == 0

  window.PlateTools.initialize() if $("[data-assay-plates-list]").length > 0
