/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_include_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import jquery from 'jquery';
window.jQuery = jquery;
window.$ = jquery;

$(function() {
  $.fn.size = function() {
      return this.length;
  }
});

import Rails from "@rails/ujs";
Rails.start();

import '../../../vendor/assets/javascripts/autocomplete-rails.js'
import '../../../vendor/assets/javascripts/jquery.dropdown.js';
import '../../../vendor/assets/javascripts/jquery.limitmaxlength.js';
import '../../../vendor/assets/javascripts/jquery.table.drag.select/jquery.table.drag.select.js';
import '../../../vendor/assets/javascripts/jrowclick.js';
import '../../../vendor/assets/javascripts/jquery.tipsy.js';
import handlebars from '../../../vendor/assets/javascripts/handlebars';
window.Handlebars = handlebars;

require('../../assets/javascripts/application.coffee');
