$ ->
  # This is checking to see if 'IQA Linearity' is one of the programs
  # associated with the site. If it is then related checkboxes are shown.
  toggleLinearityPlatformPermissions = (selected_program_names) ->
    if /IQA Linearity/.test(selected_program_names)
      $("#linearity-platform-permissions").show()
    else
      $("#linearity-platform-permissions").hide()

  selected_program_names = $('#site_program_ids :selected').text()
  toggleLinearityPlatformPermissions(selected_program_names)
  $('#site_program_ids').change ->
    toggleLinearityPlatformPermissions($('#site_program_ids :selected').text())
