$ ->
  $('#order_trigger_events').change (e) ->
    value = $(e.target).children('option:selected').text()
    if value == "Approved"
      $('#approver-fields').show()
    else
      $('#approver-fields').hide()
      $('#order_for_research, #order_from_humans').val('')
      $('#order_irb_number').attr('readonly', true)
      $('#order_irb_number').addClass('disabled')
      $('#order_irb_number').val('')

  order_status = $('#order_trigger_events option:selected').text()
  if order_status == "Approved"
    $('#approver-fields').show()

  $('#order_for_research, #order_from_humans').change (e) ->
    if $('#order_for_research').val() == "true" && $('#order_from_humans').val() == "true"
      $('#order_irb_number').attr('readonly', false)
      $('#order_irb_number').attr('disabled', false)
      $('#order_irb_number').removeClass('disabled')
    else
      $('#order_irb_number').attr('readonly', true)
      $('#order_irb_number').addClass('disabled')
      $('#order_irb_number').val('')

$.disable_form = ->
  _gon = if (gon?) then gon else {}
  unless _gon.exclude_forms? and _gon.exclude_forms instanceof Array
    _gon.exclude_forms = []
  $("form").each ->
    unless $(@).attr('id') in _gon.exclude_forms
      $("input:submit:not(.questionnaire-invalidate-button)", @).remove()
      $("select", @).each (i, select) ->
        self = $(select)
        value = self.find("option:selected").text()
        self.after $('<input>').attr("type", "text").val(value).attr("class", self.attr("class")).attr('title', value)
        self.remove()
      $("input:not([type=hidden]):not(.questionnaire-invalidate-button), textarea", @).attr("readonly", true).attr("disabled", true)
