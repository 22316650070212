$ ->
  $('a#add-genotype-result').on 'click', (evtHandler) ->
    evtHandler.preventDefault()
    $.addFormlet('genotype-result')

  $.checkYearOfSampling = ->
    if $("#specimen_unknown_year").is(':checked')
      $("#specimen_year").val("").attr("disabled", true).addClass("disabled")
    else
      $("#specimen_year").attr("disabled", false).removeClass("disabled")

  $("#specimen_unknown_year").click ->
    $.checkYearOfSampling()

  $.checkYearOfSampling()

  $('form#create_virus_culture').on 'submit', (evtHandler) ->
    form = $(this)

    if form.data('submit_button').value == "Cancel"
      return true # Skip validation if the user is cancelling.

    selected_feeder_cell_rows = form.find("#selected-feeders tr.specimen")
    if selected_feeder_cell_rows.length == 0
      alert("Please select at least one feeder cell pool.")
      evtHandler.preventDefault()
      return false

    return true

  $('form#create_virus_culture input[type=submit]').on 'click', (evtHandler) ->
    $('form#create_virus_culture').data('submit_button', evtHandler.target)

  $.each $("input[name='processing_event[event_time]']"), (i, input) ->
    if $(input).parents('.test-modal-form').length > 0
      date = new Date
    else
      date = new Date(input.value)
    input.value = window.dateTimeFormatter.formatDate(
      date,
      window.dateTimeFormat
    )
