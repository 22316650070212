$ ->
  if $('.user_form').length > 0
    $('input[type=checkbox].assign-application-manager').change (event) ->
      $form = $(this).closest('.user_form')
      $app_manager_cb = $(this)
      if $app_manager_cb.prop('checked') == false
        app_program_manager_cbs = $form.find('[data_application_manager_programs]')
        app_program_manager_cbs.each (i, el) ->
          $(el).prop('checked', false)

    $('input[type=checkbox].assign-biostatistician').change (event) ->
      $form = $(this).closest('.user_form')
      $app_manager_cb = $(this)
      if $app_manager_cb.prop('checked') == false
        app_program_manager_cbs = $form.find('[data_biostatistician_programs]')
        app_program_manager_cbs.each (i, el) ->
          $(el).prop('checked', false)

    $('input[type=checkbox].assign-ep-reviewer').change (event) ->
      $form = $(this).closest('.user_form')
      $app_manager_cb = $(this)
      if $app_manager_cb.prop('checked') == false
        app_program_manager_cbs = $form.find('[data_ep_reviewer_programs]')
        app_program_manager_cbs.each (i, el) ->
          $(el).prop('checked', false)

    $('input[type=checkbox].assign-program-manager').change (event) ->
      $form = $(this).closest('.user_form')
      $app_manager_cb = $(this)
      if $app_manager_cb.prop('checked') == false
        app_program_manager_cbs = $form.find('[data_program_manager_programs]')
        app_program_manager_cbs.each (i, el) ->
          $(el).prop('checked', false)
