$ ->
  $('.scrollable_area').on 'click', 'a.add-enrollment.add-link', (e) ->
    e.preventDefault()
    return unless confirm("Are you sure you want to add a new enrollment? This cannot be undone once you save.")
    self = $(e.target)
    siteId = self.parent().first().attr("id")
    siteIdNumber = siteId.match(/site-(\d+)/)[1]

    template = $("##{siteId} div.enrollment:last").clone()
    template.find("input:text, input.enrollment-id").val ""
    template.find(".sendout-status").text '\u00a0'
    template.find("input.site-id").val siteIdNumber
    template.adjust_rails_form_fields($("div.enrollment").length)
    template.find(".enrollment-number").text($("##{siteId} div.enrollment").length + 1)
    template.find(".new-enrollment").prop("checked", true)
    $("##{siteId}").append template

    template.find(".datepicker").removeClass("hasDatepicker")
    template.find(".datetimepicker").removeClass("hasDatetimepicker")
    $.add_datepickers()

  $('body').on 'change', '[data-ep-in-quarter]', (e) ->
    target = $(e.target)
    oldValue = target.attr('data-ep-in-quarter')
    if(oldValue && (oldValue != target.val()))
      $('[data-submit-button]').attr(
        'data-confirm',
        'EP in quarter value has changed. Sample data may be lost. Are you sure you wish to continue?'
      )
    else
      $('[data-submit-button]').removeAttr('data-confirm')


