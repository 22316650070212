$ ->
  window.ScorecardCreateForm =
    createEvents: ->
      @displayPopulatedDescriptions()
      @createDescriptionEvent()

    createDescriptionEvent: ->
      $('a.js-use-other-criteria').on 'click', (event) ->
        event.preventDefault()
        $eval_description_input = $(@).parents('fieldset.scorecard-target-record').find("input.js-point-description")
        if $eval_description_input.is(':hidden')
          $eval_description_input.show()
        else
          $eval_description_input.hide().val('')

    displayPopulatedDescriptions: ->
      $('fieldset.scorecard-target-record input.js-point-description').each (i ,el) ->
        $(el).show() if $(el).val()

  window.ScorecardCreateForm.createEvents()
