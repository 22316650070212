$.standard_float = {
  format: 'float',
  minimum: 0,
  maximum: 99.9,
  digits_after_decimal: 1,
  allow_blank: true
}

$.standard_float_extra = {
  format: 'float',
  minimum: 0,
  maximum: 99.99,
  digits_after_decimal: 2,
  allow_blank: true
}

$.extended_float = {
  format: 'float',
  minimum: 0,
  maximum: 100.0,
  digits_after_decimal: 2,
  allow_blank: true
}

$.required_extended_float = {
  format: 'float',
  minimum: 0,
  maximum: 100.0,
  digits_after_decimal: 1,
  required: true
}

$.required_float_no_max = {
  format: 'float',
  minimum: 0,
  digits_after_decimal: 1,
  required: true
}

$.required_extended_float_no_max = {
  format: 'float',
  minimum: 0,
  digits_after_decimal: 2,
  required: true
}

$.standard_integer = {
  format: 'integer',
  minimum: 0,
  maximum: 9999,
  allow_blank: true
}

$.medium_string = {
  format: 'string',
  max_length: 255,
  allow_blank: true
}

$.bright = {
  format: 'float',
  minimum: 0,
  maximum: 100.0,
  digits_after_decimal: 1,
  allow_blank: true
}

$.required_float = {
  format: 'float',
  minimum: 0,
  maximum: 99.9,
  digits_after_decimal: 1,
  required: true
}

$.required_string = {
  format: 'string',
  required: true
}

$.required_email = {
  format: 'email',
  required: true
}

$.required_integer = {
  format: 'integer',
  minimum: 0,
  required: true
}

$.validations = $.validations || {}

$.convert_number_to_float = (field, places) ->
  value = field.val()

  unless value is ''
    do convert_comma = ->
      value = value.replace(',', '.')

    do add_missing_decimal = ->
      regex = /\./
      value += '.' unless regex.test(value)

    do add_zeros = ->
      value_array = value.split('.')
      decimals = value_array[1]
      zeros_needed = places - decimals.length

      if zeros_needed > 0
        value += '0' for i in [1..zeros_needed]

  field.val(value)

$.validation_rules_for_field = (field) ->
  result = []
  classes = field.attr('class').split(/\s+/)
  if classes
    for css_class in classes
      if $.validations[css_class]
        result.push($.validations[css_class])

  return result

$.before_validation = (field) ->
  rules = $.validation_rules_for_field(field)
  if (rules.length > 0) && (rules[0].format?) && (rules[0].format == 'float')
    decimal_places = 1
    if rules[0].digits_after_decimal?
      decimal_places = rules[0].digits_after_decimal

    $.convert_number_to_float(field, decimal_places)

$.validate = (field) ->
  valid = true
  rules = $.validation_rules_for_field(field)

  if rules.length > 0
    result = $.validate_with_rules(field.val(), rules[0], field)
    if result.valid
      field.removeClass('invalid')
      field.attr('original-title', "")
      field.tipsy('hide')
    else
      field.addClass('invalid')
      field.data('errors', result.errors)
      field.attr('original-title', result.errors.join("<br>"))
      $.hide_iqa_validation_messages()
      field.tipsy('show')
      valid = false

  return valid

$.validate_with_rules = (value, rules, field) ->
  result = {
    valid: true,
    errors: []
  }

  if rules.require_value_in_group_on_submit?
    hasValue = _.any $(field).parent().children('input:visible'), (input) ->
      ValidationTools.hasValue(input)
    unless hasValue
      result.valid = false
      result.errors.push("Missing input")

  if(rules.allow_blank && value == "")
    return result

  if rules.format == 'integer'
    r = /^\d+$/gi
    unless r.test(value) || (rules.allow_blank && value == "")
      result.valid = false
      result.errors.push("Only integers are allowed")

  if rules.format == 'float'
    r = /^\d+(\.\d+)?$/gi
    unless r.test(value) || (rules.allow_blank && value == "")
      result.valid = false
      result.errors.push("Only floating point numbers are allowed")

  if rules.format == 'email'
    r = /^[A-Za-z0-9+_.-]+@([A-Za-z0-9]+\.)+[A-Za-z]{2,6}$/gi
    unless r.test(value) || (rules.allow_blank && value == "")
      result.valid = false
      result.errors.push("Valid email required")

  if rules.max_length?
    v = String(value)
    if v.length > rules.max_length
      result.valid = false
      result.errors.push("Only " + rules.max_length + " characters are allowed")

  if rules.minimum?
    v = parseFloat(value)
    unless v >= rules.minimum
      result.valid = false
      result.errors.push("Minimum value is " + rules.minimum)

  if rules.maximum?
    v = parseFloat(value)
    unless v <= rules.maximum
      result.valid = false
      result.errors.push("Maximum value is " + rules.maximum)

  if rules.digits_after_decimal?
    r = new RegExp("^\\d+\.\\d{"+rules.digits_after_decimal+"}$", "gi")
    unless r.test(value) || (rules.allow_blank && value == "")
      result.valid = false
      result.errors.push(rules.digits_after_decimal + " decimal place(s) are required")

  if rules.required?
    unless value || field.is(':hidden')
      result.valid = false
      result.errors.push('This field is required')

  return result

$(document).ready ->
  $('body').on 'change', '[data-trigger-required]', (event) ->
    checkbox = $(event.target)
    targetField = checkbox.data('trigger-required')
    if checkbox.is(':checked')
      $("[data-required-target=" + targetField + "]").addClass('required_string')
    else
      $("[data-required-target=" + targetField + "]").removeClass('invalid required_string').tipsy('hide')