$ ->
  window.KeyFields =
    createKeyFieldNameUrl: "/key_field_names"
    deleteKeyFieldUrl: "/key_fields/key_field_id"

    initialize: ->
      @bindEvents()
      @showRemoveKeyFieldNewValueButton()

    showRemoveKeyFieldNewValueButton: ->
      $('.allowed-to-remove-new-value').show()

    bindEvents: ->
      @addKeyFieldNewValue()
      @removeKeyFieldNewValue()
      @removeKeyFieldRow()
      @addKeyFieldRow()
      @addKeyFieldName()

    addKeyFieldNewValue: ->
      $('[data-container-for-assay]').on 'click', '[data-add-new-value-for-key-field]', (event) ->
        removeButtonContainer = $(event.target).closest('[data-group="new-values-for-key-field"]').find('[data-container="remove-key-field-new-value"]')
        removeButtonContainer.show()
      $('[data-container-for-assay]').on 'nested:fieldAdded:new_values', (event) ->
        field = event.field
        # mark new value as unsaved
        field.find('[data-new-value-for-key-field]').addClass('unsaved-key-field-new-value')

    removeKeyFieldNewValue: ->
      $("[data-container-for-assay]").on 'click', "[data-action='remove-new-value-for-key-field']", (event) =>
        event.preventDefault()
        removeLink = $(event.target)
        newValues = removeLink.closest("[data-group='new-values-for-key-field']")
        lastNewValueField = newValues.find('[data-new-value-for-key-field]').last()
        lastNewValueField.addClass('removed-key-field-new-value')

        if lastNewValueField.hasClass('unsaved-key-field-new-value')
          lastNewValueField.remove() # remove new value fields from the DOM if the new value is unsaved
        else
          lastNewValueField.find('[data-field="delete-key-field-new-value"]').val(1) # set _delete field to delete the new value record serverside

        unless @_multipleNewValuesPresentForKeyField(newValues)
          $(event.target).closest('[data-container="remove-key-field-new-value"]').hide()

    _multipleNewValuesPresentForKeyField: (newValues) ->
      $(newValues).find("[data-new-value-for-key-field]:not(.removed-key-field-new-value)").length > 1

    removeKeyFieldRow: ->
      $("[data-container-for-assay]").on 'click', '[data-action="remove-unsaved-key-field-row"]', (event) =>
        event.preventDefault()
        $button = $(event.target)
        $keyFieldRow = $button.closest('[data-key-field-row]')
        $keyFieldRow.remove()

    addKeyFieldRow: ->
      $("[data-container-for-assay]").on 'nested:fieldAdded:key_fields', (event) =>
        $newKeyFieldRow = $(event.field)
        @_addOldValueField($newKeyFieldRow)
        @_addNewValueField($newKeyFieldRow)

        @_hideRemoveNewValueFieldButton($newKeyFieldRow)

        @_removeRemoveNewKeyFieldCheckbox($newKeyFieldRow)
        @_showRemoveNewKeyFieldButton($newKeyFieldRow)
        @_markKeyFieldRowAsNewRecord($newKeyFieldRow)

        $selectFields = $newKeyFieldRow.find('select')
        Assays.activateSelect2($selectFields)

    _markKeyFieldRowAsNewRecord: (keyFieldRow) ->
      $(keyFieldRow).find('[data-key-field-row]').addClass('new-key-field-record')

    _hideRemoveNewValueFieldButton: (newKeyFieldRow) ->
      $(newKeyFieldRow).find('[data-container="remove-key-field-new-value"]').hide()

    _removeRemoveNewKeyFieldCheckbox: (newKeyFieldRow) ->
      $(newKeyFieldRow).find('.remove-button-column input[type="checkbox"]').remove()

    _showRemoveNewKeyFieldButton: (newKeyFieldRow) ->
      $(newKeyFieldRow).find('[data-action="remove-unsaved-key-field-row"]').removeClass('hidden-remove-key-field-button')

    _addOldValueField: (newKeyFieldRow) ->
      $newKeyFieldRow = $(newKeyFieldRow)
      $addOldValueButton = $newKeyFieldRow.find('[data-add-old-value-for-key-field]')
      $addOldValueButton.click()

    _addNewValueField: (newKeyFieldRow) ->
      $newKeyFieldRow = $(newKeyFieldRow)
      $addNewValueButton = $newKeyFieldRow.find('[data-add-new-value-for-key-field]')
      $addNewValueButton.click()

    addKeyFieldName: ->
      $("[data-container-for-assay]").on 'click', '[data-add-key-field-name]', (event) =>
        event.preventDefault()
        keyFieldName = @keyFieldNameField().val()
        if keyFieldName.length > 0
          if confirm("Are you sure you wish to add the key field type: #{keyFieldName}?")
            @_disableAddNewKeyFieldSection()
            @createNewKeyField(keyFieldName)
        else
          alert("Please enter a name for the new key field type.")

    createNewKeyField: (keyFieldName) ->
      keyFieldNameAttributes = {
        key_field_name_attributes: {
          name:  keyFieldName
        }
      }
      @_sendRequestToCreateKeyFieldName(keyFieldNameAttributes)

    keyFieldNameField: () ->
      $("#key_field_name")

    _sendRequestToCreateKeyFieldName: (keyFieldNameAttributes) ->
      $('[data-key-field-name-error]').hide()

      $.ajax(
        url: @createKeyFieldNameUrl,
        type: "POST",
        data: keyFieldNameAttributes,
        dataType: "json"
      ).done((data, textStatus, jqXHR) =>
        @keyFieldNameField().val("")
        newData = $.parseJSON(jqXHR.responseText)
        newOptionTag = $('<option>').val(newData.id).html(newData.name)
        $('select[data-key-field-select]').append(newOptionTag)
        @_enableAddNewKeyFieldSection()
      )
       .fail((data, textStatus, errorThrown) =>
          $('[data-key-field-name-error]').show()
          @_enableAddNewKeyFieldSection()
      )

    _disableAddNewKeyFieldSection: ->
      $('[data-field="new-key-field-name"]').prop("disabled", true)
      $('[data-add-key-field-name]').toggle()

    _enableAddNewKeyFieldSection: ->
      $('[data-field="new-key-field-name"]').prop("disabled", false)
      $('[data-add-key-field-name]').toggle()

  window.KeyFields.initialize() if $("[data-key-fields-section]").length > 0
