(function ( $ ) {

    $.fn.drag_select = function(options) {
      var settings = $.extend({
          // These are the defaults.
          select_color: "#EFF1F9",
          on_row_select: null,
          table_row_selector: null
      }, options );
      
      if (settings.table_row_selector == null) {
        return false;
      }
      
    
      jQuery(document).on('dragstart', settings.table_selector, function(event) {
        var row = jQuery(event.target).closest("tr");
        var the_table = row.closest("table");
        the_table.data("drag-select.start-row", row);
      });

      if(settings.on_row_select != null) {
        jQuery(document).on('drag', settings.table_selector, function(event) {
          var end_row = jQuery(event.target).closest("tr");
          var the_table = end_row.closest("table");
          var start_row = the_table.data("drag-select.start-row");
          settings.on_row_select.apply(this, [event, start_row, end_row]);
        });
        
      }
      
      jQuery(document).on('dragend', settings.table_selector, function(event) {
        var end_row = jQuery(event.target).closest("tr");
        var the_table = end_row.closest("table");
        var start_row = the_table.data("drag-select.start-row");
        if ((typeof start_row != "undefined") && (typeof end_row != "undefined")) {
          DragSelect.selectRowRange(start_row, end_row);
        }
        the_table.data("drag-select.start-row", null);
      });
      
      return this;
    };

}( jQuery ));


var DragSelect = {
  selectRowRange: function(start_row, end_row) {
    if((typeof start_row == "undefined") || (typeof end_row == "undefined")) {
      return false;
    }
    var start_table = start_row.closest("table");
    var end_table = end_row.closest("table");
    if(start_table[0] == end_table[0]) {
      var all_rows = start_table.find("tr");
      var start_index = all_rows.index(start_row);
      var end_index = all_rows.index(end_row);
      if(end_index < start_index) {
        var swap_temp = start_index;
        start_index = end_index;
        end_index = swap_temp;
      }
      var selected_rows = all_rows.slice(start_index, end_index+1);
      selected_rows.trigger("drag-select.selected");
    }
    else {
      log("start table is not the same as the end table");
    }
  },
  preSelectRowRange: function(start_row, end_row) {
    var table = start_row.closest("table");
    var all_rows = table.find("tr");
    var start_index = all_rows.index(start_row);
    var end_index = all_rows.index(end_row);
    if(end_index < start_index) {
      var swap_temp = start_index;
      start_index = end_index;
      end_index = swap_temp;
    }
    all_rows.removeClass("drag-select-selected");
    var selected_rows = all_rows.slice(start_index, end_index+1);
    selected_rows.addClass("drag-select-selected");
  }
};