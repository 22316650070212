jQuery.fn.sgaFormCreator = () ->
  openSgaForm = (trigger) ->
    sgaForm = $('#sga-form')
    newSga = $(sgaForm.clone())
    newSga.find('.form-template').val('')
    i = $('#sga-list').children('div').length
    newSga.adjust_rails_form_fields(i)
    newSga.attr('id', "sga-form-#{i}")
    newSga.find('.sga-gel-files').multipleFileUploader()
    newSga.find('.sga-ab1-files').multipleFileUploader()

    newSga.on 'click', '.save-sga-button', (e) ->
      e.preventDefault()
      saveSga(newSga, trigger)
    newSga.on 'click', '.cancel-sga-button', (e) ->
      e.preventDefault(newSga)
      cancelSga(newSga, trigger)
    sgaForm.before(newSga)
    newSga.show()
    trigger.hide()

  saveSga = (newSga, trigger) ->
    newSga.children('div').hide()
    sgaRow = newSga.find('div.sga-row')
    sgaRow.find('a').text(newSga.find('input.sga-name').val())
    sgaRow.show()
    trigger.show()

  cancelSga = (newSga, trigger) ->
    newSga.find('.form-template').val('template')
    $(newSga).hide()
    trigger.show()

  this.each () ->
    trigger = $(this)
    trigger.click (eventHandler) ->
      eventHandler.preventDefault()
      openSgaForm($(eventHandler.target))
