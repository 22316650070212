jQuery ->
  $filters = $("#test-result-filters")
  $filters.on "click", "a#add-test-result-filter", (e) ->
    e.preventDefault()
    $.addTestResultFilter()

  $filters.on "click", "a.remove-test-result-filter", (e) ->
    e.preventDefault()
    $(this).closest(".test-result-filter").remove()

  $filters.on "change", ".test-result-option", (e) ->
    $.testResultOptionToggle $(this)

  $filters.on "change", ".test-result-comparator", (e) ->
    $.testResultComparatorToggle $(this)

  $.addTestResultFilter = ->
    this.addFormlet("test-result-filter")
    new_child = $filters.children('[data-test-filter]').last()
    new_child.find("[data-remove-filter]").show()
    new_child.find("input[type=text]").val("")
    new_child.find("select").each (_index, select) ->
      select = $(select)
      select.val($("##{select.attr("id")} option:first"))
    $.testResultOptionToggle new_child.find("select.test-result-option")
    $.testResultComparatorToggle new_child.find("select.test-result-comparator")

  $.testResultOptionToggle = (select) ->
    select.siblings("[data-option-set]").hide()
    dataType = select.find(":selected").data("type")
    select.siblings(".#{dataType}").show() if dataType

  $.testResultComparatorToggle = (select) ->
    if select.val() == ""
      select.siblings("span.range").show()
      select.siblings("span.result").hide()
    else
      select.siblings("span.range").hide()
      select.siblings("span.result").show()

  # Initialize display of test result options on page load
  $(".test-result-option").each (_i) ->
    $.testResultOptionToggle $(this)
  $(".test-result-comparator").each () ->
    $.testResultComparatorToggle $(this)
  $("[data-remove-filter]:first").hide()
