window.LagBiostatisticianView = do ->
  initialized = false
  self = {}

  init = ->
    unless initialized
      initialized = true
      self.panelResults = 'panel-results'
      self.calibratorTestResults = 'calibrator-test-results'
      self.$tabLinks = $('[data-change-tab]')
      self.$panelResultsLink = $("[data-change-tab='#{self.panelResults}']")
      self.$calibratorTestResultsLink = $("[data-change-tab='#{self.calibratorTestResults}']")
      self.$sections = $('[data-tab-section]')
      bindEvents()
      activateAnchorTab()

  bindEvents = ->
    bindChangeTab()

  bindChangeTab = ->
    self.$tabLinks.click (e) ->
      $link = $(e.target)
      activateTab($link)

  activateTab = ($link) ->
    sectionId = $link.attr('data-change-tab')
    $section = $("[data-tab-section='#{sectionId}']")
    self.$sections.removeClass('active')
    self.$tabLinks.removeClass('active')
    $link.addClass('active')
    $section.addClass('active')

  activateAnchorTab = ->
    hrefAnchor = location.hash.replace('#', '')
    return unless hrefAnchor.length
    if hrefAnchor == self.calibratorTestResults
      activateTab(self.$calibratorTestResultsLink)
    else if hrefAnchor == self.panelResults
      activateTab(self.$panelResultsLink)


  init: init

$ ->
  window.LagBiostatisticianView.init() if $("[data-attribute='lag-biostatistician-results-view']")
