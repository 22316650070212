$(document).ready ->
  class Aliquots
    constructor: ->
      @container = $.parseJSON($("#is-container").val()) if $('#is-container').length > 0

    add: ->
      createNewAliquotFields()
      showRemoveButtonForMultipleAliquots()

    showRemoveButtonForMultipleAliquots = ->
      if $(".aliquot-entry").length <= 1
        $(".aliquot-entry:first").children(".discard").children().remove()
      else
        # If there isn't a remove button on the first aliquot, then add one.
        selectedLocation = $(".aliquot-entry:last").children().children("#location_id").val()
        selectedShelf = $(".aliquot-entry:last").children().children(".selected_shelf").val()
        $(".aliquot-entry:last").children().children("#location_id").val(selectedLocation)
        $(".aliquot-entry:last").children().children(".selected_shelf").val(selectedShelf)
        if $(".aliquot-entry:first").children(".discard").children().length == 0
          $(".aliquot-entry:first").children(".discard").append('<a class="delete-link remove-aliquot">Remove</a>')
          $(".aliquot-entry:last").children(".discard").append('<a class="delete-link remove-aliquot">Remove</a>')

    createNewAliquotFields = ->
      template = $(".aliquot-entry:last").clone()
      template.find("input:text, input.aliquot-id").val ""
      template.adjust_rails_form_fields($(".aliquot-entry").length)
      template.find(".aliquot-number").text($(".aliquot-entry").length + 1)
      $(".aliquot-entry:last").after template

    remove: (remove_button) ->
      $(remove_button).parent().parent().remove()
      # If there is only one aliquot, then remove the ability to remove it. We
      # cannot allow all of the aliquots to be removed since we use them as a
      # template for new aliquots.
      showRemoveButtonForMultipleAliquots()
      handleExceptions ->
        total = calculateTotal()
        unaliquoted = initial_amount()
        removeFromUnaliquoted(total, unaliquoted)

    initial_amount = ->
      # total_to_aliquot is a global variable set on page load.
      total_to_aliquot


    is_container: ->
      @container

    is_valid: ->
      @valid

    updateTotal: ->
      $('table input:hidden').attr('disabled', false)
      handleExceptions =>
        @valid = false
        total = calculateTotal()
        unaliquoted = initial_amount()
        removeFromUnaliquoted(total, unaliquoted)
        @valid = true

    removeInvalidFields: ->
      aliquotList = $(".aliquot-entry")
      for aliquot in aliquotList
        [quantity, amount, units] = getValues(aliquot)
        # Remove any empty fields. I might change this to disable.
        hasNoEmptyFields = handleExceptions ->
          if aliquots.is_container()
            unless quantity
              throw EmptyFieldError
          else
            unless amount and quantity
              throw EmptyFieldError
          return true
      # Disable calculated fields if there's nothing to aliquot
        return false unless hasNoEmptyFields
      if aliquots.is_container()
        quantity = getValues("#unaliquoted")
        if quantity <= 0
          $("#unaliquoted").children("td").children().attr('disabled','disabled')
      else
        [quantity, amount, units] = getValues("#unaliquoted")
        if amount <= 0 or quantity <= 0
          $("#unaliquoted").children("td").children().attr('disabled','disabled')
        [quantity, amount, units] = getValues("#unaliquoted-partial")
        if amount <= 0 or quantity <= 0
          $("#unaliquoted-partial").children("td").children().attr('disabled','disabled')
      return true

    # Calculate the remaining material.
    calculateUnaliquoted = (total, unaliquoted) ->
      [unaliquotedQuantity, unaliquotedAmount, unaliquotedUnits] = parseUnaliquoted(unaliquoted)
      unaliquotedQuantity = parseInt(unaliquotedQuantity, 10)
      if aliquots.is_container()
        unaliquotedTotal = unaliquotedQuantity
      else
        unaliquotedAmount = parseFloat unaliquotedAmount
        unaliquotedTotal = convertToMilli(unaliquotedUnits) * unaliquotedAmount * unaliquotedQuantity
      difference = unaliquotedTotal - total
      return [difference, unaliquotedAmount, unaliquotedUnits]

    # Remove aliquoted material from the unaliquoted field.
    # Returns true if not over aliquoted.
    removeFromUnaliquoted = (total, unaliquoted) ->
      [difference, unaliquotedAmount, unaliquotedUnits] = calculateUnaliquoted(total, unaliquoted)
      if difference < 0
        throw OverAliquotedError
      [quantity, amount, units] = composeUnaliquoted(difference, unaliquotedAmount, unaliquotedUnits)
      setValues("#unaliquoted", quantity, amount, units)

    # Pull the starting material from a heading on the page.
    # Returns [unaliquotedQuantity, unaliquotedAmount, unaliquotedUnits]
    parseUnaliquoted = (unaliquoted) ->
      return [unaliquoted.quantity, unaliquoted.amount_per_unit, unaliquoted.units];

    # Convert unaliquoted material into human readable form.
    composeUnaliquoted = (unaliquoted, amount, units) ->
      if aliquots.is_container()
        quantity = unaliquoted
        return [quantity]
      else
        quantity = calculateRemainder(unaliquoted, amount, units)
        return [quantity, amount, units]

    # Calculate partial units of unaliquoted material.
    calculateRemainder = (amount, unitAmount, units) ->
      unitAmount = convertToMilli(units) * unitAmount
      quantity = Math.floor(amount/unitAmount)
      showPartialUnaliquoted(amount % unitAmount, units.slice(-1))
      return quantity

    # Calculate the total aliquoted material.
    calculateTotal = ->
      aliquotList = $(".aliquot-entry")
      total = 0
      for aliquot in aliquotList
        [quantity, amount, units] = getValues(aliquot)
        if aliquots.is_container()
          if quantity
            total = quantity + total
        else
          if quantity and amount
            total = convertToMilli(units) * amount * quantity + total
        if quantity <= 0 or amount <= 0
          throw NegativeError
      return total

    # Show partial units of unaliquoted material.
    showPartialUnaliquoted = (remainder, units) ->
      partial = convertFromMilli remainder, units
      remainder = Math.round(partial[0] * remainder * 1000000000)/1000000000 # skirt floating point errors
      # Since partial containers can only have a quantity of 1 or 0, just check to
      # see if there is any material in the partial.
      if remainder
        setValues("#unaliquoted-partial", 1, remainder, partial[1])
      else
        setValues("#unaliquoted-partial", 0, remainder, partial[1])

    ## TOOLS

    getValues = (section) ->
      inputs = $(section).children("td").children(".aliquot-input")
      if aliquots.is_container()
        quantity = parseInt($(inputs[0]).val(), 10)
        return [quantity]
      else
        quantity = parseInt($(inputs[0]).val(), 10)
        amount = parseFloat $(inputs[1]).val()
        units = $(inputs[2]).val()

        return [quantity, amount, units]

    setValues = (section, quantity, amount, units) ->
      if aliquots.is_container()
        [quantityField] = $(section).children("td").children(".aliquot-input")
        quantityField.value = quantity
        return true
      else
        [quantityField, amountField, unitsField] = $(section).children("td").children(".aliquot-input")
        quantityField.value = quantity
        amountField.value = amount
        unitsField.value = units
        return true

    # Convert normal or micro units to milli units for easier math.
    convertToMilli = (units) ->
      if units == "L" or units == "g"
        return 1000000
      else if units == "mL" or units == "mg"
        return 1000
      # Checks for micro units, did it this way to avoid matching a greek
      # character, which was causing problems.
      else
        return 1

      # Convert back when you are done.
    convertFromMilli = (amount, unitType) ->
      amount = Math.abs(amount)
      if amount / 1000000 >= 1
        units = unitType
        factor = 1 / 1000000
      else if amount /1000 >= 1
        units = "m" + unitType
        factor = 1/1000
      else
        units = "μ" + unitType
        factor = 1
      return [factor, units]

    handleExceptions = (block) ->
      try
        retval = block()

        # Restore non-error state after successfully calling block
        $("#negative-warning").fadeOut()

        $("#unaliquoted").css("border", "1px solid rgb(187, 187, 187)")
        $("#over-aliquoted").fadeOut()

        return retval
      catch error
        if error == OverAliquotedError
          $("#unaliquoted").css("border", "1px solid red")
          $("#over-aliquoted").fadeIn()
        else if error == NegativeError
          $("#negative-warning").fadeIn()
        else if error == EmptyFieldError
          $("#empty-field-warning").fadeIn()
          return false
        else
          throw error

    NegativeError = {}
    OverAliquotedError = {}
    EmptyFieldError = {}


  aliquots = new Aliquots()

  simpleCheck = ->
    quantity = $("#reagent_transaction_quantity").val()
    amount = $("#reagent_transaction_amount_per_unit").val()
    if $.parseJSON($("#is-container").val())
      unless quantity
        $("#empty-field-warning").fadeIn()
        return false
    else
      unless amount and quantity
        $("#empty-field-warning").fadeIn()
        return false
    return true

  ###
  #
  # Event Handlers
  #
  ###
  $aliquots = $('.aliquots')

  # Add aliquots to the form.
  $aliquots.on 'click', '.add-aliquot', (e) ->
    e.preventDefault()
    aliquots.add()

  # Remove Aliquots from the form.
  $aliquots.on 'click', '.remove-aliquot', (e) ->
    e.preventDefault()
    aliquots.remove(e.target)

  # Raise a dialog window when the user submits invalid data.
  $(".reagent-transform").submit( (e) ->
    if $("#unaliquoted").length > 0
      aliquots.updateTotal()
      return false unless aliquots.is_valid()
      return aliquots.removeInvalidFields()
    else if $("#reagent_transaction_quantity").length > 0
      return simpleCheck()


  )

  # Recalculate the remaining materials when the text fields change.
  $('.aliquot-input').on "keyup",  (e) ->
    aliquots.updateTotal()

  # Recalculate the remaining materials when the text fields change.
  $('.aliquot-input').on "change",  (e) ->
    aliquots.updateTotal()

