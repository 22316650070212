$ ->
  # Initialize the `num_vials_tested` value to 1 if there
  # is not a saved value (i.e this is a new sample)
  $("[data-add-remove-vial]").each (_, element)->
    id = $(element).attr('id')
    num_vials_tested_input = $("[data-num-vials-tested-" + id + ']')

    if !num_vials_tested_input.val()
      num_vials_tested_input.val('1')

  $('.cryo-vial-active').on 'click', (e)->
    table = $(e.target).closest('table')
    $('td input:checkbox', table).not(e.target).prop('checked', false);

  $("[data-add-remove-vial]").on 'click', (e)->
    id = $(this).attr('id')
    table = $(e.target).parent().siblings('table')
    second_vial_row = $("[data-second-" + id + "]")

    # Removing vial 2
    if second_vial_row.is(":visible")
      second_vial_row.hide()
      $("[data-num-vials-tested-" + id + ']').val('1')
      $(this).html('<a href=#>Add Vial 2</a>')

      $('td input:checkbox', table).first().prop('checked', true);
      $('input:checkbox', second_vial_row).prop('checked', false);

      # Clear out the removed vial
      $('select', second_vial_row).val('Automated')
      $('input:text', second_vial_row).each (_, child_input)->
        $(child_input).val('')
    # Adding vial 2
    else
      second_vial_row.show()
      $("[data-num-vials-tested-" + id + ']').val('2')
      $(this).html('<a href=#>Remove Vial 2</a>')

  $("#cryo-vial").submit (event) ->
    if $.confirmSubmit(event)
      $.validateForm($(this))

      $("[data-cryo-vials-for-sample]").each (idx, element)->
        table = $(element).closest('table')
        checked_visible_checkboxes = $('td input:checkbox:visible:checked', table)
        unless checked_visible_checkboxes.length == 1
          alert('You must select one active vial when submitting the results.')
          event.preventDefault();
