$ ->
  # Persist IR Form/IQA Review tabs
  siteDataSubtabs = $('[data-ir-report-subtabs-menu]').find('.ir_report_subtab')
  siteDataSubtabsEnclosingDiv = $('#ir-report-cryo-tabs')
  parseSiteDataSubtabIndex = (tabIdentifier) ->
    if tabIdentifier == '#investigational_review' then 0 else 1

  $.persistSubtab(
    siteDataSubtabs,
    siteDataSubtabsEnclosingDiv,
    'activeIrReportTab',
    parseSiteDataSubtabIndex
  )

  $("#ir-form").submit (event) ->
    $.validateForm($(this)) if $.confirmSubmit(event)

  $("#add-review-comment").on 'click', ()->
    $("#iqa-review-form").submit()

  $("#iqa_review_investigation_complete_true").on 'click', ()->
    if $("#iqa_review_investigation_complete_true").is(':checked')
      $("#iqa_review_generate_ir_report").prop('checked', true)

  $("#iqa_review_investigation_complete_false").on 'click', ()->
    if $("#iqa_review_investigation_complete_false").is(':checked')
      $("#iqa_review_ir_submitted").val(false)

  $("#iqa-review-form").submit (event) ->
    if $.confirmSubmit(event)
      $.validateForm($(this))
      # If there are no saved comments and the comment text area is empty
      no_comments = $('#no_iqa_review_comments').val() && !$("textarea.note-text").val()
      if no_comments && $('#iqa_review_investigation_complete_false').is(':checked')
        alert('You must enter a comment if the investigation has been marked as incomplete')
        event.preventDefault();
