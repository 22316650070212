$ ->
  # Validates fields with the `field_with_validation` class
  #
  # @param form [JQuery] the form being submitted wrapped in a JQuery object.
  #   Passed in as $(this) inside the onSubmit function
  # @param validateInvisible [Bool] whether or not invisible fields should be validated.
  #   Defaults to false (only visible fields are validated)
  # @param activateTab [Func(String, Integer)] changes focus to the tab
  #   currently being validated. If an error is found, it will be reported, and
  #   the tab being validated will be shown. This function only needs to activate
  #   the tab with the info matching the params to work.
  #   This function has 2 params:
  #     @param tabId [String] the id attribute of the tab element being validated
  #     @param tabIndex [Integer] the index of the tab being validated
  $.validateForm = (form, validateInvisible = false, activateTab = null) ->
    errors_fields = []
    # We will only perform form validations if it was the Submit button that was clicked
    return true if $(document.activeElement).val() == 'Save'

    $(".field-with-validation").each ->
      currentField = $(this)
      tab_element = currentField.closest(".ui-tabs-panel")
      tab_id = tab_element.attr('id')
      tab_index = $('.ui-tabs-panel').index($('#' + tab_id))

      skip_validation_attribute = tab_element.data('skip-validations')
      skip_validations = skip_validation_attribute != undefined && skip_validation_attribute != null
      return true if skip_validations

      activateTab(tab_id, tab_index) if activateTab

      return true if currentField.is(':hidden') && !validateInvisible
      if (!$.validate(currentField))
        errors_fields.push(currentField)

      if errors_fields.length > 0
        try
          form.data('submitting', false)
          alert('The results contain errors. Please review entries before saving.\n')
          event.preventDefault()
        return false
      else
        return true

  # Confirms user-triggered form submits
  #
  # @return [Bool] returns true if submit was clicked and confirmed,
  #   otherwise returns false
  $.confirmSubmit = (e) ->
    return false if $(document.activeElement).val() == 'Save'
    return true if confirm(confirmSubmitMessage)
    e.preventDefault()
    false

  confirmSubmitMessage = 'Are you sure you want to submit your results? ' +
    'Once you submit your results you will no longer be able to make any changes.'

  $(".field-with-validation").each ->
    field = $(this)
    field.tipsy({html: true, fade: false, trigger: 'manual', fallback: 'Value is not valid'})

  $(".field-with-validation").blur (e) ->
    field = $(e.target)
    $.before_validation(field)
    $.validate(field)

  $(".field-with-validation").keyup (e) ->
    # Avoid validating field if the tab key is released. (e.g. When the user tabs into the next field.)
    # Note: When tabbing between fields, the field which the keyup event is fired upon is the field that is being tabbed into, rather than the field that is being tabbed out of.
    tab_key = 9
    if e.keyCode == tab_key
      return true
    field = $(e.target)
    $.validate(field)

  $(".field-with-validation.invalid").focus (e) ->
    $.hide_iqa_validation_messages()
    $(e.target).tipsy('show')

  $(".field-with-validation").keydown (e) ->
    # Allow keys to be pressed if the command/control key is down (so they can reload the page, open a new tab, etc)
    if e.ctrlKey || e.metaKey
      return true

    field = $(e.target)

    rules = $.validation_rules_for_field(field)
    return true if rules.length == 0
    ruleset = rules[0]

    if ruleset.format? && ruleset.format == 'float'
      # Allow a decimal point only if there is not already one
      if ((field.val().indexOf(".") >= 0) && (e.keyCode == 190))
        return false

      # Allow only delete, backspace, tab, enter, period, arrow keys, numbers 0-9, and the numbers 0-9 on the right hand keypad, decimal point, and comma
       valid_codes = [8,46,9,13,190,37,38,39,40,48,49,50,51,52,53,54,55,56,57,96,97,98,99,100,101,102,103,104,105,110,188]
       if ($.inArray(e.keyCode, valid_codes) == -1)
         return false
       else
         return true

    if ruleset.format? && ruleset.format == 'integer'
      # Allow only delete, backspace, tab, enter, arrow keys, numbers 0-9, and the numbers 0-9 on the right hand keypad
      valid_codes = [8,46,9,13,37,38,39,40,48,49,50,51,52,53,54,55,56,57,96,97,98,99,100,101,102,103,104,105]
      if ($.inArray(e.keyCode, valid_codes) == -1)
        return false
      else
        return true

  errors_fields: ->
    $(".field-with-validation").filter ->
      $.before_validation($(@))
      not $.validate($(@))
