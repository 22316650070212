window.Modals =
  modals: {}
  defaultModalSettings:
    autoOpen: false
    modal: true
    height: 600
    width: 750
  newItemModalSettings:
    autoOpen: false
    modal: true
    height: 200
    width: 750
  cryoModalSettings:
    autoOpen: false
    modal: true
    width: 750
    closeText: ''

  initCryoModal: ->
    @modals.cryoModal = $("#cryo_modal")
    @modals.cryoModal.dialog @cryoModalSettings

  initReagentModal: ->
    @modals.reagentModal = $("#reagent_modal")
    @modals.reagentModal.dialog @defaultModalSettings

  initItemFromKitModal: ->
    @modals.itemFromKitModal = $("#item_from_kit_modal")
    @modals.itemFromKitModal.dialog @defaultModalSettings

  initOrdersModal: ->
    @modals.ordersModal = $("#orders_modal")
    @modals.ordersModal.dialog @defaultModalSettings

  initSpecimenModal: ->
    @modals.specimenModal = $("#specimen_modal")
    specimenModalSettings = $.extend {
      open: ->
        $(this).find("form").submit()
    }, @defaultModalSettings
    @modals.specimenModal.dialog specimenModalSettings

  initNewItemModal: ->
    @modals.newItemModal = $("#new-inventory-item-modal")
    @modals.newItemModal.dialog @newItemModalSettings

  initItemConcentrationModal: ->
    @modals.itemConcentrationModal = $('#item-concentration-dilution-modal')
    @modals.itemConcentrationModal.dialog @newItemModalSettings

  initPlateInfoModal: ->
    @modals.plateInfoModal = $('#plate-info-modal')
    @modals.plateInfoModal.dialog @newItemModalSettings

  initEditAssayCommentModal: ->
    @modals.editAssayCommentModal = $('#edit-assay-comment-modal')
    @modals.editAssayCommentModal.dialog @newItemModalSettings

  showReagentModal: (e) ->
    e.preventDefault()

    unless Modals.modals.reagentModal
      Modals.initReagentModal()

    populateBridgingResults Modals.modals.reagentModal, []
    Modals.modals.reagentModal.dialog 'open'

    fields = [
      '#reagent_modal [data-filter="name"]'
      '#reagent_modal [data-filter="lot-number"]'
    ]
    $(fields.join(', ')).on "keyup", (ev) ->
      searchFields = ["name", "lot_number"]
      populateBridgingResults Modals.modals.reagentModal, searchFields

  showCryoModal: (e) ->
    e.preventDefault()

    unless Modals.modals.cryoModal
      Modals.initCryoModal()

    Modals.modals.cryoModal.dialog 'open'

  showItemFromKitModal: (e) ->
    e.preventDefault()

    unless Modals.modals.itemFromKitModal
      Modals.initItemFromKitModal()

    populateBridgingResults(Modals.modals.itemFromKitModal, [])
    Modals.modals.itemFromKitModal.dialog 'open'

    $('#kit_name, #part_name').on "keyup", (e) ->
      searchFields = ["kit_name", "part_name", "part_type"]
      populateBridgingResults(Modals.modals.itemFromKitModal, searchFields )

    $('#part_type').on "change", (e) ->
      searchFields = ["kit_name", "part_name", "part_type"]
      populateBridgingResults(Modals.modals.itemFromKitModal, searchFields )

  showOrdersModal: (e) ->
    e.preventDefault()

    unless Modals.modals.ordersModal
      Modals.initOrdersModal()

    Modals.modals.ordersModal.dialog 'open'

  showSpecimenModal: (e) ->
    e.preventDefault()

    unless Modals.modals.specimenModal
      Modals.initSpecimenModal()

    Modals.modals.specimenModal.dialog 'open'

  showNewItemModal: (e) ->
    e.preventDefault()

    unless Modals.modals.newItemModal
      Modals.initNewItemModal()

    Modals.modals.newItemModal.dialog 'open'

  showItemConcentrationModal: ($row) ->

    unless Modals.modals.itemConcentrationModal
      Modals.initItemConcentrationModal()

    Modals.modals.itemConcentrationModal.dialog 'open'

    $('#item-concentration-dilution-modal').data 'row', $row

  showPlateInfoModal: ($plate, action) ->

    unless Modals.modals.plateInfoModal
      Modals.initPlateInfoModal()

    Modals.modals.plateInfoModal.dialog 'open'

    $modal = $('#plate-info-modal')
    $modal.data 'plate', $plate
    $modal.data 'action', action

  resetPlateInfoData: ->
    $modal = $('#plate-info-modal')
    $modal.data 'plate', null
    $modal.data 'action', null
    $modal.data 'originalPlate', null

  showEditAssayCommentModal: ->
    unless Modals.modals.EditAssayCommentModal
      Modals.initEditAssayCommentModal()

    Modals.modals.editAssayCommentModal.dialog 'open'

  hideItemConcentrationModal: ->
    if Modals.modals.itemConcentrationModal
      Modals.modals.itemConcentrationModal.dialog 'close'

  hidePlateInfoModal: ->
    if Modals.modals.plateInfoModal
      Modals.modals.plateInfoModal.dialog 'close'

  hideEditAssayCommentModal: ->
    if Modals.modals.editAssayCommentModal
      Modals.modals.editAssayCommentModal.dialog 'close'

$(document).ready ->
## Listeners ##
  $('#add_reagent_link').on "click", Modals.showReagentModal
  $('#add_item_from_kit_link').on "click", Modals.showItemFromKitModal
  $("#add_item_from_orders_link").on "click", Modals.showOrdersModal
  $('#add_specimen_link').on "click", Modals.showSpecimenModal
  $('[data-cryo-modal]').on "click", Modals.showCryoModal

  $(document).on "change", "[data-cryo-select-all]", (e) ->
    checkbox = $(e.currentTarget)
    if checkbox.is(":checked")
      $("[data-cryo-checkbox]").prop("checked", true)
    else
      $("[data-cryo-checkbox]").prop("checked", false)


  $(document).on "click", ".add_reagent_link", (e) ->
    e.preventDefault()
    link = $(e.currentTarget)
    resultId = link.closest('tr').attr('id')
    sectionType = link.attr('id') # "new", "old", or "test"
    resultName = link.closest('tr').find('.result_name').html()
    if not resultType = link.closest('tr').children('#part_type').html()
      resultType = 'Lot'
    insertReagentIntoForm(sectionType, resultId, resultName, resultType)

  $(document).on "click", ".add_specimen_link", (e) ->
    e.preventDefault()
    link = $(e.currentTarget)
    resultId = link.closest('div.reagent-fields').children('#specimen_id').html()
    sectionType = link.attr('id') # "new", "old", or "test"
    resultName = link.closest('div.reagent-fields').children('#result_name').html()
    resultType = 'Specimen'
    insertReagentIntoForm(sectionType, resultId, resultName, resultType)


  $(document).on "click", ".add_item_from_order_link", (e) ->
    e.preventDefault()
    link = $(e.currentTarget)
    reagentId = link.closest('div.reagent-fields').data('reagent-id')
    section = link.attr('id') # "new", "old", or "test"
    reagentName = link.closest('div.reagent-fields').data('reagent-name')
    reagentType = link.closest('div.reagent-fields').data('reagent-type')
    insertReagentIntoForm(section, reagentId, reagentName, reagentType)

## Functions ##

populateBridgingResults = (reagentSection, searchFields) ->
  clearBridgingResults(reagentSection)
  hiddenResults = reagentSection.children("#hidden_info").val()
  results = JSON.parse(hiddenResults)
  for result in results
    if resultMatches(result, searchFields, reagentSection)
      resultRow = resultRowHtml(result, reagentSection)
      reagentSection.find("#results").find("tbody").append(resultRow)

clearBridgingResults = (reagentSection) ->
  reagentSection.find("#results").find("tbody").children().not("#header_row").remove()

resultMatches = (result, searchFields, reagentSection) ->
  matches = true
  for searchField in searchFields
    if not filterMatch(result[searchField], reagentSection.find("##{searchField}").val())
      matches = false
  matches

resultRowHtml = (result, reagentSection) ->
  rowHtml = "<tr id='#{result.id}'>"
  buttonClasses = ""
  if (reagentSection.attr("id") == "item_from_kit_modal")
    rowHtml += "<td>#{result.kit_name}</td>"
    rowHtml += "<td id='part_type' data-part-type='#{result.part_type}'>#{result.part_type}</td>"
    rowHtml += "<td class='result_name' data-result-name='#{result.part_name}'>#{result.part_name}</td>"
    rowHtml += "<input type='hidden' data-number-identifier value=#{result.part_number_identifier}>"
    buttonClasses += "add_from_kit_link"
  else
    rowHtml += "<td class='result_name' data-result-name='#{result.name}'>#{result.name}</td>"
    rowHtml += "<td data-lot-number='#{result.lot_number}'>#{result.lot_number}</td>"
  rowHtml += "<td>"
  rowHtml += "<a href='#' id='old' class='add_reagent_link add-link #{buttonClasses}'>Old</a>"
  rowHtml += "<a href='#' id='new' class='add_reagent_link add-link #{buttonClasses}'>New</a>"
  rowHtml += "<a href='#' id='test' class='add_reagent_link add-link #{buttonClasses}'>Test</a>"
  rowHtml += "</td></tr>"
  rowHtml

filterMatch = (objString, filterString) ->
  patt = new RegExp(filterString, "gi");
  patt.test(objString)

# This will add a qualifying study reagent into either new, old, or test.
insertReagentIntoForm = (section, reagentId, reagentName, reagentType) ->
  # reagentType = "Lot" #if section == "test" then "Specimen" else "Lot"

  # Copy template form and adjust as necessary
  template = $("##{section}_qualifying_study_reagents .new-row:last").clone()
  template.find("input.reagent_id").val(reagentId)
  template.find("input.reagent_type").val(reagentType)
  template.find(".reagent_name").html(reagentName)
  template.find('input.destroy_field').val(false)
  index = $("##{section}_qualifying_study_reagents").find("div.reagent-fields").length
  template.attr("id", "#{index}")
  template.adjust_rails_form_fields(index)
  $("##{section}_qualifying_study_reagents").append(template)
  template.show()
