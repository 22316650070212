$(document).ready(function(){
	if($("ul.dropdown").length) {
		$("ul.dropdown li").dropdown();
	}
});

$.fn.dropdown = function() {
	return this.each(function() {
		$(this).hover(function(){
			clearTimeout($(this).data('timeout'));
			$(this).addClass("hover");
			$('> .has-nested',this).addClass("open");
			$('ul:first',this).css('visibility', 'visible');
		},function(){
			var _this = this;
			var t = setTimeout(function() {
				$(_this).removeClass("hover");
				$('.open',_this).removeClass("open");
				$('ul:first',_this).css('visibility', 'hidden');
			}, 100);
			$(this).data('timeout', t);
		});
	});
};
