$ ->
  window.ScorecardEditForm =
    createEvents: ->
      @createPublishEvent()
      @createRecalculatePointsEvent()
      @createNoDataEvent()

    createPublishEvent: ->
      $('#enter_ep_site_scores input#publish').click (e) ->
        if ScorecardEditForm.validateSiteScores()
          $('#published').val(true)
        else
          e.preventDefault()

    createRecalculatePointsEvent: ->
      $("#enter_ep_site_scores .target_evaluations").on "keyup",  (e) =>
        @recalculatePoints(e)

    createNoDataEvent: ->
      $('input[data-pte=no-data]:checked').each (i, e) =>
        @toggleNoData(e)
      $('input[data-pte=no-data]').click (e) =>
        @toggleNoData(e.currentTarget)

    # Calculates point value for each enrollment based on "# Evals" input.
    recalculatePoints: (event) ->
      evaluation_field = event.target
      row = $(evaluation_field).closest("tr")
      maximumPoints = row.data("max-points")
      evaluations = row.data("max-evaluations")
      earnedEvaluations = parseInt($(evaluation_field).val())
      if isNaN(earnedEvaluations)
        $(evaluation_field).parent().next(".target_score").find('input').val("")
      else
        score = ((earnedEvaluations / evaluations ) * maximumPoints).toFixed(2)
        $(evaluation_field).parent().next(".target_score").find('input').val(score)

    # Validates the ep site score form when submitted.  Adds validation errors for the following:
    # * Inputs that are not numeric
    # * Inputs less than 0
    # * Inputs greater than max # of Evals for target.
    #
    validateSiteScores: ->
      error_fields = []
      $(".target_evaluations:visible").each () ->
        maxEvaluations = parseInt($(@).closest("tr").data("max-evaluations"))
        earnedEvaluations = parseInt($(@).val())
        if isNaN(earnedEvaluations) || earnedEvaluations > maxEvaluations || earnedEvaluations < 0
          error_fields.push($(@))
          $(@).addClass("validation_error")
        else
          $(@).removeClass("validation_error")
      if(error_fields.length == 0)
        return true
      else
        alert("# Evals must be between 0 and the maximum # Evals for the target.")
        error_fields[0].focus()
        return false

    toggleNoData: (checkbox) ->
      columnIndex     = (checkbox.offsetParent.cellIndex * 2)
      $scorecardTable = $(checkbox).parents('table')

      $numberOfEvals  = $($scorecardTable.find('tr').map (idx, elt) ->
        $(elt).find('td')[columnIndex]).find('input')
      $points         = $($scorecardTable.find('tr').map (idx, elt) ->
        $(elt).find('td')[columnIndex + 1]).find('input')

      $numberOfEvals.toggle()
      $points.toggle()

  window.ScorecardEditForm.createEvents()

  # Sets the height for the scorecard results rows to match the height of the corresponding table key rows.
  for row in $(".scorecard_result_table tr")
    row = $(row)
    if row.attr?("class")
      col = $("td." + row.attr("class"))
      colHeight = col.outerHeight(true)
      row.css(height: colHeight)

  # Prepares the top scrollbar for site scores table section.
  $(".scorecard-top-scroller div").css(width: $(".scorecard_result_table table").outerWidth(true))
  $(".scorecard-top-scroller").scroll ->
    $(".scorecard_result_table").scrollLeft $(".scorecard-top-scroller").scrollLeft()
  $(".scorecard_result_table").scroll ->
    $(".scorecard-top-scroller").scrollLeft $(".scorecard_result_table").scrollLeft()

  # Calculates the Point Value field when Maximum Points and Evaluations are filled in on the Edit Grading Criteria form.
  $(".edit_scorecard .maximum_points, .edit_scorecard .number_of_evaluations").on "keyup", (e) ->
    fieldset = $(this).closest("fieldset")
    maxPoints = parseFloat fieldset.find(".maximum_points").val()
    numberOfEvaluations = parseFloat fieldset.find(".number_of_evaluations").val()
    pointValue = fieldset.find(".point-value")
    if maxPoints && numberOfEvaluations
      pointValue.val((maxPoints / numberOfEvaluations).toFixed(3))
    else
      pointValue.val("")

  # Validates the grading criteria form when submitted.
  # * Adds validation error when maximum point input total is not a number.
  # * Adds validation error when maximum point input total is not between 0 and 100.
  # * Adds validation warning when maximum point input total does not equal 100.
  $(".edit_scorecard").submit ->
    sum = 0
    $(".maximum_points:visible").each () ->
      sum += parseFloat($(this).val())
    if isNaN(sum)
      alert("Error: The grading criteria has an improper value for maximum points. The total points must be between 0 and 100.")
      return false
    if sum < 0 || sum > 100
      alert("Error: The grading criteria has a total of " + sum + " maximum points. The total points must be between 0 and 100.")
      return false
    else if sum != 100
      alert("Warning: The grading criteria has a total of " + sum + " maximum points. It should have exactly 100 points, and cannot have more than 100.0 points.")
      return true
    else
      return true


  # Validates grading level form.  Ensure that lower bound values are between 0 and 100.
  $(".edit_grading_levels").submit ->
    error_fields = []
    $(".lower_bound:not([readonly])").each () ->
      this_value = parseFloat($(this).val())
      if(this_value > 100) || (this_value <= 0)
        error_fields.push($(this))
        $(this).addClass("validation_error")
      else
        $(this).removeClass("validation_error")

    if(error_fields.length == 0)
      return true
    else
      alert("Some scores are outside of the allowed range. All scores must be between 1 and 100.")
      error_fields[0].focus()
      return false

  # Sets the upper bound value based on the lower bound value.
  set_upper_bound = (field) ->
    lower_bound_field = $(field)
    previous_row = lower_bound_field.closest(".fields").prevAll(".fields:visible").first()
    if previous_row
      lower_bound = parseFloat(lower_bound_field.val())
      previous_row.find(".upper_bound").val(lower_bound - 1)

  # Sets the upper bound when a lower bound field is changed.
  $(".edit_grading_levels").on "change", ".lower_bound", (_event) ->
    set_upper_bound($(this))

  # Refreshes the upper bound values.
  # * Calls set_upper_bound on each grading level.
  # * Sets the last upper bound value to 100.
  refresh_upper_bounds = ->
    $(".edit_grading_levels .lower_bound:visible").each () ->
      set_upper_bound($(this))
    $("#user_grade_levels").find(".fields:visible").last().find(".upper_bound").val("100")

  # Refresh the upper bound values if a grading level removed.
  $(".edit_grading_levels").on('nested:fieldRemoved', (event) ->
    setTimeout(refresh_upper_bounds, 200)
  )

  # Refresh the upper bound values on page load.
  refresh_upper_bounds()
