$ ->
  window.QualifyingStudiesReport =

    initialize: ->
      @bindEvents()
      @activateSelect2()

    bindEvents: ->
      @bindResetSearchFieldsButton()

    bindResetSearchFieldsButton: ->
      $("[data-reset-search-fields]").on 'click', (event) ->
        event.preventDefault()
        $searchForm = $(event.target).closest('[data-search-form]')

        $fields = $searchForm.find('[data-search-form-field]')
        $fields.val("")
        $fields.attr("values", "")

        $select2Fields = $searchForm.find('[data-select2-selector]')
        $select2Fields.select2('data', null)

    activateSelect2: ->
      $('[data-qualifying-studies-report]').find('[data-select2-selector]').select2(
        width: 'resolve',
        allowClear: true
      )

  window.QualifyingStudiesReport.initialize() if $('[data-qualifying-studies-report]').length > 0
