# Set parser to MomentJS. Default is php-date-formatter which is buggy.
# https://github.com/xdan/datetimepicker/issues/495
#
# Documentation for the datepicker library:
# https://github.com/xdan/datetimepicker
# http://xdsoft.net/jqplugins/datetimepicker/
#
# The library's javascript is in
# vendor/assets/javascripts/jquery.datetimepicker.full.js

window.dateTimeFormatter =
  parseDate: (date, format) ->
    d = moment(date, format)
    if d.isValid() then d.toDate() else false
  formatDate: (date, format) ->
    moment(date).format(format)

window.dateTimeFormat = 'MM/DD/YYYY hh:mm A'

$.datetimepicker.setDateFormatter window.dateTimeFormatter

$.add_datepickers = () ->
  $(".datepicker").datetimepicker
    timepicker: false
    defaultSelect: true
    format: 'MM/DD/YYYY'
    formatDate:'MM/DD/YYYY'
    scrollInput: false
    scrollMonth: false
    scrollTime: false
    closeOnDateSelect: true

  $(".datetimepicker").datetimepicker
    scrollInput: false
    scrollMonth: false
    scrollTime: false
    defaultSelect: true
    format: window.dateTimeFormat
    formatTime: 'hh:mm A'
    step: 10
    closeOnDateSelect: true
    onClose: (_currentTime, input) ->
      $inputField = $(input)
      $(this).val('') if $inputField.hasClass('ui-datepicker-close')

$ ->
  $('#complex-form').on "elementAdded", '.add-fields', ->
    $.add_datepickers()
