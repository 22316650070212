$ ->
  do initializeTabs = ->

    # Set up jQuery tabs for results
    resultsTabs = $('#results-tabs')
    resultsTabs.tabs()

    do initializeMainTabs = ->
      $('#linearity').tabs()

    do setUpLinks = ->
      $('#linearity a[href="#replicate2"]').click ->
        resultsTabs.tabs('option', 'active', 2)
      $('#linearity a[href="#replicate1"]').click ->
        resultsTabs.tabs('option', 'active', 1)


  do initializeCheckboxes = ->
    toggleCheckboxesInRow = ->
      checkboxElement = $(@)

      thisRow = checkboxElement.closest('tr')
      thisRowType = thisRow.data('row-type')

      form = checkboxElement.closest('form')
      allRows = form.find("tr[data-row-type='#{thisRowType}']")
      allBlankRows = allRows.filter ->
        uncheckedBoxes = $(@).find('input[type=checkbox]').not(checkboxElement).filter ->
          $(@).prop('checked')
        uncheckedBoxes.length == 0
      otherCheckboxes = allBlankRows.find('input[type=checkbox]').not(checkboxElement)
      if checkboxElement.prop('checked')
        otherCheckboxes.prop('checked', true)
      else
        otherCheckboxes.prop('checked', false)
    # Removed per conversation with Justin 12/11/13
    #first_checkbox_in_row = $(".linearity-replicate tr").find(".premixed_mab:first")
    #not_first_checkbox_in_row = $(".linearity-replicate tr").find(".premixed_mab").not(first_checkbox_in_row)
    #not_first_checkbox_in_row.addClass('disabled')
    #not_first_checkbox_in_row.click (event) ->
    #  event.stopPropagation()
    #  event.preventDefault()
    #  return false
    $('.linearity-replicate tr').find(".premixed_mab:first").click(toggleCheckboxesInRow)


  do initializeSelects = ->
    toggleOtherSelects = ->
      selectElement = $(@)
      selectedValue = $(@).val() # I don't think this is the correct way to get the value?

      thisRow = selectElement.closest('tr')
      thisRowType = thisRow.data('row-type')

      form = selectElement.closest('form')
      allRows = form.find("tr[data-row-type='#{thisRowType}']")
      allBlankRows = allRows.filter ->
        filledSelects = $(@).find('select').not(selectElement).filter ->
          $(@).val() != ''
        filledSelects.length == 0

      otherSelects = allBlankRows.find('select').not(selectElement)

      otherSelects.val(selectedValue)

    $('.linearity-replicate select').change(toggleOtherSelects)


  do initializeSectionVisibility = ->
    if gon?
      do hideSections = ->
        hide = (el) ->
          $(el).hide()
          $(el).removeClass('warn-on-empty')
        hide el for el in gon.all_mapped_elements

      do showSections = ->
        # It would be nice to call $(@).val() instead, but `this` is undefined when
        # this is a self-executing function.
        platform = $('#platform').val()
        elementsToShow = gon.platform_mapping[platform]

        show = (el) ->
          $(el).show()
          $(el).addClass('warn-on-empty')
        show el for el in elementsToShow if elementsToShow?

      $('#platform').change(hideSections)
      $('#platform').change(showSections)

  do initializeSiteSwitcher = ->
    $('#site-switcher').change ->
      siteSwitcher = $(this)
      siteId = siteSwitcher.val()
      window.location.href = '/linearity/sites/' + siteId


  do initializeSubmit = ->
    do bindSubmitTypeListener = ->
      buttons = $('#linearity-result').find('input[type=submit]')
      buttons.click ->
        submitType = $(@).data('submit-type')
        $('#submit-type').val(submitType)

    warnOfInvalidData = ->
      valid = true
      $(".field-with-validation:visible").each ->
        $.before_validation($(@))
        if !$.validate($(this))
          valid = false
      alert 'The results contain errors. Please review entries before saving.' unless valid
      valid

    warnOfEmptyFields = ->

      emptyFields = ->
        platform = $('#platform').val()
        selectors = gon.platform_mapping[platform] || gon.all_mapped_elements
        filters = ['.warn-on-empty input[type=text]', '.warn-on-empty select']
        allEmptyEntries(selectors, filters)

      fieldCount = ->
        emptyFields().length

      anyEmptyFields = ->
        fieldCount() > 0

      tabs_missing_data = emptyFields().map (index, field) ->
        tab_id = $(field).closest(".linearity_results_tab").attr('id')
        $(".ui-tabs-nav li a[href='#"+tab_id+"']").first().html()
      tabs_missing_data = unique(tabs_missing_data.toArray())

      if anyEmptyFields()
        return confirm '' + fieldCount() + ' fields are blank in the following sections: ' + tabs_missing_data.join(", ") + '. Are you sure you want to submit this data?'
      else
        return true

    $('#linearity-result').submit ->
      formatPercentages()
      unless $('#submit-type').val() is 'draft'
        return warnOfInvalidData() and warnOfEmptyFields() and confirm "Are you ready to submit your results? Once submitted, results cannot be changed."


  do formatPercentages = ->
    $('.percentage').each -> $.convert_number_to_float($(@), 2)

  do disableFormIfNecessary = ->
    if gon?
      if gon.enable_form? and gon.enable_form is false
        $.disable_form()

  $.extend($.validations, {
    integer: $.standard_integer,
    percentage: $.extended_float,
    absolute: $.medium_string
  })
