jQuery.fn.showOption = (show) ->
  jQuery(this).toggle show
  if show
    jQuery(this).unwrap()  if jQuery(this).parent("span.toggleOption").length
  else
    jQuery(this).wrap "<span class=\"toggleOption\" style=\"display: none;\" />"

$ ->
  (->
    remove_selected_options_from_siblings = (element) ->
      # Loop through all selects that aren't this one, and disable value option and enable previous.
      siblingSelects = element.closest(".single-serve-options").find("select").not(element)
      previous = element.data('previous-value')
      value = element.find("option:selected").attr("value")

      element.data('previous-value', value)
      
      for sibling in siblingSelects
        sibling = $(sibling)
        sid = sibling.attr('id')
        
        if value != ''
          sibling.find("option").filter("[value=#{value}]").showOption(false)
        if previous != '' && previous != value
          sibling.find("option").filter("[value=#{previous}]").showOption(true)
    
    $(".single-serve-options select").each( ->
      $(@).data('previous-value', $(@).find("option:selected").attr("value")) # Initalize the 'previous-value' for each select
    ).each( ->
      remove_selected_options_from_siblings($(@)) # Hide/Show the appropriate options on page load
    ).change ->
      remove_selected_options_from_siblings($(@)) # Hide/Show the appropriate options on select change
  )()
  
$ ->
  toggleSelectionWhenChecked = (checkbox, selector) ->
    if checkbox.is(":checked")
      $(selector).show()
    else
      $(selector).hide()
  
  invalidateCheckbox = $("#linearity_panel_invalidated")
  toggleSelectionWhenChecked(invalidateCheckbox, "#reason-for-invalidation")
  invalidateCheckbox .change ->
    toggleSelectionWhenChecked($(this), "#reason-for-invalidation")