$ ->
  # Button to add new conversation

  $("[data-type='new-conversation-form']").hide()

  $("[data-action='show-new-conversation-form']").click (e) ->
    e.preventDefault()
    $("[data-type='new-conversation-form']").show()
    $(e.target).hide()


  $("[data-action='flag-conversation']").on "click", (e) ->
    e.preventDefault()
    self = $(e.target)
    parent = self.parent()
    $.get self.attr("href"), {}, ->
      if(self.text() == "Flag Conversation")
        parent.closest("[data-type='conversation']").addClass("flagged")
        self.siblings("[data-attribute='conversation-title']").before("<img src='/images/flag.png' class='flag' />")
        self.text("Remove Flag")
      else
        parent.closest("[data-type='conversation']").removeClass("flagged")
        self.siblings('.flag').remove()
        self.text("Flag Conversation")

  $("[data-action='expand-comments']").click (e) ->
    hiddenComments = (e) -> $(e.target).parent().siblings('.hidden-comment')

    if $(e.target).text() != "View all comments"
      e.preventDefault()
      chunk = $(e.target).data('chunk')
      hiddenComments(e).slice(0,chunk).removeClass('hidden-comment')
      unless hiddenComments(e).length
        if $(e.target).data('limited')
          $(e.target).attr('href', $(e.target).data('link'))
          $(e.target).attr('data-action', 'show-all-comments')
          $(e.target).text("View all comments")
        else
          $(e.target).hide()

  # Prompt user about discarded changes and form errors

  $("[data-action='leave-conversation']").click (e) ->
    if $("[data-attribute='new-note']").val() && !confirm('Discard comment?')
      e.preventDefault()
      false

  $("form :input").change ->
    $(this).closest('form').data('changed', true)

  $("[data-action='back-to-conversation']").click (e) ->
    if $("[data-attribute='edit-conversation']").data('changed') && !confirm('Discard changes?')
      e.preventDefault()
      false

  # Highlight the note if anchor tag is present

  if id = window.location.hash.match(/^#?note_\d+$/)
    $anchor = $('body').find(window.location.hash).next('.note')
    $anchor.addClass('anchored')
    fromTop = $anchor.position().top
    $('html,body').animate { scrollTop: fromTop }, 1
