$(document).ready ->
  window.tempGroupStates = JSON.parse($("[data_add_shipment_btn]").attr('data_toggle_states')) if $("[data_add_shipment_btn]").length > 0

  $(".add-all-to-shipment-cell input[type=checkbox]").change ->
    parent_id = $(this).closest("tr").attr("id")
    $(".#{parent_id} input").not(":disabled").prop("checked", this.checked)

  $(".really-add-all-to-shipment").change ->
    $(this).closest("table").find("input").not(":disabled").prop("checked", this.checked)

  # Store shipping issues value in data 'original' onload
  $("[data_add_shipment_btn]").click ->
    desiredTemperature = $(this).siblings('[data_temperature_selection]').val()
    return unless desiredTemperature
    window.tempGroupStates[desiredTemperature] = !window.tempGroupStates[desiredTemperature]
    $('.order-review-items tbody tr').each( ->
      return unless $(this).find('[data-temperature]').text() == desiredTemperature
      $checkboxes = $(this).find("input[type=checkbox]").not(':disabled')
      $checkboxes.prop("checked", window.tempGroupStates[desiredTemperature])
    )

  # Store shipping issues value in data 'original' onload
  $("#shipping_issues").data "original", $("#shipping_issues").val()
  $("#any_shipping_issues").change()

  $("#any_shipping_issues").on "change", (e) ->
    e.preventDefault()

    # If user selects that this Shipment has shipping issues, then load the shipping issues from data 'original'
    if $("#any_shipping_issues").val() is "Yes"
      $("#shipping_issues").val $("#shipping_issues").data("original")
      $("#shipping_issues_container").show()
    else
      # If user selects that this Shipment has no shipping issues, then store shipping issues in data 'original'
      # and clear out the shipping issues field
      $("#shipping_issues_container").hide()
      $("#shipping_issues").data "original", $("#shipping_issues").val()
      $("#shipping_issues").val ""
