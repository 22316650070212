$(document).ready ->
  class ReagentVolumes
    constructor: ->
      @reagents = $.parseJSON($('#reagent_volumes').val()) if $('#reagent_volumes').length > 0
      @overOrderWarning = $('#over-aliquoted')
    getAvailableQuantity = ->
      parseInt($("#order_reagent_available_quantity").text())
    setAvailableQuantity = (availableQuantity) ->
      $("#order_reagent_available_quantity").text(availableQuantity)
    setId = (id) ->
      $("#reagent-volumes-id").val(id)
    updateQuantity: ->
      id = $("#reagent_volume_id").val()
      setAvailableQuantity(@reagents[id])
    updateId: ->
      id = $("#reagent_volume_id").val()
      setId(id)
    getRequestedQuantity = ->
      parseInt($("#order_item_quantity").val())
    checkForOverOrder: =>
      if getAvailableQuantity() < getRequestedQuantity()
        @overOrderWarning.fadeIn()
        return true
      else
        @overOrderWarning.fadeOut()
        return false

  reagent_volumes = new ReagentVolumes()


  ## Event Handlers
  $('#reagent_volume_id').on "change",  (e) ->
    reagent_volumes.updateQuantity()
    reagent_volumes.updateId()
    reagent_volumes.checkForOverOrder()

  $('#order_item_quantity').on "keyup",  (e) ->
    reagent_volumes.checkForOverOrder()

  $(".order_reagents_form").submit ->
    not reagent_volumes.checkForOverOrder()
