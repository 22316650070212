var Rowclick = function() {
  var obj = {};


  obj.init = function( element, options ) {
      obj.element = element;
      if(typeof options == "undefined") { options = []; }
      var defaults = {
        on_click: obj.on_click,
        row_click: obj.row_click,
        link_class: "rowclick",
        hide_links: false,
        allow_links: false
      };
      obj.options = jQuery(options).extend(defaults);

      if(obj.options.hide_links) {
        obj.element.find("a."+obj.options.link_class).each(function(index, link) {
          jQuery(link).hide();
        });
      }

      var that = obj;
      obj.element.bind('click', function(event) {
        that.options.on_click(event);
      });
  };


  obj.on_click = function(event) {
    // Don't do row click if the user clicked on a link in the row.
    if((!obj.options.allow_links) && (event.target.nodeName == "A" || event.target.nodeName == "INPUT")) {
      return false;
    }

    var target = jQuery(event.target);
    var row = target.closest("tr");
    obj.row_click(row);
  };

  obj.row_click = function(row) {
    var link = row.find("a."+obj.options.link_class).first();
    if((link) && (link != [])) {
      this.click_link(link.get(0));
      //window.location.href = link.attr('href');
    }
  };

  obj.click_link = function(link) {
      var cancelled = false;

      if (document.createEvent) {
          var event = document.createEvent("MouseEvents");
          event.initMouseEvent("click", true, true, window,
              0, 0, 0, 0, 0,
              false, false, false, false,
              0, null);
          cancelled = !link.dispatchEvent(event);
      }
      else if (link.fireEvent) {
          cancelled = !link.fireEvent("onclick");
      }

      if (!cancelled) {
          window.location = link.href;
      }
  };

  return obj;
};


// JQueryize it
(function($){
	$.fn.jrowclick = function(options) {
		 return this.each(function() {
		   var element = $(this);
       var row_click = new Rowclick();
       row_click.init(element, options);
		 });
	};
})(jQuery);


// Helper method to allow extending/merging objects
// Object.prototype.extend = function(destination) {
//    for (var property in this) {
//        if (this.hasOwnProperty(property)) {
//            destination[property] = this[property];
//        }
//    }
//    return destination;
// };
