$ ->
  #### SAVED SAMPLE TAB CONTROL VARIABLES AND FUNCTIONS ####
  sample_tab = (tab_number) ->
    $("[data-iqa-sample-#{tab_number}]")

  set_sample_tab_active_status = (tab_number, active_status) ->
    $("#proficiency_test_enrollment_cryo_samples_attributes_#{tab_number - 1}_active").val(active_status)

  sample_tab_close_button = (tab_number) ->
    $("[data-iqa-sample-#{tab_number}-close]")

  hide_sample_tab = (tab_number) ->
    sample_tab(tab_number).hide()
    set_sample_tab_active_status(tab_number, false)

  show_sample_tab = (tab_number) ->
    sample_tab(tab_number).show()
    set_sample_tab_active_status(tab_number, true)
    $.setPersistedSubtab(samplesSubtabsKey, tab_number)

  skip_validations_for_sample_tab = (tab_number) ->
    $("#samples_#{tab_number}").data('skip-validations', '') # Any defined value will work here

  show_samples_page = ->
    $("#site-data-cryo-tabs").tabs('option', 'active', 1)
    $("#iqa-cryo-samples-tabs").tabs('option', 'active', 1)

  handle_remove_sample_tab = (e, tab_number) ->
    e.preventDefault()
    hide_sample_tab(tab_number)
    skip_validations_for_sample_tab(tab_number)
    # Default to tab 2 after this tab is removed
    show_sample_tab(2)
    show_samples_page()

  default_sample_page_setup = ->
    hide_sample_tab(3)
    hide_sample_tab(4)

  set_up_page = ->
    default_sample_page_setup()
    # TODO: handle more cases

  #### END SAMPLE TAB CONTROL VARIABLES AND FUNCTIONS ####

  #### RUN SAMPLE TAB CONTROL FUNCTIONS ####
  set_up_page()

  sample_tab_close_button(3).on "click", (e)->
    handle_remove_sample_tab(e, 3)

  sample_tab_close_button(4).on "click", (e)->
    handle_remove_sample_tab(e, 4)
  #### END SAMPLE TAB CONTROL FUNCTIONS ####

  #### DYNAMIC DROPDOWN MENU CONTROLS ####
  $("[data-anticoagulant]").each (idx, element)->
    $("#anticoagulant_select-" + idx).on "change", ()->
      if ($("#anticoagulant_select-" + idx).val() == 'Other')
        $("[data-specify-anticoagulant-" + idx + "]").show()
      else
        $("[data-specify-anticoagulant-" + idx + "]").hide()

  $("[data-viability]").each (idx, element)->
    $("#viability_select-" + idx).on "change", ()->
      if ($("#viability_select-" + idx).val() == 'Other')
        $("[data-specify-viability-" + idx + "]").show()
      else
        $("[data-specify-viability-" + idx + "]").hide()

  $("[data-cell-counting]").each (idx, element)->
    $("#cell_counting_method_main_used-" + idx).on "change", ()->
      if ($("#cell_counting_method_main_used-" + idx).val() == 'Manual')
        $("[data-cell-counting-manual-options-" + idx + "]").show()
        $("[data-cell-counting-auto-machines-" + idx + "]").hide()
      else if ($("#cell_counting_method_main_used-" + idx).val() == 'Automated')
        $("[data-cell-counting-auto-machines-" + idx + "]").show()
        $("[data-cell-counting-manual-options-" + idx + "]").hide()
      else
        $("[data-cell-counting-manual-options-" + idx + "]").hide()
        $("[data-cell-counting-auto-machines-" + idx + "]").hide()
      adjust_cell_counting_other_input_visibility(idx)

    $("#cell_counting_auto_method-" + idx).on "change", ()->
      adjust_cell_counting_other_input_visibility(idx)

  adjust_cell_counting_other_input_visibility = (idx)->
    parent_input = $("#cell_counting_auto_method-" + idx)
    if (parent_input.val() == 'Other' && parent_input.is(':visible'))
      $("[data-cell-counting-auto-options-other-" + idx + "]").show()
    else
      $("[data-cell-counting-auto-options-other-" + idx + "]").hide()
  #### END DYNAMIC DROPDOWN MENU CONTROLS ####

  #### SUBTAB PERSISTENCE ####

  # Persist Contact Information/Samples tabs
  siteDataSubtabsKey = 'activeSiteDataTab'
  samplesSubtabsKey = 'activeSiteSamplesDataTab'
  siteDataSubtabs = $('[data-site-data-subtabs-menu]').find('.site_data_subtab')
  siteDataSubtabsEnclosingDiv = $('#site-data-cryo-tabs')
  parseSiteDataSubtabIndex = (tabIdentifier) ->
    if tabIdentifier == '#contact' then 0 else 1

  $.persistSubtab(
    siteDataSubtabs,
    siteDataSubtabsEnclosingDiv,
    siteDataSubtabsKey,
    parseSiteDataSubtabIndex
  )

  # Persist Sample 1/2/3/4 tabs
  siteDataSamplesSubtabs = $('[data-iqa-sample-tabs]').find('.site_data_samples_subtab')
  siteDataSamplesSubtabsEnclosingDiv = $('#iqa-cryo-samples-tabs')
  # This function expects input like '#sample_3' and will return 2
  # (the index of the tab is the sample number - 1)
  parseSampleTabIndexFromHref = (href) ->
    parseInt(href[href.length - 1]) - 1

  $.persistSubtab(
    siteDataSamplesSubtabs,
    siteDataSamplesSubtabsEnclosingDiv,
    samplesSubtabsKey,
    parseSampleTabIndexFromHref
  )
  #### END SUBTAB PERSISTENCE ####

  $("#cryo-sample").submit (event) ->
    $("[data-anticoagulant]").each (idx, element)->
      if $("#anticoagulant_select-" + idx).val() == 'Other'
        $("[data-anticoagulant-field='" + idx + "']").val($("#anticoagulant_other-" + idx).val())
      else if $("#anticoagulant_select-" + idx).val() != 'Other'
        $("[data-anticoagulant-field='" + idx + "']").val($("#anticoagulant_select-" + idx).val())

    $("[data-viability]").each (idx, element)->
      if $("#viability_select-" + idx).val() == 'Other'
        $("[data-viability-method='" + idx + "']").val($("#viability_other-" + idx).val())
      else if $("#viability_select-" + idx).val() != 'Other'
        $("[data-viability-method='" + idx + "']").val($("#viability_select-" + idx).val())

    $("[data-cell-counting]").each (idx, element)->
      if $("#cell_counting_method_main_used-" + idx).val() == 'Manual'
        $("[data-cell-counting-method='" + idx + "']").val($("#cell_counting_manual_method-" + idx).val())
      else if $("#cell_counting_auto_method-" + idx).val() == 'Other'
        $("[data-cell-counting-method='" + idx + "']").val($("#cell_counting_auto_method_other-" + idx).val())
      else
        $("[data-cell-counting-method='" + idx + "']").val($("#cell_counting_auto_method-" + idx).val())

    activateTab = (tabId, tabIndex) ->
      if tabId.includes("sample")
        $("#site-data-cryo-tabs").tabs('option', 'active', 1)
        $("#iqa-cryo-samples-tabs").tabs('option', 'active', tabIndex - 2)
      else # If we are not going to a sample tab, we select the contact info tab
        $("#site-data-cryo-tabs").tabs('option', 'active', 0)

    $.validateForm($(this), true, activateTab) if $.confirmSubmit(event)
