$.display_register_fields = () ->
  type = $(".user-type-radio:checked").val()
  if(type is "dhe")
    $("#dhe-id-field").show()
    $("#password-confirmation-field").hide()
  else
    $("#dhe-id-field").hide()
    $("#password-confirmation-field").show()

$(".user-type-radio").on 'change', ->
  $.display_register_fields()
