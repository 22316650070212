window.disable_test_form = (form) ->
  form.removeClass('edit-view')
  form.addClass('show-view')
  form.find(':input').attr("disabled", "disabled")

window.enable_test_form = (form) ->
  form.removeClass('show-view')
  form.addClass('edit-view')
  form.find(':input').removeAttr("disabled")

jQuery ->

  do initializeFormAction = ->
    current_tab = $.query.get("current_tab")
    $form = $('.back-to-donor-link').closest('form')
    if current_tab != "" && $form.length != 0
      action = $form.attr('action')
      newActionQuery = $.query.load(action).set('current_tab', current_tab)
      newAction = action.split("?")[0] + newActionQuery
      $form.attr('action', newAction)

  $(".test-modal-form").dialog
    autoOpen: false,
    height: 575,
    width: 1000,
    modal: true,
    buttons:
      Save: ->
        $(this).children("form").submit()
        $(".select-processing-event").val("").show()
        $(".processing-event-select-box").val("").hide()

      Cancel: ->
        $(this).dialog "close"
        $(".select-processing-event").val("").show()
        $(".processing-event-select-box").val("").hide()

  $(".select-processing-event").change ->
    $(".processing-event-select-box").hide()
    test_type = $(this).val()
    $(this).closest(".processing-event-entry").find("." + test_type + "-processing-event-select-box").show()

  $(".processing-event-select-box").change ->
    $(".test-modal-form").dialog "close"
    form = $("##{$(this).val()}-donor-specimen-form")
    form.dialog "open"
    visit_id = $(this).closest(".donor-subsection").find("[name='visit_id']").val()
    visit_type = $(this).closest(".donor-subsection").find("[name='visit_type']").val()
    orders = $(this).closest(".donor-subsection").find("[name='visit_orders']").val()
    aliquots = $(this).closest(".donor-subsection").find("[name='visit_aliquots']").val()
    currentTab = $("div.tab:not(.ui-tabs-hide)").attr("id")
    $("[name='processing_event[target_id]']").val(visit_id)
    $("[name='processing_event[target_type]']").val(visit_type)
    form.find("#current_tab").val(currentTab)
    $(".order-aliquots-selector").html(orders)
    $(".aliquot-select").html(aliquots).trigger("chosen:updated")
    unless aliquots
      $(".orders_and_aliquots").hide()

  $("#processing_event_detail_attributes_clade_code").cladeCodeObserver()

  $(".processing-event-select-box").hide()
  $(".ui-dialog-titlebar-close").hide()

  $(".order-aliquots-selector").change ->
    aliquots = $.parseJSON($(this).val())
    chznAddAliquots(aliquots, this)

  chznAddAliquots = (aliquots, order_select) ->
    currentAliquots = $(".aliquot-select").val()
    newAliquotList = $.merge(currentAliquots || [], aliquots || [])
    $(order_select).closest(".donor-test-form").find(".aliquot-select").val(newAliquotList).trigger('chosen:updated')

  $('.donor-test-form .edit-button').click (event) ->
    enable_test_form($(this).closest('.donor-test-form'))

  $('.donor-test-form .cancel-button').click (event) ->
    disable_test_form($(this).closest('.donor-test-form'))
