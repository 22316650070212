$.add_polymorphic_event_type_trigger = () ->
  $('#processing_event_detail_attributes_test_type.polymorphic').change ->
    val = $(this).val()
    if val != ""
      if $('form#new_processing_event').length != 0
        target = "#{$('form#new_processing_event')[0].action}?test_type=#{val}"
      else
        # TODO: This feels brittle.  It'd be nice to come up with a better way to do this.
        $('form.edit_processing_event')[0].action.match(/(\d{1,})$/)
        processingEventId = RegExp.$1
        frontUrl = $('form.edit_processing_event')[0].action.replace(/\d{1,}$/, "")
        target = "#{frontUrl}?test_type=#{val}&event_id=#{processingEventId}"
        
      $.get target, "", (data) ->
        $("#results-formlet").html(data)