jQuery.fn.feedersSelector = (select='select') ->
  
  adjustSelected = (selectedTable) ->
    selectList = ''
    selectedTable.find('tr.specimen').each () ->
      selectList += ($(this).attr('id') + ' ')
      $('input#processing_event_feeder_ids, input#selected_feeder_ids').val(selectList)
    if selectList is ''
      $('#selected-feeders-empty').show()
      $('#selected-feeders').hide()
    else
      $('#selected-feeders').show()
      $('#selected-feeders-empty').hide()
    
  selectFeeder = (eventHandler) ->
    eventHandler.preventDefault()   
    trigger = $(eventHandler.target)
    row = $(trigger).closest('tr')
    if row.find('a').is(':visible')
      selectedTable = $('#selected-feeders')
      newRow = row.clone()
      row.find('a').hide()
      row.find('span.fc-selected-label').show()
      newRow.attr('id', row.attr('id').replace('specimen_', 'selected_'))
      deleteTrigger = newRow.find('span.specimen-select a')
      deleteTrigger.closest('span').addClass('specimen-reject').removeClass('specimen-select')
      deleteTrigger.text('Remove')
      deleteTrigger.click (deselectEventHandler) ->
        deselectFeeder(deselectEventHandler)
      selectedTable.append(newRow)
      adjustSelected(selectedTable)
    false
    
  deselectFeeder = (eventHandler) ->
    eventHandler.preventDefault()
    trigger = $(eventHandler.target)
    row = $(trigger).closest('tr')
    doppelgangerRow = $("tr##{row.attr('id').replace('selected_', 'specimen_')}")
    doppelgangerRow.find("a").show()
    doppelgangerRow.find('span.fc-selected-label').hide()
    row.remove()
    adjustSelected($('#selected-feeders'))
    false
    
  if select == 'select'
    eventFunction = selectFeeder
  else
    eventFunction = deselectFeeder
    
  this.each () ->
    trigger = $(this)
    trigger.click (eventHandler) ->
      eventFunction(eventHandler)
