$ ->
  # Make table rows links

  $("tr[data-link]").click (e) ->
    e.preventDefault()
    window.location.href = $(e.target).closest('tr').data('link')

  # Highlight all associated notes if hovering over conversation tr (search)

  $("[data-type='conversation-row']").hover(
    (e) -> $(e.target).parent("tr[data-type='conversation-row']").nextUntil("[data-type='conversation-row']", '.linkable-tr').addClass('group-hover'),
    (e) -> $('.group-hover').removeClass('group-hover'))
