$.add_quantification_select_trigger = () ->
  $('#program_inventory_item_quantification').change ->
    val = $(this).val()
    switch val
      when 'Individual'
        $('#quantity-field').hide()
        $('#quantity-units-field').hide()
      when 'Individual with quantity'
        $('#quantity-field').show()
        $('#quantity-units-field').show()
        $('div#quantity-field label').text('Quantity')
      when 'Bulk'
        $('#quantity-field').show()
        $('#quantity-units-field').show()
        $('div#quantity-field label').text('"Aliquotted" Quantity')
      