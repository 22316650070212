$ ->
  toggleEolBackground = ->
    $(".eol-site-checkbox:checked").each (i,e) ->
      addEolSiteClass(e)

  addEolSiteClass = (e) ->
    $(e).closest('.site-enrollments').toggleClass('eol-site')

  toggleEolBackground()

  $(".eol-site-checkbox").click (e) ->
    addEolSiteClass(e.target)

  $('input[data-action="select-all-sites"]').on 'click', (event) ->
    selectAllCheckbox = $(event.target)
    sitesForm = selectAllCheckbox.closest('form')
    availableSiteCheckboxes = sitesForm.find('input[data-type=select-site-checkbox]:not(:disabled)')
    if selectAllCheckbox.prop('checked') == true
      availableSiteCheckboxes.prop('checked', true)
    else
      availableSiteCheckboxes.prop('checked', false)

  # Store the initial value of the proficiency_test_status drop down on page load
  dropdown = $("#proficiency_test_status");
  if dropdown.length > 0 then dropdown.data("previous_value", dropdown.val())

  $('#proficiency_test_status').on 'change', (event) ->
    dropdown = $(event.target)
    previous_value = dropdown.data("previous_value")
    new_value = dropdown.val()

    iqa_checkbox = $('#proficiency_test_send_email_notification')
    iqa_checkbox_exists = iqa_checkbox.length

    cryo_checkbox = $('#proficiency_test_send_cryo_email_notification')
    cryo_checkbox_exists = cryo_checkbox.length

    dropdown.data("previous_value", new_value) # Save this for next time

    if previous_value == "Upcoming" && new_value == "Active"
      if iqa_checkbox_exists
        iqa_checkbox.prop('checked', true);
        alert("Because the status is changing to Active, an email notification will be sent to all participating sites when you save this Trial. If you do not wish to send the email at this time, please deselect the checkbox called 'Send Email Notification To Sites' before saving this record.")
      else if cryo_checkbox_exists
        cryo_checkbox.prop('checked', true);
        alert("Because the status is changing to Active, an email notification will be sent to all participating sites when you save this Proficiency Test. If you do not wish to send the email at this time, please deselect the checkbox called 'Send Email Notification To Sites' before saving this record.")

  proficiencyTestsUrl = '/api/proficiency_test_enrollments'

  onEPQuarterDataChange = (_event) ->
    quarter_start_months = {
      '1': 2,
      '2': 5,
      '3': 8,
      '4': 11
    }
    epInQuarter = $('#proficiency_test_ep_in_quarter').val()
    epStartMonth = quarter_start_months[$('#proficiency_test_quarter').val()[0]]
    programId = new URLSearchParams(window.location.search).get('program_id')
    startDate = new Date($('#proficiency_test_start_date').val())
    startDateYear = startDate.getFullYear()
    if (startDate.getMonth() < epStartMonth)
      startDateYear = startDate.getFullYear() - 1

    requestData = {
      quarter: $('#proficiency_test_quarter').val(),
      year: startDateYear,
      program_id: programId,
      ep_in_quarter: epInQuarter
    }

    $.getJSON(proficiencyTestsUrl, requestData, handleSiteData)

  # Site data represents whether or not the site should be included in the trial
  # for a second EP in a quarter based on their status in the first trial.
  # If the selected trial is the first in the quarter, the data should represent
  # the currently added sites, or default to all active for new trials.
  # It is expected in the format:
  # [
  #   {
  #    id: 1234
  #    active: true
  #   }
  # ]
  handleSiteData = (sitesData) ->
    setAllSitesActive(false)
    epInQuarter = $('#proficiency_test_ep_in_quarter').val()
    if sitesData.length == 0 || epInQuarter == '1'
      setAllSitesActive(true)
    for id, site of sitesData
      siteActiveCheckboxInputs = $("#site-#{site.id} > .enrollment > .fields > .enrollment-active > input")
      visibleSiteInput = siteActiveCheckboxInputs.last()
      visibleSiteInput.prop('checked', site.active)
      visibleSiteInput.trigger('change')

  setAllSitesActive = (boolVal) ->
    sites = $('.site-enrollments')
    sites.each (i, site) ->
      siteActiveCheckboxInputs = $(site).find(".enrollment > .fields > .enrollment-active > input")
      visibleSiteInput = siteActiveCheckboxInputs.last()
      visibleSiteInput.prop('checked', boolVal)
      visibleSiteInput.trigger('change')

  $('#proficiency_test_quarter').on 'change', (e) ->
    onEPQuarterDataChange()

  $('#proficiency_test_ep_in_quarter').on 'change', (e) ->
    onEPQuarterDataChange()

  $('#proficiency_test_start_date').on 'change', (e) ->
    onEPQuarterDataChange()

  $('#proficiency_test_locked').on 'change', (event) ->
    if $(event.target).is(":checked")
      $('[data-omit-from-analysis]').addClass('readonly_checkbox')
    else
      $('[data-omit-from-analysis]').removeClass('readonly_checkbox')