jQuery.fn.multipleFileUploader = (opts = {}) ->
  uploaderContainer = $(this)
  uploaderContainer.fileUploaderAttributeName = opts['attributeName']
  addUploader = (formletsContainer, countContainer, triggerContainer) ->
    current_uploaders = formletsContainer.find('div.document-formlet')
    newUploader = current_uploaders.last().clone()
    newUploader.removeClass('hide')
    attribute_name = uploaderContainer.fileUploaderAttributeName || triggerContainer.data('adjust-attribute')
    countAttributeName = attribute_name || 'documents_attributes'
    i = countContainer.find('div.document-formlet').length
    countContainer.find('div.document-formlet').each (index, formlet) =>
      theInput = $(formlet).find("input[type='file']")
      if theInput.length > 0
        theId = theInput[0].id
        match = theId.match(new RegExp("#{countAttributeName}_(\\d+)_"))
        if match?
          thisI = (Number) match[1]
          i = Math.max(i, thisI + 1)

    newUploader.adjust_rails_form_fields(i, {attributeName: attribute_name, clear: 'file'})
    triggerContainer.before(newUploader)

  this.each () ->
    countContainerParentSelector = $(this).data('count-container-parent-selector')
    formletsContainer = $(this)
    if countContainerParentSelector?
      countContainer = $(this).parent(countContainerParentSelector)
    else
      countContainer = $(this)
    trigger = $('.add-file-field')
    trigger.click (eventHandler) ->
      eventHandler.preventDefault()
      addUploader(formletsContainer, countContainer, $(eventHandler.target).parent('div'))
