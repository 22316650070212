#= require d3.min
#= require c3.min
#= require jquery.min

## Copied from proficiency_test_enrollments.coffee below. Workaround for
## stub directive in application.coffee.

$ ->
  if $("#site-scores-graph").length > 0
    $siteGraph = $("#site-scores-graph")
    epName = $('.assay-number-header').data('epName')

    setupGradingLevels = ->
      gradingLevels = []
      $.each $('[data-range-upper-bound]'), (i, level) ->
        gradingLevels.push( $(level).data('rangeUpperBound') )
      gradingLevels

    window.gradingLevels = setupGradingLevels()

    colorFor = (id, score) ->
      averageColor =   '#000000'
      poorColor =      '#FF0000'
      fairColor =      '#FFA500'
      goodColor =      '#009900'
      excellentColor = '#00009C'

      if /average/i.test(id)    then return averageColor
      if /poor/i.test(id)       then return poorColor
      if /fair/i.test(id)       then return fairColor
      if /good/i.test(id)       then return goodColor
      if /excellent/i.test(id)  then return excellentColor
      unless score? then return null
      # order matters
      if score <= (window.gradingLevels[window.gradingLevels.length - 1] || 0) then return poorColor
      if score <= (window.gradingLevels[2] || 0) then return fairColor
      if score <= (window.gradingLevels[1] || 0) then return goodColor
      if score <= (window.gradingLevels[0] || 0) then return excellentColor
      return averageColor

    chart = c3.generate
      bindto: '#site-scores-graph'
      transition:
        duration: 0
      size:
        width: 500
        height: 150
      color:
        pattern: ['#000000']
      point:
        r: 5 # radius of dots
      legend:
        show: false
      axis:
        y:
          padding:
            top: 14
          tick:
            values: [ 0, 25, 50, 75, 100 ]
          max: 100
          min: 0
        x:
          type: 'category'
      grid:
        x:
          lines:
            [
              value: epName
              position: 'start'
            ]
      tooltip:
        grouped: false
      data:
        rows: []
        empty:
          label:
            text: "No data found"
        color: (color, d) ->
          newColor = colorFor(d.id, d.value)
          unless newColor? then return color
          newColor
        x: 'EP Name'

    legendTextMap = {
      'Average':    'EP Average',
      'Excellent':  "Site Score - Excellent",
      'Good':       "Site Score - Good",
      'Fair':       "Site Score - Fair",
      'Poor':       "Site Score - Poor",
    }

    legendItem = (backgroundColor, text) ->
      """
      <span class="legend-palette" style="background-color: #{backgroundColor}"></span>
      #{text}
      """

    siteScoresUrl = $('#site-scores-graph').data('siteScoresUrl')
    epAverageScoresUrl = $('#site-scores-graph').data('epAverageScoresUrl')

    # Setup Legend
    d3.select('#site-scores-graph')
      .insert('div', '.chart')
      .attr('class', 'legend')
      .selectAll('span')
      .data(Object.keys(legendTextMap))
      .enter()
      .append('span')
      .html( (id) -> legendItem(colorFor(id), legendTextMap[id]) )

    # Below should be deferred objects, but this is a simpler hack
    $siteGraph.on 'chartLoaded', ->
      if $(this).data('chartOneRendered') == true && $(this).data('chartTwoRendered') == true
        # Can't figure out why c3 is giving the Averages a 0.5 opacity
        # Override here
        $('.c3-circle').css('opacity', 1)
        # This is needed so wkhtmltoimage knows when it's done rendering
        window.status = 'charts_loaded'

    chart.load
      type: 'spline'
      url: siteScoresUrl
      done: ->
        $siteGraph.data('chartOneRendered', true)
        $siteGraph.trigger('chartLoaded')

    chart.load
      type: 'scatter'
      url: epAverageScoresUrl
      done: ->
        $siteGraph.data('chartTwoRendered', true)
        $siteGraph.trigger('chartLoaded')
